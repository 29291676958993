import { API_URL_DEV, API_URL_LOCAL_DEV , API_URL } from "../../env"
import { Get, Post } from "../consumir"



export const checkCamera = async(value:string) =>{

    const response = await Get(`${API_URL}/recordvideo/checkcamera/${value}`);
    const status = response.status;
    if(status !== 200){
        console.error('ERROR NETWORK CONNECTION GET STATUS CAMERA',status);
    }
    return response;
};


export const getDataClientUser = async(values:any) => {
    const response = await Post(`${API_URL}/login/getDataClient`,values);
    const status = response.status;
    if( status !== 200 && status !== 201){
        console.error('ERROR NETWORK CONNECTION GET DATA CLIENT',status);
    }
    return response;
};

export const startRecordLiveVideo = async(values:any) => {
    const response = await Post(`${API_URL}/recordvideo/startRecord`,values);
    const status = response.status;
    if( status !== 200 && status !== 201){
        console.error('ERROR NETWORK CONNECTION INITIALIZE RECORD VIDEO',status);
    }
    return response;
};