import coachtool from "../utils/images/coachtoolv2.jpg";
import eventtool from "../utils/images/eventtool.jpg";
import {
  faEarthAmerica,
  faShareNodes,
  faUpload,
  faUserGroup,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { useContext, useEffect } from "react";
import { API_URL } from "../env";
import { Get, Post } from "../utils/consumir";
import Swal from "sweetalert2";
import AuthContext from "../utils/Auth";

import { PayPalPopUp } from "../components/paypalpopup";
import React from "react";
import { Modal } from "react-bootstrap";
import { language, Textos } from "../texto";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Planesclubes() {
  const { currentUser, GetUser, logoutUser, auth } = useContext(AuthContext);
  const [popup, setPopup] = React.useState(false);
  const [plandata, setPlandata] = React.useState({ idplan: "", tipoplan: "" });
  const URL_API = API_URL || "";
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  return (
    <>
      <div className="container">
        <div className="mt-5 grid-container-dos border-top">
          <div className="mt-5 row">
            <h1 className="text-center display-2 fw-bold">
              {t("SuscriptionClub.Title")}
            </h1>
          </div>
          <div className="row">
            <div className="col div-cuadro-uno ">
              <p className="mt-15 text-start">
                {t("SuscriptionClub.subTitle")}
              </p>

              <ul className="mt-5 text-start">
                <li>
                  <p>
                    <span>
                      <span className="mb-5 text-primary fw-semibold">
                        {t("SuscriptionClub.product1")}
                      </span>{" "}
                      {t("SuscriptionClub.product1_description")}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <span className="mb-5 text-primary fw-semibold">
                        {t("SuscriptionClub.product2")}
                      </span>{" "}
                      {t("SuscriptionClub.product2_description")}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <span className="mb-5 text-primary fw-semibold">
                        {t("SuscriptionClub.product3")}
                      </span>{" "}
                      {t("SuscriptionClub.product3_description")}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <span className="mb-5 text-primary fw-semibold">
                      {t("SuscriptionClub.product4")}
                      </span>{" "}
                      {t("SuscriptionClub.product4_description")}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <span className="mb-5 text-primary fw-semibold">
                      {t("SuscriptionClub.product5")}
                      </span>{" "}
                      {t("SuscriptionClub.product5_description")}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <span className="mb-5 text-primary fw-semibold">
                      {t("SuscriptionClub.product6")}
                      </span>{" "}
                      {t("SuscriptionClub.product6_description")}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>
                      <span className="mb-5 text-primary fw-semibold">
                      {t("SuscriptionClub.product7")}
                      </span>{" "}
                      {t("SuscriptionClub.product7_description")}
                    </span>
                  </p>
                </li>
              </ul>
            </div>
            <div className="mt-5 col-md-6">
              <img
                src={eventtool}
                style={{
                  width: "100%",
                }}
                className="imagen"
                alt="eventtool"
              />
              <img
                src={coachtool}
                style={{
                  width: "100%",
                }}
                className="imagen"
                alt="coachtool"
              />
            </div>
          </div>
        </div>

        <div className="mt-5 grid-container-dos border-top">
          <div className="mt-5 row">
            <h1 className="mb-5 text-center fs-2hx fw-bold">
            {t("SuscriptionClub.Membership.Title")}
            </h1>
          </div>
          <div className="row">
            <div
              className="col-md-4"
              style={{
                marginBottom: "20px",
              }}
            >
              <div className="d-flex h-100 align-items-center">
                <div className="px-10 py-3 w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-95">
                  <div className="text-center mb-7">
                    <h1 className="mb-5 text-dark fw-bolder">
                      <br></br>{t("SuscriptionClub.Membership.BuyOption_Title")}
                    </h1>
                    <div className="mb-5 text-primary fw-semibold">
                    {t("SuscriptionClub.Membership.BuyOption_subTitle")}
                    </div>
                    <br></br>
                    <div className="text-center">
                      <span className="mb-2 text-primary">USD</span>
                      <span className="fs-3x fw-bold text-primary"> 780 </span>
                      <span className="opacity-50 fs-7 fw-semibold">
                        /
                        <span data-kt-element="period">
                          {" "}
                          {t("SuscriptionClub.Membership.pay") + ' ' +  t("SuscriptionClub.Membership.span")}
                        </span>
                      </span>
                    </div>
                    <div className="text-center">
                      <span className="mb-2 text-primary">+ USD</span>
                      <span className="fs-3x fw-bold text-primary"> 40 </span>
                      <span className="opacity-50 fs-7 fw-semibold">
                        /<span data-kt-element="period">  {t("SuscriptionClub.Membership.month")}</span>
                      </span>
                    </div>
                  </div>
                  <div className="mb-10 w-100">
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.BuyOption_Description1")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.BuyOption_Description2")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.BuyOption_Description3")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.BuyOption_Description4")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.BuyOption_Description5")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <br />

                    <div style={{ textAlign: "center" }}>
                      {currentUser ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setPlandata({
                              idplan: "P-0YT0933278444310WMN5WWQQ",
                              //idplan: 'P-1M896066E0416484EMN2DFE',
                              tipoplan: "Plan Aduéñate",
                            });
                            setPopup(true);
                          }}
                        >
                          {t("SuscriptionClub.Subscribe")}
                        </button>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <a
                            style={{
                              fontSize: "21px",
                            }}
                            onClick={() => {
                              navigate("/login");
                            }}
                            className="htitle"
                          >
                           {t("SuscriptionClub.NeedsLogin")}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-4"
              style={{
                marginBottom: "20px",
              }}
            >
              <div className="d-flex h-100 align-items-center">
                <div className="px-10 py-3 w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-95">
                  <div className="text-center mb-7">
                    <h1 className="mb-5 text-dark fw-bolder">
                    {t("SuscriptionClub.Membership.LeasingOption_Title")}
                    </h1>
                    <div className="mb-5 text-primary fw-semibold">
                    {t("SuscriptionClub.Membership.LeasingOption_subTitle")}
                    </div>
                    <br></br>
                    <div className="text-center">
                      <span className="mb-2 text-primary">USD</span>
                      <span className="fs-3x fw-bold text-primary"> 75 </span>
                      <span className="opacity-50 fs-7 fw-semibold">
                        /
                        <span data-kt-element="period">
                          {" "}
                          {t("SuscriptionClub.Membership.monthsKit")}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="mb-10 w-100">
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.LeasingOption_Description1")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.LeasingOption_Description2")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.LeasingOption_Description3")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.LeasingOption_Description4")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.LeasingOption_Description5")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.LeasingOption_Description6")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <br />
                    <div>
                      <div style={{ textAlign: "center" }}>
                        {currentUser ? (
                          <>
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                setPlandata({
                                  idplan: "P-9JU480136A4467600MN5WYBY",
                                  // idplan: 'P-5E430781207600816MN2D55Q',
                                  tipoplan: "Plan Arrienda",
                                });
                                setPopup(true);
                              }}
                            >
                               {t("SuscriptionClub.Subscribe")}
                            </button>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <a
                              style={{
                                fontSize: "21px",
                              }}
                              onClick={() => {
                                navigate("/login");
                              }}
                              className="htitle"
                            >
                              {t("SuscriptionClub.NeedsLogin")}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-4"
              style={{
                marginBottom: "20px",
              }}
            >
              <div className="d-flex h-100 align-items-center">
                <div className="px-10 py-3 w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-95">
                  <div className="text-center mb-7">
                    <h1 className="mb-5 text-dark fw-bolder">
                    {t("SuscriptionClub.Membership.OwnCamera_Title")}
                    </h1>
                    <div className="mb-5 text-primary fw-semibold">
                    {t("SuscriptionClub.Membership.OwnCamera_subtitle")}
                    </div>
                    <br></br>
                    <div className="text-center">
                      <span className="mb-2 text-primary">USD</span>
                      <span className="fs-3x fw-bold text-primary"> 40 </span>
                      <span className="opacity-50 fs-7 fw-semibold">
                        /<span data-kt-element="period">  {t("SuscriptionClub.Membership.monthsCamera")}</span>
                      </span>
                    </div>
                  </div>
                  <div className="mb-10 w-100">
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.OwnCamera_Description1")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.OwnCamera_Description2")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.OwnCamera_Description3")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.OwnCamera_Description4")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                      {t("SuscriptionClub.Membership.OwnCamera_Description5")}
                      </span>
                      <span className="svg-icon svg-icon-1 svg-icon-success">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>

                    <div className="mb-5 d-flex align-items-center">
                      <span className="text-gray-800 fw-semibold fs-6 flex-grow-1 pe-3">
                        <br></br>
                      </span>
                    </div>

                    <br />

                    <div style={{ textAlign: "center" }}>
                      {currentUser ? (
                        <>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              setPlandata({
                                idplan: "P-4B573197AK0327748MN5WZEA",
                                //idplan: 'P-0BF76008YW968472AMN2EAUQ',
                                tipoplan: "Plan Conecta",
                              });
                              setPopup(true);
                            }}
                          >
                            {t("SuscriptionClub.Subscribe")}
                          </button>
                        </>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <a
                            style={{
                              fontSize: "21px",
                            }}
                            onClick={() => {
                              navigate("/login");
                            }}
                            className="htitle"
                          >
                             {t("SuscriptionClub.NeedsLogin")}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 grid-container-dos border-top">
          <div className="mt-5 row">
            <p className="text-center">
              {" "}
              {t("SuscriptionClub.Kits.description1")}
            </p>
            <p className="text-center">
              {" "}
              {t("SuscriptionClub.Kits.description2")}{" "}
            </p>
            <p className="text-center">
              {" "}
              {t("SuscriptionClub.Kits.description3")}{" "}
            </p>
            <p className="text-center">
              {" "}
              {t("SuscriptionClub.Kits.description4")}{" "}
            </p>
          </div>
        </div>
      </div>

      <Modal show={popup} backdrop="static" style={{ top: "30vh" }}>
        <Modal.Body>
          <div>
            {/* <PayPalPopUp idplan={plandata.idplan} tipoplan={plandata.tipoplan} idcliente="desa" ></PayPalPopUp> */}
            <PayPalPopUp
              idplan={plandata.idplan}
              tipoplan={plandata.tipoplan}
              idcliente=""
            ></PayPalPopUp>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            justifyContent: "center",
          }}
        >
          <div>
            <button
              style={{}}
              onClick={() => {
                setPopup(false);
              }}
              className="btn btn-danger"
            >
                {t("Cookies.Cookies_button.degree_button")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
