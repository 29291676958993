import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { CardPVVideo } from "../components/cardpvvideo";
import { Delete, Get, getParameter, Put } from "../utils/consumir";
import { ProcesarDuracion } from "../utils/utilidades";
import "./evento.css";
import {
  Button,
  DatePicker,
  DateRangePicker,
  IconButton,
  Input,
  Loader,
  Message,
  SelectPicker,
  Uploader,
  useToaster,
} from "rsuite";
import {
  faCircleXmark,
  faDeleteLeft,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { language, Textos } from "../texto";
import ConfigContext from "../utils/Config";
import AuthContext from "../utils/Auth";
import { debug } from "console";
import { date } from "yup/lib/locale";
import Dragger from "antd/es/upload/Dragger";
import { API_URL, API_URL_LOCAL_DEV, API_URL_DEV } from "../env";
import {
  Modal as ModalAntd,
  Input as InputAntd,
  message,
  Upload,
  Button as ButtonAntd,
  Image,
} from "antd";
import Lottie from "lottie-react";
import uploadAnim from "../animations/upload.json";
import { useTranslation } from "react-i18next";
import {
  deleteEventById,
  deleteVideoById,
  getEventById,
  getEventsByClubId,
  updateEventByiD,
} from "../utils/Api/eventsApi";
import moment from "moment";
import ModalComponent from "../components/ModalComponent";

type evento = {
  id: string;
  eventname: string;
  eventdate: string;
  id_club: number;
  eventenddate: string;
  eventendtime: string;
  eventinitdate: string;
  eventinitime: string;
  urlimagen: string;
};

export function Evento() {
  const idevento = getParameter("id") || "";
  const [t] = useTranslation("global");
  const [fileInfo, setFileInfo] = useState(null);
  const toaster = useToaster();
  const [uploading, setUploading] = useState(false);
  const URL_API = API_URL || "";
  const [data, setData] = useState([]);
  const [evento, setEvento] = useState<evento>({
    id: "",
    eventname: "",
    eventdate: "",
    id_club: 0,
    eventenddate: "",
    eventendtime: "",
    eventinitdate: "",
    eventinitime: "",
    urlimagen: "",
  });
  const { currentConfig, GetConfig } = useContext(ConfigContext);
  function addZeros(num: number) {
    return String(num).padStart(2, "0");
  }

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [Hasmore, setHasmore] = useState(true);
  const [totalreg, settotalreg] = useState(0);
  const [editarEvento, seteditarEvento] = useState(false);
  const [ClubList, setClubList] = useState<any[]>([]);
  const [captureImgEvent, setCaptureImgEvent] = useState("");
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (idevento !== undefined) {
      getDataEvents();
      getEventsById(0);
    }
  }, [idevento, window.location.search]);

  const idioma = {
    placeholder: `${t("Home.Filter.Selected")}`,
    searchPlaceholder: `${t("Home.Filter.Search")}`,
    noResultsText: `${t("Home.Filter.NoResult")}`,
  };

  const props = {
    name: "file",
    multiple: false,
    action: `${API_URL}/upload`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log("información de la imagen ", info.file);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setCaptureImgEvent(info.file.response.url);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      message.success(
        `${e.dataTransfer.files} drop file uploaded successfully.`
      );
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  function previewFile(file: any, callback: any) {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }

  const getDataEvents = () => {
    //ver si se está parseando las zonas horarias
    // zona horaria del Servidor es UTC-0.

    getEventById(idevento)
      .then((resp: any) => {
        const result = resp.data;
        setLoading(false);
        // result.data.eventdate = new Date(result.data.eventdate).toUTCString();

        // if (result.data.eventenddate === null) {
        //   result.data.eventenddate = new Date(
        //     result.data.eventdate
        //   ).toUTCString();
        // }

        // if (
        //   result.data.eventendtime === null ||
        //   result.data.eventendtime === "" ||
        //   result.data.eventendtime === undefined
        // ) {
        //   result.data.eventendtime = "00:00:00";
        // }
        // if (
        //   result.data.eventinitdate === null ||
        //   result.data.eventinitdate === "" ||
        //   result.data.eventinitdate === undefined
        // ) {
        //   result.data.eventinitdate = new Date(
        //     result.data.eventdate
        //   ).toUTCString();
        // }
        // if (
        //   result.data.eventinittime === null ||
        //   result.data.eventinittime === "" ||
        //   result.data.eventinittime === undefined
        // ) {
        //   result.data.eventinittime = "00:00:01";
        // }
        // Convertir las fechas a strings UTC usando Moment.js
        result.data.eventdate = moment(result.data.eventdate).utc().format();

        if (!result.data.eventenddate) {
          result.data.eventenddate = moment(result.data.eventdate)
            .utc()
            .format();
        }

        if (!result.data.eventendtime) {
          result.data.eventendtime = "00:00:00";
        }

        if (!result.data.eventinitdate) {
          result.data.eventinitdate = moment(result.data.eventdate)
            .utc()
            .format();
        }

        if (!result.data.eventinittime) {
          result.data.eventinittime = "00:00:01";
        }

        setEvento({
          id: result.data.id,
          eventname: result.data.eventname,
          eventdate: result.data.eventdate,
          id_club: result.data.id_club,
          eventenddate: result.data.eventenddate,
          eventendtime: result.data.eventendtime,
          eventinitdate: result.data.eventinitdate,
          eventinitime: result.data.eventinittime,
          urlimagen: result.data.urlimagen,
        });
      })
      .catch((err: any) => {
        console.error("Hubo un erro al traer el evento", err);
      });
  };
  // console.log("🚀 ~ file: evento.tsx ~ line 115 ~ getEvent ~ evento", evento);

  const getEventsById = (count: number) => {
    setLoading(true);
    const values = {
      id: idevento,
      count: count,
    };
    getEventsByClubId(values)
      .then((resp: any) => {
        // console.log("⚠️ que trae resp? 🚸", resp);
        const result = resp.data;
        setLoading(false);
        setData(result.data);
        settotalreg(result.total.count);
      })
      .catch((err: any) => {
        console.error("Hubo un error en traer los videos del evento", err);
        setLoading(false);
        setHasmore(false);
      });
  };

  const getDataEvent = (count: number) => {
    setLoading(true);
    const values = {
      id: idevento,
      count: count,
    };
    if (data.length > 0 && totalreg > 0) {
      if (data.length === totalreg) {
        setHasmore(false);
        setLoading(false);
        return;
      }
    }
    getEventsByClubId(values)
      .then((resp: any) => {
        const result = resp.data;
        let addedData = data.concat(result.data);
        // console.log("que es el resultado de aqui?",result.data);
        setData(addedData);
        // console.log("resultado de getDataEvent: " ,result);
        settotalreg(result.total.count);
        setLoading(false);
        if (addedData.length === totalreg) {
          setHasmore(false);
        }
      })
      .catch((err: any) => {
        setHasmore(false);
        setLoading(false);
        console.error("Hubo un error en traer los datos", err);
      });
  };

  const deleteEvent = (id: string) => {
    deleteEventById(id)
      .then((resp: any) => {
        Swal.fire({
          icon: "success",
          title: `${t("Events.Modals.deleteDescription")}`,
        });
        window.location.href = "/eventos";
      })
      .catch((err: any) => {
        console.error("No se pudo borrar el evento", err);
        Swal.fire({
          icon: "error",
          title: `${t("Events.Modals.eventDeleteError")}`,
        });
      });
  };

  function formatDatetime(dateinput: string) {
    let ddateinput = new Date(dateinput.split(".")[0]);
    // pasar a yyyy-mm-dd hh:mm:ss

    let year = ddateinput.getFullYear();
    let month = addZeros(ddateinput.getMonth());
    let day = addZeros(ddateinput.getDate());
    let hour = addZeros(ddateinput.getHours());
    let minute = addZeros(ddateinput.getMinutes());

    // let date = new Date(Date.UTC(ddateinput.getFullYear(), ddateinput.getMonth(), ddateinput.getDate(), ddateinput.getHours(), ddateinput.getMinutes(), ddateinput.getSeconds()));
    // let day = addZeros(date.getUTCDate());
    // let month = addZeros(date.getUTCMonth());
    // let year = date.getFullYear();
    // let hour = addZeros(date.getUTCHours());
    // let minute = addZeros(date.getUTCMinutes());
    // let second = addZeros(date.getUTCSeconds());
    return `${year}-${month}-${day} ${hour}:${minute}`;
  }

  function cargarClubs() {
    let clubes: any[] = [];

    currentConfig?.clubs.map((x: any) => {
      if (x.id_admin === currentUser?.id_player) {
        clubes.push({
          label: x.displayname,
          value: x.id,
        });
      }
    });
    setClubList(clubes);
  }

  function formatDate(dateinput: string) {
    let ddate = new Date(dateinput);
    let date = new Date(
      Date.UTC(ddate.getFullYear(), ddate.getMonth(), ddate.getDate())
    );
    let day = addZeros(date.getUTCDate());
    let month = addZeros(date.getUTCMonth());
    let year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  function formatDatetime2(dateinput: string) {
    //substring date

    const year = dateinput.substring(0, 4);
    const month = dateinput.substring(5, 7);
    const day = dateinput.substring(8, 10);
    const hour = dateinput.substring(11, 13);
    const minute = dateinput.substring(14, 16);
    const second = dateinput.substring(17, 19);
    const fecha = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    ////console.log(fecha);
    //const dateutc = new Date(Date.UTC(parseInt(year), parseInt(month), parseInt(day), parseInt(hour), parseInt(minute), parseInt(second)));
    const dateutc2 = new Date(Date.parse(fecha));
    //const dateutc3 = new Date(fecha);
    // //console.log("new Date(Date.UTC",dateutc);
    // //console.log("new Date(date.parse(",dateutc2);
    // //console.log("new date(fecha)",dateutc3);
    return dateutc2;
  }

  const deleteVideo = (id: string) => {
    Swal.fire({
      title: Textos[language].eventdeletevideoq,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#060707",
      cancelButtonColor: "#f44336",
      confirmButtonText: `${t("Events.Buttons.deleteVideo")}`,
      cancelButtonText: `${t("Events.Buttons.cancel")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteVideoById(id)
          .then((resp: any) => {
            Swal.fire({
              icon: "success",
              title: `${t("Events.Modals.eventDeleteVideoOk")}`,
            });
            window.location.reload();
          })
          .catch((err: any) => {
            console.error("Hubo un problema al borrar el video", err);
            Swal.fire({
              icon: "error",
              title: `${t("Events.Modals.eventDeleteVideoError")}`,
            });
          });
        // Get(`${URL_API}/event/quit/${id}`).then(
        //   (res) => {
        //     Swal.fire({
        //       icon: "success",
        //       title: Textos[language].eventdeletevideook,
        //     });
        //     window.location.reload();
        //   },
        //   (err) => {
        //     Swal.fire({
        //       icon: "error",
        //       title: Textos[language].eventdeletevideoerror,
        //     });
        //     //console.log(err);
        //   }
        // );
      }
    });
  };

  const updateEvent = () => {
    // if (
    //   evento.eventinitime.includes("z") ||
    //   evento.eventinitime.includes("Z")
    // ) {
    //   evento.eventinitime = formatDatetime(evento.eventinitime);
    // }
    // if (
    //   evento.eventendtime.includes("z") ||
    //   evento.eventendtime.includes("Z")
    // ) {
    //   evento.eventendtime = formatDatetime(evento.eventendtime);
    // }

    // if (evento.eventdate.includes("GMT-")) {
    //   evento.eventdate = evento.eventinitime.split(" ")[0];
    // }
    // if (
    //   evento.eventenddate.includes("z") ||
    //   evento.eventenddate.includes("Z")
    // ) {
    //   evento.eventenddate = evento.eventendtime.split(" ")[0];
    // }
    // if (
    //   evento.eventinitdate.includes("z") ||
    //   evento.eventinitdate.includes("Z")
    // ) {
    //   evento.eventinitdate = evento.eventinitime.split(" ")[0];
    // }
    // Revisa si la fecha contiene 'Z' o 'z' y la formatea
    if (
      evento.eventinitime.includes("z") ||
      evento.eventinitime.includes("Z")
    ) {
      evento.eventinitime = moment(evento.eventinitime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }
    if (
      evento.eventendtime.includes("z") ||
      evento.eventendtime.includes("Z")
    ) {
      evento.eventendtime = moment(evento.eventendtime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }

    // Ajusta la fecha del evento si incluye una zona horaria
    if (evento.eventdate.includes("GMT-")) {
      evento.eventdate = moment(evento.eventinitime).format("YYYY-MM-DD");
    }
    if (
      evento.eventenddate.includes("z") ||
      evento.eventenddate.includes("Z")
    ) {
      evento.eventenddate = moment(evento.eventendtime).format("YYYY-MM-DD");
    }
    if (
      evento.eventinitdate.includes("z") ||
      evento.eventinitdate.includes("Z")
    ) {
      evento.eventinitdate = moment(evento.eventinitime).format("YYYY-MM-DD");
    }
    //update http
    const values = {
      id: evento.id,
      eventname: evento.eventname,
      eventdate: evento.eventdate,
      id_club: evento.id_club,
      eventenddate: evento.eventenddate,
      eventendtime: evento.eventendtime,
      eventinitdate: evento.eventinitdate,
      eventinitime: evento.eventinitime,
      urlimagen: captureImgEvent ? captureImgEvent : evento.urlimagen,
    };
    //console.log("QUE TRAE VALUES DE EDIT?", values);
    updateEventByiD(evento.id, values)
      .then((resp: any) => {
        if (resp.status === 200) {
          Swal.fire({
            icon: "success",
            title: `${t("Events.Modals.eventUpdateOk")}`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `${t("Events.Modals.eventUpdateError")}`,
          });
        }
        window.location.href = "/eventos";
      })
      .catch((err: any) => {
        console.error("Error al actualizar el evento", err);
        Swal.fire({
          icon: "error",
          title: `${t("Events.Modals.eventUpdateError")}`,
        });
      });
  };

  function AdminClub(idplayer: number, idclub: number) {
    let retorno = false;
    currentConfig?.clubs.map((x: any) => {
      if (x.id === idclub) {
        if (x.id_admin === idplayer) {
          retorno = true;
        }
      }
    });
    return retorno;
  }

  return (
    <div>
      {evento.urlimagen !== null ? (
        <div
          className="bannerImage"
          style={{
            backgroundImage: `url('${evento.urlimagen}')`,
          }}
        >
          <div className="titleEvent">{evento?.eventname}</div>
        </div>
      ) : (
        <div className="bannerTitle">
          <div className="titleEvent">{evento?.eventname}</div>
        </div>
      )}

      <div>
        {currentUser !== null ? (
          currentUser.id_membership === 0 ||
          AdminClub(currentUser?.id_player, evento.id_club) ? (
            <div className="flex items-center justify-end py-3">
              <Button
                className="h-12 mx-4 text-base font-medium text-center text-white bg-black hover:ring-2 hover:ring-black hover:text-black hover:bg-white"
                onClick={() => {
                  if (currentUser !== null) {
                    if (
                      currentUser.id_membership === 0 ||
                      AdminClub(currentUser?.id_player, evento.id_club)
                    ) {
                      seteditarEvento(true);
                      cargarClubs();
                    } else {
                      Swal.fire({
                        icon: "error",
                        title: `${t("Evens.Modals.doesnothavepermission")}`,
                      });
                    }
                  }
                }}
              >
                <FontAwesomeIcon className="eventicon" icon={faEdit} />
                {t("Events.editEvent")}
              </Button>

              <Button
                className="h-12 mx-4 text-base font-medium text-center text-white bg-red-500 hover:ring-2 hover:bg-red-400"
                onClick={() => {
                  if (currentUser !== null) {
                    if (
                      currentUser.id_membership === 0 ||
                      AdminClub(currentUser?.id_player, evento.id_club)
                    ) {
                      Swal.fire({
                        icon: "warning",
                        title: `${t("Events.Modals.titleDelete")}`,
                        text: `${t("Events.Modals.descriptionDelete")}`,
                        confirmButtonColor: "#060707",
                        cancelButtonColor: "#f44336",
                        showCancelButton: true,
                        confirmButtonText: `${t("Events.Buttons.delete")}`,
                        cancelButtonText: `${t("Events.Buttons.cancel")}`,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deleteEvent(evento.id);
                        }
                      });
                    } else {
                      Swal.fire({
                        icon: "error",
                        title: `${t("Events.Modals.doesnothavepermission")}`,
                      });
                    }
                  }
                }}
              >
                <FontAwesomeIcon className="eventicon" icon={faTrash} />
                {t("Events.Buttons.delete2")}
              </Button>
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        <InfiniteScroll
          dataLength={data.length} //This is important field to render the next data
          next={() => {
            let count = page + 1;
            // console.log("se está sumando mas videos?", count);
            setPage(count);
            getDataEvent(count);
          }}
          hasMore={Hasmore}
          loader={
            loading ? (
              <div className="cssload-loader">
                <div className="cssload-inner cssload-one"></div>
                <div className="cssload-inner cssload-two"></div>
                <div className="cssload-inner cssload-three"></div>
              </div>
            ) : (
              <></>
            )
          }
          refreshFunction={() => {
            if (Hasmore) {
              getEventsById(0);
            }
          }}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
        >
          <div className="bodypage">
            {loading ? (
              <></>
            ) : data.length > 0 ? (
              <></>
            ) : (
              <h4 className="mt-3 text-lg font-semibold text-center">
                {t("Home.NoVideo")}
              </h4>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-around",
                width: "100%",
                marginBottom: "20px",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              {data.map((item: any, index) => {
                // console.log("datos del usuario?",item);
                return (
                  <Fragment key={index}>
                    <div>
                      {currentUser !== null ? (
                        currentUser.id_membership === 0 ||
                        AdminClub(currentUser?.id_player, evento.id_club) ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <div
                              style={{
                                marginRight: "15px",
                                marginBottom: "-25px",
                              }}
                            >
                              <button
                                style={{
                                  backgroundColor: "transparent",
                                  color: "red",
                                }}
                                onClick={() => {
                                  if (currentUser !== null) {
                                    if (
                                      currentUser.id_membership === 0 ||
                                      currentUser.id_membership === 3
                                    ) {
                                      deleteVideo(item.id);
                                    } else {
                                      Swal.fire({
                                        icon: "error",
                                        title: `${t(
                                          "Events.Modals.doesnothavepermission"
                                        )}`,
                                      });
                                    }
                                  }
                                }}
                              >
                                <FontAwesomeIcon icon={faCircleXmark} />
                              </button>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      <CardPVVideo
                        status={
                          item.ishighlight
                            ? "highlight"
                            : item.islive
                            ? "live"
                            : "stream"
                        }
                        viewsCount={item.views}
                        LikesCount={item.like}
                        dislikesCount={item.dislike}
                        cameraName={item.cameraData}
                        duration={ProcesarDuracion(
                          new Date(item.initialtime),
                          item.endtime ? new Date(item.endtime) : new Date()
                        )}
                        title={item.description}
                        club={
                          item.clubData.displayname +
                          "  " +
                          item.clubData.countryicon
                        }
                        user={item.userdata.firstname}
                        imageuser={
                          item.userdata.photoicon
                            ? item.userdata.photoicon
                            : "https://storage.googleapis.com/publicsportprotv/892781.png"
                        }
                        previewimage={
                          item.photopath === null
                            ? "https://via.placeholder.com/320x180"
                            : item.photopath
                        }
                        id={item.id}
                        alldata={item}
                        date={item.initialtime}
                        highlightcat={item.highlightcat}
                      />
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </InfiniteScroll>
      </div>
      <ModalComponent
      open={editarEvento}
      onClose={() => seteditarEvento(false)}
      >
          <div className="flex items-center justify-start w-full mt-3 text-center">
          <InputAntd
            showCount
            maxLength={50}
            onChange={(value: any) => {
              setEvento({
                ...evento,
                eventname: value.target.value,
              });
            }}
            placeholder={t("Events.nameEvent")}
            value={evento ? evento.eventname : ""}
          />
        </div>
        {evento.eventname === "" && (
          <div>
            <p style={{ color: "red" }} className="mt-2">
              * {t("Events.fieldRequired")}
            </p>
          </div>
        )}

        <div className="flex items-center justify-start w-full mt-3 text-center">
          <div className="flex flex-col items-center justify-start w-1/2">
            <div>
              <p className="pt-2 text-base font-medium text-center from-neutral-700">
                {t("Events.startEvent")}
              </p>
            </div>
            <DatePicker
              format="yyyy-MM-dd HH:mm"
              defaultValue={
                evento.eventinitdate && evento.eventinitdate
                  ? moment(evento.eventinitime, "YYYY-MM-DD HH:mm").toDate()
                  : moment().toDate()
              }
              style={{
                zIndex:"9999999px"
              }}
              locale={{
                sunday: `${t("Events.Days.do")}`,
                monday: `${t("Events.Days.lu")}`,
                tuesday: `${t("Events.Days.ma")}`,
                wednesday: `${t("Events.Days.mi")}`,
                thursday: `${t("Events.Days.ju")}`,
                friday: `${t("Events.Days.vi")}`,
                saturday: `${t("Events.Days.sa")}`,
                ok: "OK",
                today: `${t("Events.Days.today")}`,
                yesterday: `${t("Events.Days.yest")}`,
                hours: `${t("Events.Days.hours")}`,
                minutes: `${t("Events.Days.min")}`,
                seconds: `${t("Events.Days.sec")}`,
              }}
              onChange={(value) => {
                console.log("Que trae value?", value);
                let start = value ? moment(value) : moment();

                //Formateamos la fecha y hora usando moment
                const dateString = start.format("YYYY-MM-DD");
                const timeString = start.format("HH:mm:ss");

                // Imprime las cadenas de fecha y hora para depuración
             

                //Aseguramos que si la hora es 00:00:00,establezca al menos un segundo:
                if (
                  start.hour() === 0 &&
                  start.minute() === 0 &&
                  start.second() === 0
                ) {
                  start.second(1);
                }

                //Actualizamos el evento con valores de fecha y hora formateado:
                setEvento({
                  ...evento,
                  eventinitdate: dateString,
                  eventdate: dateString,
                  eventinitime: timeString,
                });
              }}
            />
          </div>
          <div className="flex flex-col items-center justify-start w-1/2 ml-10">
            <div>
              <p className="pt-2 text-base font-medium text-center from-neutral-700">
                {t("Events.endEvent")}
              </p>
            </div>
            <DatePicker
              format="yyyy-MM-dd HH:mm:ss"
              defaultValue={
                evento.eventenddate && evento.eventenddate
                  ? moment(evento.eventendtime, "YYYY-MM-DD HH:mm").toDate()
                  : new Date()
              }
              locale={{
                sunday: `${t("Events.Days.do")}`,
                monday: `${t("Events.Days.lu")}`,
                tuesday: `${t("Events.Days.ma")}`,
                wednesday: `${t("Events.Days.mi")}`,
                thursday: `${t("Events.Days.ju")}`,
                friday: `${t("Events.Days.vi")}`,
                saturday: `${t("Events.Days.sa")}`,
                ok: "OK",
                today: `${t("Events.Days.today")}`,
                yesterday: `${t("Events.Days.yest")}`,
                hours: `${t("Events.Days.hours")}`,
                minutes: `${t("Events.Days.min")}`,
                seconds: `${t("Events.Days.sec")}`,
              }}
              onChange={(value) => {
                console.log("que trae value?", value);
                //Si value es null, se usa la fecha y hora actual:
                if (!value) {
                  value = new Date();
                }
                let momentDate = moment(value);

                //Formatea la fecha y hora como string
                const dateEndString = momentDate.format("YYYY-MM-DD");
                const timeString = momentDate.format("HH:mm:ss");
                if (!momentDate.isValid()) {
                  console.error('La fecha proporcionada no es valida:',momentDate);
                  return;
                }
                  //Si la hora, minuto y segundo son 0 , se establece el segundo a 1:
                  if (
                    momentDate.hour() === 0 &&
                    momentDate.minute() === 0 &&
                    momentDate.second() === 0
                  ) {
                    momentDate.second(1);
                  }
                  //Asegurarnos que el evento la fecha inicial y la fecha final no sean iguales:
                  console.log("evento?:", {
                    fechaInicial: evento.eventinitdate,
                    fechaFinal: dateEndString,
                  });
                  if (evento.eventinitdate === dateEndString) {
                    Swal.fire(
                      "Ups , hubo un problema",
                      `La fecha inicial: ${evento.eventinitdate}  y la fecha final: ${dateEndString}  no pueden ser iguales!`,
                      "error"
                    );
                    setEvento({
                      ...evento,
                      eventenddate: "",
                      eventendtime: "",
                    });
                  } else {
                    //Actualizamos el estado con las nuevas fechas formateadas
                    setEvento({
                      ...evento,
                      eventenddate: dateEndString,
                      eventendtime:timeString,
                    });
                  }
              }}
              className="z-[99999999]"
            />
          </div>
        </div>
        {evento.eventinitdate === "" && (
          <div>
            <p style={{ color: "red" }} className="mt-2">
              * {t("Events.fieldRequired")}
            </p>
          </div>
        )}
        <div className="flex flex-col items-center justify-start w-full mt-3 text-center">
          <div>
            <p className="pt-2 text-base font-medium text-center from-neutral-700">
              {t("Events.club")}
            </p>
          </div>
          <SelectPicker
            locale={idioma}
            searchable={false}
            data={ClubList}
            value={evento.id_club}
            // defaultValue={evento.id_club}
            style={{ width: "100%" }}
            onChange={(value: any) => {
              if (value === null) value = 0;
              setEvento({
                ...evento,
                id_club: parseInt(value),
              });
            }}
          />
        </div>
        {evento.id_club === 0 && (
          <div>
            <p style={{ color: "red" }} className="mt-2">
              * {t("Events.fieldRequired")}
            </p>
          </div>
        )}
        <div className="flex flex-col items-center justify-start w-full mt-3 text-center">
          <div>
            <p className="pt-2 mb-3 text-base font-medium text-center from-neutral-700">
              {t("Events.image")}
            </p>
          </div>
          {evento.urlimagen && !uploading ? (
            <div>
              <Image width={"100%"} src={evento.urlimagen}></Image>
              <div className="flex items-center justify-start">
                <Button
                  className="text-base font-medium text-center text-white bg-black hover:ring-2 hover:ring-black hover:text-black hover:bg-white"
                  onClick={() => {
                    setUploading(true);
                  }}
                >
                  <p>{t("Events.Buttons.editImage")}</p>
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <Dragger {...props} listType="picture">
                <div className="flex items-center justify-center w-full ant-upload-drag-icon">
                  <Lottie
                    animationData={uploadAnim}
                    style={{
                      width: 120,
                      height: 120,
                    }}
                  />
                </div>

                <p className="mx-20 text-base font-medium text-center text-neutral-600">
                  {t("Events.clickorDrag")}
                </p>
                <p className="font-thin text-center text-red-600">
                  {t("Events.clickorDrag_description")}
                </p>
              </Dragger>
              <div className="flex items-center justify-start pt-3">
                <Button
                  className="text-base font-medium text-center text-white bg-black hover:ring-2 hover:ring-black hover:text-black hover:bg-white"
                  onClick={() => {
                    setUploading(false);
                  }}
                >
                  <p>{t("Events.Buttons.backImage")}</p>
                </Button>
              </div>
            </div>
          )}
          <div className="flex flex-row items-center justify-between w-full px-20 mt-5">
          <ButtonAntd
            key="back"
            onClick={() => {
              seteditarEvento(false);
            }}
            className="bg-red-500 rounded-xxl hover:bg-red-700"
          >
            <p className="text-base font-medium text-center text-white hover:text-gray-500">
              {" "}
              {t("Events.Buttons.cancel")}
            </p>
          </ButtonAntd>
          <ButtonAntd
            key="submit"
            loading={loading}
            onClick={() => {
              updateEvent();
            }}
            className="bg-black rounded-xxl hover:bg-slate-300"
          >
            <p className="text-base font-medium text-center text-white hover:text-black">
              {" "}
              {t("Events.Buttons.save")}
            </p>
          </ButtonAntd>
          </div>
        </div>

      </ModalComponent>

    </div>
  );
}
