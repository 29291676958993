import React, { createContext, useEffect, useState } from "react";

const initialValue: any = null;

export const SaveDataHLProvider = createContext(initialValue);

const DataSaveHLProvider = ({ children }) => {
  const [saveDataHL, setSaveDataHL] = useState<any>({
    from: 0,
    to: 0,
    description: "",
    isPrivate: false,
    category: "",
    duration: 0,
  });

  const addSaveHLData = (values: any) => {
    if (values !== null || values !== undefined) {
      if (values.from > 0 || values.to > 0) {
        setSaveDataHL({
          from: values.from,
          to: values.to,
          description: values.description,
          isPrivate: values.isPrivate,
          category: values.category,
          duration: values.duration,
        });
      } else {
        return;
      }
    }
  };

  const cleanSaveHLData = () => {
    setSaveDataHL({});
  };

  return (
    <SaveDataHLProvider.Provider
      value={{ setSaveDataHL, saveDataHL, addSaveHLData, cleanSaveHLData }}
    >
      {children}
    </SaveDataHLProvider.Provider>
  );
};

export default DataSaveHLProvider;
