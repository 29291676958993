import { API_URL, API_URL_LOCAL_DEV, API_URL_DEV } from "../../env";
import { Get, Post, Put } from "../consumir";

export const getPredictiveUser = async (username: string) => {
  try {
    const response = await Get(
      `${API_URL}/video/checkuser/${username}`
    );
    return response;
  } catch (error) {
    console.error(
      "ERROR NETWORK CONN USERNAME SERVICE",
      error
    );
  }
};

export const updateTagsUsers = async (id: string, tagsArray: any) => {
  try {
    const response = await Put(
      `${API_URL}/video/updatetaguser/${id}`,
      tagsArray
    );

    return response;
  } catch (error) {
    console.error(
      " ERROR NETWORK CONN UPDATE TAGS USERS",
      error
    );
  }
};

export const deleteTagUserByName = async (id: string, nickname: string) => {
  try {
    const response = await Put(
      `${API_URL}/video/removetaguser/${id}`,
      {nickname}
    );

    return response;
  } catch (error) {
    console.error(
      " ERROR NETWORK CONN DELETE TAGS USER",
      error
    );
  }
};
