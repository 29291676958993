import {
  Container,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import {
  Sidenav,
  Nav,
  Col,
  ButtonGroup,
  Button as Botton,
  IconButton,
} from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faAngleRight,
  faHome,
  faClapperboard,
  faTableTennis,
  faPeopleRoof,
  faUser,
  faRightFromBracket,
  faStop,
  faStopwatch,
  faStopCircle,
  faFileVideo,
  faQuestion,
  faClipboardQuestion,
  faManatSign,
  faPrescription,
  faContactCard,
  faPlaneArrival,
  faPlane,
  faPlaneUp,
  faPlaneCircleCheck,
  faPlaneDeparture,
  faPersonBreastfeeding,
  faPersonDotsFromLine,
  faHeadset,
  faDollyBox,
  faDollarSign,
  faMoneyBill,
  faPlay,
  faPeopleGroup,
  faPersonRunning,
  faMedal,
  faCalendarCheck,
  faCertificate,
  faUserGear,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../utils/firebase";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../utils/Auth";
import ConfigContext from "../utils/Config";
import { useQuery } from "../Rutas";
import { faEvernote } from "@fortawesome/free-brands-svg-icons";
import Swal from "sweetalert2";
import {
  RiFacebookBoxFill,
  RiTwitterFill,
  RiInstagramFill,
  RiLinkedinFill,
  RiMenuFill,
} from "react-icons/ri";
import type { MenuProps } from "antd";
import { Dropdown, Space, Button as ButtonAntd } from "antd";
import { MdTranslate , MdLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";

export function NavBar() {
  const navigate = useNavigate();
  const { currentUser, GetUser, logoutUser, auth } = useContext(AuthContext);
  const { currentConfig, GetConfig, visibleNav, setVisibleNav } =
    useContext(ConfigContext);

  const [t, i18n] = useTranslation("global");

  let query = useQuery();

  useEffect(() => {
    setTimeout(function () {
      GetUser();
    }, 100);
    GetConfig();
  }, []);

  function OrderClubs(): any[] {
    if (currentConfig) {
      let clubs = currentConfig.clubs;
      clubs.sort(function (a: any, b: any) {
        if (a.displayname < b.displayname) {
          return -1;
        }
        if (a.displayname > b.displayname) {
          return 1;
        }
        return 0;
      });
      return clubs;
    }
    return [];
  }

  //function order sports
  function OrderSports(): any[] {
    if (currentConfig) {
      let sports = currentConfig.sports;
      sports.sort(function (a: any, b: any) {
        if (a.sportname < b.sportname) {
          return -1;
        }
        if (a.sportname > b.sportname) {
          return 1;
        }
        return 0;
      });
      return sports;
    }
    return [];
  }

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <p
          onClick={() => {
            localStorage.setItem("language", "en");
            i18n.changeLanguage("en");
            window.location.reload();
          }}
         
        >
          🇺🇸 {t("Languages.English")}
        </p>
      ),
    },
    {
      key: "1",
      label: (
        <p
          onClick={() => {
            localStorage.setItem("language", "es");
            i18n.changeLanguage("es");
            window.location.reload();
          }}
        >
          🇪🇸 {t("Languages.Spanish")}
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p
          onClick={() => {
            localStorage.setItem("language", "pr");
            i18n.changeLanguage("pr");
            window.location.reload();
          }}
        >
          🇧🇷 {t("Languages.Portuguese")}
        </p>
      ),
    },
  ];

  const handleFB = () => {
    window.open("https://www.facebook.com/SportProTVstream");
  };
  const handleIG = () => {
    window.open("https://www.instagram.com/sportpro.tv/");
  };

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        className="flex flex-row items-center justify-between w-full"
      >
        <div className="flex justify-start">
          <Botton
            onClick={() => {
              setVisibleNav((prev) => !prev);
            }}
            className="bg-white rounded-md text-cente text-slate-400 ring-2 ring-gray-600 hover:ring-white hover:bg-gray-600 hover:text-white"
          >
            <RiMenuFill className="text-base tablet:max-2kScreen:text-xl" />
       
          </Botton>
        </div>
        <div
          onClick={() => {
            setVisibleNav(false);
            navigate("/");
          }}
          className="w-10/12"
        >
          <img src="/img/Sport-pro-tv.png" alt="Logo" className="w-10/12" />
        </div>
        <div className="items-center justify-center hidden laptop:max-2kScreen:flex laptop:max-2kScreen:w-full laptop:max-2kScreen:justify-end">
          {currentUser === null ? (
            <button
              onClick={() => {
                navigate("/login");
              }}
              className="iniciarsesion"
            >
              <FontAwesomeIcon
                className="buttonlikedislikeicon"
                icon={faUser}
              />
              {t("Header.signIn")}
            </button>
          ) : (
            <div>
              <label className="labeluser">{currentUser?.nickname}</label>
              <button
                onClick={async () => {
                  await logout();
                  logoutUser();
                  localStorage.clear();
                  navigate("/");
                }}
                className="iniciarsesion"
              >
                <FontAwesomeIcon
                  className="buttonlikedislikeicon"
                  icon={faRightFromBracket}
                />
                {t("Header.signOut")}
              </button>
            </div>
          )}
          <div className="items-center justify-center hidden laptop:max-2kScreen:flex laptop:max-2kScreen:w-1/6">
            <Dropdown placement="bottom" menu={{ items }}>
              <ButtonAntd
                type="primary"
                shape="circle"
                size="middle"
                icon={ i18n.language === "en"  || i18n.language === 'en-US' ? <p className="text-black"> us </p> : i18n.language === "es"  ? <p> 🇪🇸 </p> : i18n.language === 'pr' || i18n.language === 'pt-BR' ? <p> 🇧🇷 </p> : <MdLanguage size={20} className=" text-cyan-500" />}
                className="flex items-center justify-center font-mono text-black bg-transparent hover:text-white hover:bg-black"
              >
                
              </ButtonAntd>
            </Dropdown>
          </div>
         
          
        </div>
          {/* Mobiles */}
        <div className="items-center justify-center w-1/6 float laptop:max-2kScreen:hidden">
            <Dropdown placement="bottom" menu={{ items }}>
              <ButtonAntd
                type="default"
                shape="circle"
                icon={
                  i18n.language === "en"  || i18n.language === 'en-US' ? <p> 🇺🇸 </p> : i18n.language === "es"  ? <p> 🇪🇸 </p> : i18n.language === 'pr' || i18n.language === 'pt-BR' ? <p> 🇧🇷 </p> : <MdLanguage size={20} className=" text-cyan-500" />
                }
                className="flex items-center justify-center"
              />
            </Dropdown>
          </div>
        <div className="flex flex-row items-center justify-end w-full laptop:max-2kScreen:w-1/2">
          <p className="text-sm font-medium text-left text-gray-600 laptop:max-2kScreen:text-lg ">
            {t("Header.followers")}
          </p>
          <ButtonGroup>
            <IconButton
              className="hover:bg-transparent"
              icon={
                <RiFacebookBoxFill
                  // size={30}
                  className="text-xl text-blue-400 hover:text-blue-500 laptop:max-2kScreen:text-3xl"
                  onClick={handleFB}
                />
              }
              circle
            />
            {/* <IconButton
            className="hover:bg-transparent"
            icon={
              <RiTwitterFill
                size={20}
                className="text-blue-500 hover:text-blue-800"
              />
            }
            circle
          /> */}
            <IconButton
              className="hover:bg-transparent"
              icon={
                <RiInstagramFill
                  // size={30}
                  className="text-xl text-pink-400 hover:text-pink-500 laptop:max-2kScreen:text-3xl"
                />
              }
              onClick={handleIG}
              circle
            />
            {/* <IconButton
            className="hover:bg-transparent"
            icon={
              <RiLinkedinFill
                size={20}
                className="text-blue-600 hover:text-blue-700"
              />
            }
            circle
          /> */}
          </ButtonGroup>
        </div>
      </Col>

      <Navbar
        bg="#ccd6e0"
        onToggle={() => {
          setVisibleNav(!visibleNav);
        }}
        expanded={visibleNav}
        expand="default"
        className="mb-3"
      >
        <Container className="w-full">
          <div className="w-full">
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${"md"}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${"md"}`}
              placement="start"
              style={{
                width: "260px",
              }}
            >
              <Offcanvas.Header>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"md"}`}>
                  <img
                    className="logocenter"
                    src="/img/SportProTVv2.gif"
                    width={150}
                    alt="logo gif"
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {currentUser === null ? (
                  <></>
                ) : (
                  <div className="labelsdatauser">
                    <label className="labeluser">
                      {currentUser?.firstname + " " + currentUser?.lastname}
                    </label>
                    <label className="labelemail">
                      {currentUser?.nickname}
                    </label>
                    <div>
                      <img
                        className="circular--square"
                        src={currentUser?.photoicon}
                        alt="use_photo"
                      />
                    </div>
                    <label
                      onClick={() => {
                        setVisibleNav(false);
                        navigate("/perfil");
                      }}
                      className="text-primary opacity-75-hover fs-15 fw-semibold"
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                    >
                      {t("Nav_Menu.Profile")}
                    </label>
                  </div>
                )}

                <div style={{ width: "100%" }}>
                  <Sidenav appearance="default" defaultOpenKeys={[]}>
                    <Sidenav.Body>
                      <Nav activeKey="1">
                        <Nav.Item
                          eventKey="1"
                          onClick={() => {
                            setVisibleNav(false);
                            navigate("/");
                          }}
                        >
                          <div className="itemsidenav">
                            <div>
                              <FontAwesomeIcon
                                className="iconsidenav"
                                icon={faHome}
                                style={{
                                  width:'15',
                                  height:'15'
                                }}
                              />
                              <label className="labelsidenav">
                                {t("Nav_Menu.Home")}
                              </label>
                            </div>
                            <FontAwesomeIcon
                              className="iconfechasidenav"
                              icon={faAngleRight}
                              style={{
                                width:'15',
                                height:'15'
                              }}
                            />
                          </div>
                        </Nav.Item>
                        <Nav.Item
                          eventKey="1"
                          onClick={() => {
                            setVisibleNav(false);

                            navigate("/?typev=live");
                          }}
                        >
                          <div className="itemsidenav">
                            <div>
                              <FontAwesomeIcon
                                className="iconsidenav"
                                icon={faClapperboard}
                                style={{
                                  width:'15',
                                  height:'15'
                                }}
                              />
                              <label className="text-left labelsidenav">
                                {t("Nav_Menu.InLive")}
                              </label>
                            </div>
                            <FontAwesomeIcon
                              className="iconfechasidenav"
                              icon={faAngleRight}
                              style={{
                                width:'15',
                                height:'15'
                              }}
                            />
                          </div>
                        </Nav.Item>

                        {currentUser === null ? (
                          <></>
                        ) : (
                          <Nav.Item
                            eventKey="1"
                            onClick={() => {
                              setVisibleNav(false);
                              navigate("/?user=" + currentUser.id_player);
                            }}
                          >
                            <div className="itemsidenav">
                              <div>
                                <FontAwesomeIcon
                                  className="iconsidenav"
                                  icon={faPlay}
                                  style={{
                                    width:'15',
                                    height:'15'
                                  }}
                                />
                                <label className="labelsidenav">
                                  {t("Nav_Menu.MyStreams")}
                                </label>
                              </div>
                              <FontAwesomeIcon
                                className="iconfechasidenav"
                                icon={faAngleRight}
                                style={{
                                  width:'15',
                                  height:'15'
                                }}
                              />
                            </div>
                          </Nav.Item>
                        )}

                        <Nav.Item
                          eventKey="9"
                          onClick={() => {
                            if (currentUser) {
                              setVisibleNav(false);
                              navigate("/planessocios");
                            } else {
                              setVisibleNav(false);
                              Swal.fire(`
                                ${t("Header.signIn")},
                                ${t("Nav_Menu.Modals.youCan")},
                                "warning"`
                              );
                              navigate("/login");
                            }
                          }}
                        >
                          <div className="itemsidenav">
                            <div onClick={() => navigate("/terminos")}>
                              <FontAwesomeIcon
                                className="iconsidenav"
                                icon={faCertificate}
                                style={{
                                  width:'15',
                                  height:'15'
                                }}
                              />
                              <label className="labelsidenav">
                                {t("Nav_Menu.Membership")}
                              </label>
                            </div>
                            <FontAwesomeIcon
                              className="iconfechasidenav"
                              icon={faAngleRight}
                              style={{
                                width:'15',
                                height:'15'
                              }}
                            />
                          </div>
                        </Nav.Item>

                        {currentUser?.id_membership === 0 ||
                        currentUser?.id_membership === 3 ? (
                          <Nav.Item
                            onClick={() => {
                              setVisibleNav(false);
                              navigate("/clubadmin");
                            }}
                          >
                            <div className="itemsidenav">
                              <div>
                                <FontAwesomeIcon
                                  className="iconsidenav"
                                  icon={faUserGear}
                                  style={{
                                    width:'15',
                                    height:'15'
                                  }}
                                />
                                <label className="labelsidenav">
                                  {t("Nav_Menu.Admin_Club")}
                                </label>
                              </div>
                              <FontAwesomeIcon
                                className="iconfechasidenav"
                                icon={faAngleRight}
                                style={{
                                  width:'15',
                                  height:'15'
                                }}
                              />
                            </div>
                          </Nav.Item>
                        ) : (
                          <></>
                        )}

                        <Nav.Item
                          eventKey="9"
                          onClick={() => {
                            setVisibleNav(false);
                            navigate("/eventos");
                          }}
                        >
                          <div className="itemsidenav">
                            <div>
                              <FontAwesomeIcon
                                className="iconsidenav"
                                icon={faCalendarCheck}
                                style={{
                                  width:'15',
                                  height:'15'
                                }}
                              />
                              <label className="labelsidenav">
                                {t("Nav_Menu.Events_tornament")}
                              </label>
                            </div>
                            <FontAwesomeIcon
                              className="iconfechasidenav"
                              icon={faAngleRight}
                              style={{
                                width:'15',
                                height:'15'
                              }}
                            />
                          </div>
                        </Nav.Item>

                        <Nav.Menu
                          eventKey="3"
                          title={
                            <div className="itemsidenav">
                              <div>
                                <FontAwesomeIcon
                                  className="iconsidenav"
                                  icon={faPeopleRoof}
                                  style={{
                                    width:'15',
                                    height:'15'
                                  }}
                                />
                                <label className="labelsidenav">
                                  {t("Nav_Menu.Sporting_Clubs")}
                                </label>
                              </div>
                            </div>
                          }
                        >
                          {
                            // currentConfig?.clubs.map((club: any, index: any) => {
                            //     return (
                            //         <Nav.Item onClick={() => {
                            //             setVisibleNav(false);
                            //             query.set("club", club.id);
                            //             navigate(`/?club=${club.id}`);
                            //         }} eventKey="3-1" key={index}>
                            //             <FontAwesomeIcon className="iconsidenav" icon={faMedal}/>
                            //             {club.displayname}
                            //             </Nav.Item>
                            //     )
                            // })
                            OrderClubs().map((club: any, index: any) => {
                              return (
                                <Nav.Item
                                  onClick={() => {
                                    setVisibleNav(false);
                                    query.set("club", club.id);
                                    navigate(`/?club=${club.id}`);
                                  }}
                                  eventKey="3-1"
                                  key={index}
                                >
                                  <FontAwesomeIcon
                                    className="iconsidenav"
                                    icon={faMedal}
                                    style={{
                                      width:'15',
                                      height:'15'
                                    }}
                                  />
                                  {club.displayname}
                                </Nav.Item>
                              );
                            })
                          }
                        </Nav.Menu>

                        <Nav.Menu
                          eventKey="4"
                          title={
                            <div className="itemsidenav">
                              <div>
                                <FontAwesomeIcon
                                  className="iconsidenav"
                                  icon={faPersonRunning}
                                  style={{
                                    width:'15',
                                    height:'15'
                                  }}
                                />
                                <label className="labelsidenav">
                                  {t("Nav_Menu.Sports")}
                                </label>
                              </div>
                            </div>
                          }
                        >
                          {OrderSports().map((sportclub: any, index: any) => {
                            return (
                              <Nav.Item
                                onClick={() => {
                                  setVisibleNav(false);
                                  navigate(`/?sport=${sportclub.id}`);
                                }}
                                eventKey="3-1"
                                key={index}
                              >
                                {sportclub.sportname}
                              </Nav.Item>
                            );
                          })}
                        </Nav.Menu>

                        <Nav.Item
                          eventKey="5"
                          onClick={() => {
                            setVisibleNav(false);
                            navigate("/acercade");
                          }}
                        >
                          <div className="itemsidenav">
                            <div onClick={() => navigate("/acercade")}>
                              <FontAwesomeIcon
                                className="iconsidenav"
                                icon={faPeopleGroup}
                                style={{
                                  width:'15',
                                  height:'15'
                                }}
                              />
                              <label className="labelsidenav">
                                {t("Nav_Menu.Weus")}
                              </label>
                            </div>
                            <FontAwesomeIcon
                              className="iconfechasidenav"
                              icon={faAngleRight}
                              style={{
                                width:'15',
                                height:'15'
                              }}
                            />
                          </div>
                        </Nav.Item>
                        <Nav.Item
                          eventKey="6"
                          onClick={() => {
                            setVisibleNav(false);
                            navigate("/faq");
                          }}
                        >
                          <div className="itemsidenav">
                            <div onClick={() => navigate("/faq")}>
                              <FontAwesomeIcon
                                className="iconsidenav"
                                icon={faClipboardQuestion}
                                style={{
                                  width:'15',
                                  height:'15'
                                }}
                              />
                              <label className="labelsidenav">
                                {t("Nav_Menu.FAQ")}
                              </label>
                            </div>
                            <FontAwesomeIcon
                              className="iconfechasidenav"
                              icon={faAngleRight}
                              style={{
                                width:'15',
                                height:'15'
                              }}
                            />
                          </div>
                        </Nav.Item>

                        <Nav.Item
                          eventKey="7"
                          onClick={() => {
                            setVisibleNav(false);
                            navigate("/contacto");
                          }}
                        >
                          <div className="itemsidenav">
                            <div onClick={() => navigate("/contacto")}>
                              <FontAwesomeIcon
                                className="iconsidenav"
                                icon={faContactCard}
                                style={{
                                  width:'15',
                                  height:'15'
                                }}
                              />
                              <label className="labelsidenav">
                                {t("Nav_Menu.Contact")}
                              </label>
                            </div>
                            <FontAwesomeIcon
                              className="iconfechasidenav"
                              icon={faAngleRight}
                              style={{
                                width:'15',
                                height:'15'
                              }}
                            />
                          </div>
                        </Nav.Item>

                        <Nav.Menu
                          eventKey="8"
                          title={
                            <div className="itemsidenav">
                              <div>
                                <FontAwesomeIcon
                                  className="iconsidenav"
                                  icon={faMoneyBill}
                                  style={{
                                    width:'15',
                                    height:'15'
                                  }}
                                />
                                <label className="labelsidenav">
                                  {t("Nav_Menu.Subcriptions")}
                                </label>
                              </div>
                            </div>
                          }
                        >
                          <Nav.Item
                            onClick={() => {
                              setVisibleNav(false);
                              navigate("/planessocios");
                            }}
                          >
                            <div className="itemsidenav">
                              <div onClick={() => navigate("/planessocios")}>
                                <FontAwesomeIcon
                                  className="iconsidenav"
                                  icon={faPersonBreastfeeding}
                                  style={{
                                    width:'15',
                                    height:'15'
                                  }}
                                />
                                <label className="labelsidenav">
                                  {t("Nav_Menu.Suscription_Sporting")}
                                </label>
                              </div>
                              <FontAwesomeIcon
                                className="iconfechasidenav"
                                icon={faAngleRight}
                                style={{
                                  width:'15',
                                  height:'15'
                                }}
                              />
                            </div>
                          </Nav.Item>
                          <Nav.Item
                            onClick={() => {
                              setVisibleNav(false);
                              navigate("/planesclubes");
                            }}
                          >
                            <div className="itemsidenav">
                              <div onClick={() => navigate("/planesclubes")}>
                                <FontAwesomeIcon
                                  className="iconsidenav"
                                  icon={faPersonDotsFromLine}
                                  style={{
                                    width:'15',
                                    height:'15'
                                  }}
                                />
                                <label className="labelsidenav">
                                  {t("Nav_Menu.Suscription_Club")}
                                </label>
                              </div>
                              <FontAwesomeIcon
                                className="iconfechasidenav"
                                icon={faAngleRight}
                              />
                            </div>
                          </Nav.Item>
                        </Nav.Menu>

                        {currentConfig?.live != null ? (
                          <Nav.Item
                            eventKey="1"
                            onClick={() => {
                              setVisibleNav(false);
                              navigate("/v?id=" + currentConfig?.live);
                            }}
                          >
                            <div className="itemsidenav">
                              <div>
                                <FontAwesomeIcon
                                  className="iconsidenav"
                                  icon={faFileVideo}
                                  style={{
                                    width:'15',
                                    height:'15'
                                  }}
                                />
                                <label className="labelsidenav">
                                  {t("Nav_Menu.MyLive")}
                                </label>
                              </div>
                              <FontAwesomeIcon
                                className="iconfechasidenav"
                                icon={faAngleRight}
                                style={{
                                  width:'15',
                                  height:'15'
                                }}
                              />
                            </div>
                          </Nav.Item>
                        ) : (
                          <> </>
                        )}
                      </Nav>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      {currentUser === null ? (
                        <button
                          style={{
                            position: "absolute",
                            bottom: 0,
                            zIndex: 10000,
                            width: "100%",
                            height: "60px" /* Height of the footer */,
                          }}
                          onClick={() => {
                            setVisibleNav(false);
                            navigate("/login");
                          }}
                          className="btn btn-success"
                        >
                          <FontAwesomeIcon
                            className="buttonlikedislikeicon"
                            icon={faUser}
                            style={{
                              width:'15',
                              height:'15'
                            }}
                          />
                          {t("Header.signIn")}
                        </button>
                      ) : (
                        <div>
                          <button
                            style={{
                              position: "absolute",
                              bottom: 0,
                              zIndex: 10000,
                              width: "100%",
                              height: "60px" /* Height of the footer */,
                            }}
                            onClick={async () => {
                              setVisibleNav(false);
                              await logout();
                              logoutUser();
                              localStorage.clear();

                              navigate("/");
                            }}
                            className="btn btn-danger"
                          >
                            <FontAwesomeIcon
                              className="buttonlikedislikeicon"
                              icon={faRightFromBracket}
                              style={{
                                width:'15',
                                height:'15'
                              }}
                            />
                            {t("Header.signOut")}
                          </button>
                        </div>
                      )}
                    </Sidenav.Body>
                  </Sidenav>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </div>
        </Container>
      </Navbar>
    </>
  );
}
