import React, { useState, useLayoutEffect } from "react";
import { Button, Col, Container, Row } from "rsuite";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import { APP_GOOGLE_TAG } from "../env";
import { useTranslation } from "react-i18next";

const Cookies = () => {
  const [display, setDisplay] = useState(true);
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  useLayoutEffect(() => {
    if (localStorage.getItem("acceptCookies")) {
      setDisplay(false);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("acceptCookies", "true");
    setDisplay(false);

    window.dataLayer.push({
      event: "cookies-aceptadas",
    });

    const tagManagerArgs = {
      gtmId: String(APP_GOOGLE_TAG),
    };
    TagManager.initialize(tagManagerArgs);
  };

  return (
    <Container
      className={`absolute z-10 w-full h-full ${display ? "block" : "hidden"}`}
      style={{
        backgroundColor: display ? "rgba(0, 0, 0, 0.9)" : "transparent",
      }}
    >
      <Row className="fixed mx-10 mt-20 bg-white rounded-lg h-1/2 laptop:max-2kScreen:h-2/6 laptop:max-2kScreen:mt-96">
        <Col xs={24} sm={24} md={24} lg={24}>
          <h3 className="mt-3 mb-3 text-3xl font-semibold text-center">
            {t("Cookies.Cookies_title")}
          </h3>
          <p className="mx-3 text-xl font-medium tracking-wide text-left ">
            {t("Cookies.Cookies_info")}
          </p>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          className="absolute bottom-0 flex flex-col justify-end w-full pb-4 h-fit"
        >
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            className="flex flex-row items-center justify-center w-full "
          >
            <div className="mr-10">
              <Button className="w-full px-12 bg-red-600 hover:bg-red-500">
                <p
                  className="font-medium text-center text-white"
                  onClick={() => {
                    handleAcceptCookies();
                  }}
                >
                  {t("Cookies.Cookies_button.accept_button")}
                </p>
              </Button>
            </div>
            <div>
              <Button
                className="ring-2 ring-red-300"
                onClick={() => {
                  setDisplay(false);
                  navigate("/policiesCookies");
                }}
              >
                <p className="font-medium text-center text-red-400">
                  {t("Cookies.Cookies_button.notice_button")}
                </p>
              </Button>
            </div>
          </Col>
          <div></div>
        </Col>
      </Row>
    </Container>
  );
};

export default Cookies;
