import {
  faBusinessTime,
  faDiamond,
  faMailBulk,
  faManatSign,
  faMobilePhone,
  faPhone,
  faShareNodes,
  faStreetView,
  faUpload,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  CampoRequerido,
  CaracteresMaximos,
  CaracteresMinimos,
  Paises,
} from "../utils/utilidades";
import { SelectPicker } from "rsuite";
import { Post } from "../utils/consumir";
import Swal from "sweetalert2";
import { API_URL } from "../env";
import { useTranslation } from "react-i18next";

export default function Contacto() {
  const URL_API = API_URL || "";

  const [t] = useTranslation("global");

  const formik = useFormik({
    initialValues: {
      nombre: "",
      apellido: "",
      correo: "",
      telefono: "",
      nombreclub: "",
      pais: "",
      descripcion: "",
    },
    validationSchema: Yup.object().shape({
      nombre: Yup.string()
        .min(3, CaracteresMinimos(3))
        .max(50, CaracteresMaximos(50))
        .nullable(true)
        .required(CampoRequerido("Nombre")),
      apellido: Yup.string()
        .min(3, CaracteresMinimos(3))
        .max(50, CaracteresMaximos(50))
        .nullable(true)
        .required(CampoRequerido("Apellido")),
      correo: Yup.string()

        .email("Correo no válido")
        .nullable(true)
        .required(CampoRequerido("Correo")),
      telefono: Yup.string()
        .min(9, CaracteresMinimos(9))
        .nullable(true)
        .required(CampoRequerido("Teléfono")),
      nombreclub: Yup.string().nullable(true),
      pais: Yup.string()
        .min(3, CaracteresMinimos(3))
        .max(50, CaracteresMaximos(50))
        .nullable(true)
        .required(CampoRequerido("Nombre del club")),
      descripcion: Yup.string().max(150, CaracteresMaximos(150)).nullable(true),
    }),

    validateOnMount: true,
    onSubmit: (values: any) => {},
  });

  return (
    <>
      <div className="container">
        <div className="mt-3 grid-container">
          <div className="row div-cuadro mt-15">
            <div className="row">
              <div className="col-md-12">
                <h1 className="mt-5 text-center card-title display-5 fw-bold">
                {t("ContactUs.Title")}
                </h1>
                <p className="text-center">
                {t("ContactUs.subTitle")}
                </p>
              </div>
            </div>

            <div className="row justify-content-center align-items-center">
              <div className="text-center col-md-auto">
                <div className="col-md-12">
                  <div className="row">
                    <h4 className="text-center display-4 fw-bold">
                    {t("ContactUs.Title1")}
                    </h4>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label> {t("ContactUs.Form.name")}</label>
                      <input
                        {...formik.getFieldProps("nombre")}
                        onChange={(value) => {
                          formik.setFieldValue("nombre", value.target.value);
                        }}
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                      />
                    </div>
                    <div className="col-md-12">
                      <label>{t("ContactUs.Form.lastName")}</label>
                      <input
                        {...formik.getFieldProps("apellido")}
                        onChange={(value) => {
                          formik.setFieldValue("apellido", value.target.value);
                        }}
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label>{t("ContactUs.Form.email")}</label>
                      <input
                        {...formik.getFieldProps("correo")}
                        onChange={(value) => {
                          formik.setFieldValue("correo", value.target.value);
                        }}
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                      />
                    </div>
                    <div className="col-md-12">
                      <label>{t("ContactUs.Form.phone")}</label>
                      <input
                        {...formik.getFieldProps("telefono")}
                        onChange={(value) => {
                          formik.setFieldValue("telefono", value.target.value);
                        }}
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                      />
                    </div>
                  </div>
                  <div className="mt-2 row">
                    <div className="col-md-12">
                      <label>{t("ContactUs.Form.clubOrBusiness")}</label>
                      <input
                        {...formik.getFieldProps("nombreclub")}
                        onChange={(value) => {
                          formik.setFieldValue(
                            "nombreclub",
                            value.target.value
                          );
                        }}
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="text-center">{t("ContactUs.Form.country")}</label>
                      <SelectPicker
                        {...formik.getFieldProps("pais")}
                        name="pais"
                        placeholder={t("Home.Filter.Selected")}
                        onChange={(value: any) => {
                          formik.setFieldValue("pais", value);
                        }}
                        style={{
                          width: "100%",
                        }}
                        data={Paises.sort((a, b) => {
                          if (a.label < b.label) {
                            return -1;
                          }
                          if (a.label > b.label) {
                            return 1;
                          }
                          return 0;
                        })}
                      />{" "}
                    </div>
                  </div>

                  <div className="mt-2 row">
                    <div className="col-md-12">
                      <label>{t("ContactUs.Form.description")}</label>
                      <textarea
                        {...formik.getFieldProps("descripcion")}
                        onChange={(value) => {
                          formik.setFieldValue(
                            "descripcion",
                            value.target.value
                          );
                        }}
                        className="form-control form-control-lg form-control-solid"
                        maxLength={150}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="mt-3 col">
                      <button
                        type="submit"
                        disabled={JSON.stringify(formik.errors) !== "{}"}
                        onClick={() => {
                          let hola = formik;
                          Post(`${URL_API}/contacto`, formik.values).then(
                            (response: any) => {
                              formik.resetForm();
                              Swal.fire({
                                icon: "success",
                                title: `${t("ContactUs.Form.Modal.title")}`,
                                text: `${t("ContactUs.Form.Modal.description")}`,
                                showConfirmButton: false,
                                timer: 3000,
                              });
                            }
                          );
                        }}
                        style={{
                          fontSize: "25px",
                          marginTop: "10px",
                        }}
                        className="form-control btn btn-danger"
                      >
                        {t("ContactUs.Form.buttonSend")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 row">
              <div className="col-md-12">
                <div className="col-12">
                  <p className="text-center">
                    <span>
                      <FontAwesomeIcon
                        className="icono-encabezado-uno"
                        icon={faStreetView}
                      />
                       {t("ContactUs.businessAddress")}
                    </span>
                  </p>
                  <p className="text-center">
                    <span>
                      <FontAwesomeIcon
                        className="icono-encabezado-uno"
                        icon={faBusinessTime}
                      />
                      {t("ContactUs.contactSupport")}
                    </span>
                  </p>
                  <p className="text-center">
                    <span>
                      <FontAwesomeIcon
                        className="icono-encabezado-uno"
                        icon={faPhone}
                      />
                      Tel: <a href="tel:+56975483648">+56 9 75483648</a>
                    </span>
                  </p>
                  <p className="text-center">
                    <span>
                      <FontAwesomeIcon
                        className="icono-encabezado-uno"
                        icon={faMailBulk}
                      />
                      email:{" "}
                      <a href="mailto:contact@sportpro.tv">
                        contact@sportpro.tv
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
