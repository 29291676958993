import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, CheckPicker, Dropdown, Modal, SelectPicker } from "rsuite";
import { language, Textos } from "../texto";
import ConfigContext from "../utils/Config";
import { getParameter } from "../utils/consumir";
import { useTranslation } from "react-i18next";

export function FilterVideo(props: any) {
  const [open, setOpen] = useState(false);
  const { currentConfig, GetConfig } = useContext(ConfigContext);
  const [data, setData] = useState<any>({});
  const navigate = useNavigate();
  const [t] = useTranslation("global");


  const idioma = {
    placeholder:`${t("Home.Filter.Selected")}`,
    searchPlaceholder: `${t("Home.Filter.Search")}`,
    noResultsText:`${t("Home.Filter.NoResult")}`,
  };
  const typevideo = [
    {
      label: `${t("Home.Filter.Categories.typeVideo.live")}`,
      value: "live",
    },
    {
      label: `${t("Home.Filter.Categories.typeVideo.stream")}`,
      value: "stream",
    },
    {
      label: `${t("Home.Filter.Categories.typeVideo.highlight")}`,
      value: "highlight",
    },
  ];
  
  const sortvideo = [
    {
      label:  `${t("Home.Filter.Categories.Order.views")}`,
      value: "views",
    },
    {
      label:  `${t("Home.Filter.Categories.Order.liked")}`,
      value: "like",
    },
    {
      label:  `${t("Home.Filter.Categories.Order.recently")}`,
      value: "initialtime",
    },
  ];

  useEffect(() => {
    let club_id = getParameter("club") || "";
    let sport_id = getParameter("sport") || "";
    let typev = getParameter("typev") || "";
    let sortingv = getParameter("sort") || "";

    if (club_id !== "") {
      data.sportclubs = club_id.split(",").map((x) => parseInt(x));
    }
    if (sport_id !== "") {
      data.sport = sport_id.split(",").map((x) => parseInt(x));
    }
    if (typev !== "") {
      data.typev = typev.split(",")[0];
    }
    if (sortingv !== "") {
      data.sort = sortingv.split(",")[0];
    }

    setData(data);
  }, [setData, window.location.search]);
  return (
    <>
      <button
        onClick={() => {
          setOpen(!open);
        }}
        className="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder show menu-dropdown"
      >
        <FontAwesomeIcon icon={faFilter} /> {t("Home.Filter.Filter_title")}
      </button>
      <Modal
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
      >
        <Modal.Header>
          <Modal.Title>{t("Home.Filter.Filter_title_inner")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-10">
            <label className="form-label fw-bold">
              {t("Home.Filter.Sporting_Clubs")}:
            </label>
            <div className="d-flex">
              <CheckPicker
                locale={idioma}
                onChange={(value: any) => {
                  setData({ ...data, sportclubs: value });
                }}
                defaultValue={data.sportclubs}
                data={
                  currentConfig?.clubs.map((x: any) => {
                    return {
                      label: x.displayname,
                      value: x.id,
                    };
                  }) || []
                }
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="mb-10">
            <label className="form-label fw-bold">
              {t("Home.Filter.Sports")}:
            </label>
            <div className="d-flex">
              <CheckPicker
                onChange={(value: any) => {
                  setData({ ...data, sport: value });
                }}
                defaultValue={data.sport}
                locale={idioma}
                data={
                  currentConfig?.sports.map((x: any) => {
                    return {
                      label: x.sportname,
                      value: x.id,
                    };
                  }) || []
                }
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="mb-10">
            <label className="form-label fw-bold">
              {t("Home.Filter.Video_Type")}:
            </label>
            <div className="d-flex">
              <SelectPicker
                locale={idioma}
                defaultValue={data.typev}
                searchable={false}
                onChange={(value: any) => {
                  setData({ ...data, typevideo: value });
                }}
                data={typevideo}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="mb-10">
            <label className="form-label fw-bold">
              {t("Home.Filter.OrderBy")}:
            </label>
            <div className="d-flex">
              <SelectPicker
                locale={idioma}
                searchable={false}
                defaultValue={data.sort}
                onChange={(value: any) => {
                  setData({ ...data, sort: value });
                }}
                data={sortvideo}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              let url = "";
              if (data.sportclubs !== undefined) {
                if (data.sportclubs.length !== 0) {
                  url += `&club=${data.sportclubs.join(",")}`;
                }
              }

              if (data.sport !== undefined) {
                if (data.sport.length !== 0) {
                  url += `&sport=${data.sport.join(",")}`;
                }
              }

              if (data.typevideo !== undefined && data.typevideo !== null) {
                if (data.typevideo.length !== 0) {
                  url += `&typev=${data.typevideo}`;
                }
              }

              if (data.sort !== undefined && data.sort !== null) {
                if (data.sort.length !== 0) {
                  url += `&sort=${data.sort}`;
                }
              }

              setData({});
              if (url.length === 0) navigate("/");
              else navigate(`/?${url}`);

              setOpen(!open);
            }}
            appearance="primary"
            className="bg-red-600 hover:bg-red-800"
          >
            {t("Home.Filter.Filter_button.Success")}
          </Button>
          <Button
            appearance="subtle"
            className="text-white bg-black hover:text-red-500"
            onClick={() => {
              setOpen(!open);
            }}
          >
             {t("Home.Filter.Filter_button.Deny")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
