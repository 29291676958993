import { Get } from "../utils/consumir";
import AuthContext from "../utils/Auth";
import ConfigContext from "../utils/Config";
import { API_URL } from "../env";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Container, FlexboxGrid, Row, Table } from "rsuite";
import './clubadmin.css';
import { debug } from "console";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
import { useTranslation } from "react-i18next";

interface resumen {
    private: number;
    public: number;
    views: number;
    likes: number;
    lives: number;
    mes: number;
    mesname: string;
    year: number;
    id: number;
}
interface resumenMes {
    Enero: resumen;
    Febrero: resumen;
    Marzo: resumen;
    Abril: resumen;
    Mayo: resumen;
    Junio: resumen;
    Julio: resumen;
    Agosto: resumen;
    Septiembre: resumen;
    Octubre: resumen;
    Noviembre: resumen;
    Diciembre: resumen;
}


export function ClubAdmin() {
    const URL_API = API_URL || '';
    const navigate = useNavigate();
    const [t] = useTranslation("global")


    const { state } = useLocation();
    const { currentConfig, GetConfig, } = useContext(ConfigContext);
    const { currentUser, } = useContext(AuthContext);
    const [dataClub, setData] = useState<any>();

    const [loading, setLoading] = useState<any>(false);

    const [clubsAdmin, setClubsAdmin] = useState<any>([]);
    const [players, setPlayers] = useState<any>([]);
    const [club, setClub] = useState<number>(0);
    const [cantViews, setcantViews] = useState<number>(0);
    const [cantLikes, setcantLikes] = useState<number>(0);
    const [cantLives, setcantLives] = useState<number>(0);
    const [cantPlayers, setcantPlayers] = useState<number>(0);
    const [cantEvents, setcantEvents] = useState<number>(0);
    const year = new Date().getFullYear();




    const [datosClub, setdatosClub] = useState<any>([]);
    const [datosMeses, setdatosMeses] = useState<any>([]);



    const { Column, HeaderCell, Cell } = Table;

    function cargarClubs() {
        let clubes: any[] = [];
        currentConfig?.clubs.map((x: any) => {
            if (x.id_admin === currentUser?.id_player) {
                clubes.push({
                    name: x.displayname,
                    id: x.id
                });
            }
        })
        return clubes;
    }

    const getData = useCallback(async () => {
        setClubsAdmin(cargarClubs());
    }, [URL_API])

    const processState = useCallback(() => {
        const idplayer = currentUser?.id_player;
        if (idplayer !== undefined) {
            getData();
        } else {
            navigate(-1);
        }

    }, [URL_API, state, getData,])

    async function ObtenerDataClub(club: any) {
        if (club === '0') {
            return;
        }
        //console.log('Previo a obtener data club');
        await Get(`${URL_API}/clubadmin/` + club).then((response) => {
            //console.log('Respuesta de club admin');
            //console.log(response);
            if (response.data.data !== null) {
                setData(response.data.data);
                let viewcount = 0;
                let likescount = 0;
                let livecount = 0;

                setdatosClub(response.data.data);

                response.data.data.lives.map((x: any) => {
                    const actualMonth = new Date().getMonth();
                    const actualYear = new Date().getFullYear();
                    const liveMonth = new Date(x.initialtime).getMonth();
                    const liveYear = new Date(x.initialtime).getFullYear();
                    viewcount = viewcount + x.views;
                    likescount += x.like === undefined ? 0 : x.like;
                    livecount++;
                });

                //agrupar jugadores
                let players: any[] = [];
                response.data.data.players.map((x: any) => {
                    players.push({
                        name: x.displayname,
                        id: x.id
                    });
                })
                //eliminar jugadores duplicados
                players = players.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === thing.id
                    )))

                //contar eventos
                let eventos: any[] = [];
                response.data.data.events.map((x: any) => {
                    eventos.push({
                        name: x.eventname,
                        id: x.id
                    });
                });

                if (eventos.length > 0) {
                    setcantEvents(eventos.length);
                }

                if (players.length > 0) {
                    setcantPlayers(players.length);
                }

                if (viewcount > 0) {
                    setcantViews(viewcount);
                }
                if (likescount > 0) {
                    setcantLikes(likescount);
                }

                setcantLives(livecount);
                const dtmes = ObtenerMesesLives(response.data.data.lives);

                setdatosMeses(dtmes);
                //console.log("🚀 ~ file: clubadmin.tsx ~ line 91 ~ awaitGet ~ datosMeses", datosMeses)
            }

        }).catch((error) => {
            //console.log(error);
        });
        setLoading(false);
    }

    function ObtenerMesesLives(lives: any) {

        let anhios: any = {
            "2021": [],
            "2022": [],
            "2023": [],
            "2024": [],
        };

        //goup lives by year
        anhios["2021"] = lives.filter((x: any) => new Date(x.initialtime).getFullYear() === 2021);
        anhios["2022"] = lives.filter((x: any) => new Date(x.initialtime).getFullYear() === 2022);
        anhios["2023"] = lives.filter((x: any) => new Date(x.initialtime).getFullYear() === 2023);
        anhios["2024"] = lives.filter((x: any) => new Date(x.initialtime).getFullYear() === 2024);

        let resumenYears: any = {
            "2021": [],
            "2022": [],
            "2023": [],
            "2024": [],
        };

        for (let index = 0; index < 4; index++) {
            let yearaux = [];
            let yearnameaux = '';
            switch (index) {
                case 0:
                    yearaux = anhios["2021"];
                    yearnameaux = '2021';
                    break;
                case 1:
                    yearaux = anhios["2022"];
                    yearnameaux = '2022';
                    break;
                case 2:
                    yearaux = anhios["2023"];
                    yearnameaux = '2023';
                    break;
                case 3:
                    yearaux = anhios["2024"];
                    yearnameaux = '2024';
                    break;
                default:
                    break;
            }
            const yearnumber = parseInt(yearnameaux);
            let mesesArray: resumenMes = {
                Enero: { private: 0, public: 0, views: 0, likes: 0, lives: 0, mesname: 'Enero ' + yearnameaux, mes: 1, year: yearnumber, id: 1 },
                Febrero: { private: 0, public: 0, views: 0, likes: 0, lives: 0, mesname: 'Febrero ' + yearnameaux, mes: 2, year: yearnumber, id: 2 },
                Marzo: { private: 0, public: 0, views: 0, likes: 0, lives: 0, mesname: 'Marzo ' + yearnameaux, mes: 3, year: yearnumber, id: 3 },
                Abril: { private: 0, public: 0, views: 0, likes: 0, lives: 0, mesname: 'Abril ' + yearnameaux, mes: 4, year: yearnumber, id: 4 },
                Mayo: { private: 0, public: 0, views: 0, likes: 0, lives: 0, mesname: 'Mayo ' + yearnameaux, mes: 5, year: yearnumber, id: 5 },
                Junio: { private: 0, public: 0, views: 0, likes: 0, lives: 0, mesname: 'Junio ' + yearnameaux, mes: 6, year: yearnumber, id: 6 },
                Julio: { private: 0, public: 0, views: 0, likes: 0, lives: 0, mesname: 'Julio ' + yearnameaux, mes: 7, year: yearnumber, id: 7 },
                Agosto: { private: 0, public: 0, views: 0, likes: 0, lives: 0, mesname: 'Agosto ' + yearnameaux, mes: 8, year: yearnumber, id: 8 },
                Septiembre: { private: 0, public: 0, views: 0, likes: 0, lives: 0, mesname: 'Septiembre ' + yearnameaux, mes: 9, year: yearnumber, id: 9 },
                Octubre: { private: 0, public: 0, views: 0, likes: 0, lives: 0, mesname: 'Octubre ' + yearnameaux, mes: 10, year: yearnumber, id: 10 },
                Noviembre: { private: 0, public: 0, views: 0, likes: 0, lives: 0, mesname: 'Noviembre ' + yearnameaux, mes: 11, year: yearnumber, id: 11 },
                Diciembre: { private: 0, public: 0, views: 0, likes: 0, lives: 0, mesname: 'Diciembre ' + yearnameaux, mes: 12, year: yearnumber, id: 12 },
            };

            for (let i = 0; i < yearaux.length; i++) {
                const element = yearaux[i];
                const meselement = new Date(element.initialtime).getMonth();
                const yearelement = new Date(element.initialtime).getFullYear();

                let nombremes = "";
                switch (meselement) {
                    case 0:
                        nombremes = "Enero";
                        mesesArray.Enero.views += element.views;
                        mesesArray.Enero.likes += element.like;
                        mesesArray.Enero.lives++;
                        if (element.isprivate) {
                            mesesArray.Enero.private++;
                        } else {
                            mesesArray.Enero.public++;
                        }
                        break;
                    case 1:
                        nombremes = "Febrero";
                        mesesArray.Febrero.views += element.views;
                        mesesArray.Febrero.likes += element.like === undefined ? 0 : element.like;
                        mesesArray.Febrero.lives++;
                        if (element.isprivate) {
                            mesesArray.Febrero.private++;
                        } else {
                            mesesArray.Febrero.public++;
                        }
                        break;
                    case 2:
                        nombremes = "Marzo";
                        mesesArray.Marzo.views += element.views;
                        mesesArray.Marzo.likes += element.like === undefined ? 0 : element.like;
                        mesesArray.Marzo.lives++;
                        if (element.isprivate) {
                            mesesArray.Marzo.private++;
                        } else {
                            mesesArray.Marzo.public++;
                        }
                        break;
                    case 3:
                        nombremes = "Abril";
                        mesesArray.Abril.views += element.views;
                        mesesArray.Abril.likes += element.like === undefined ? 0 : element.like;
                        mesesArray.Abril.lives++;
                        if (element.isprivate) {
                            mesesArray.Abril.private++;
                        } else {
                            mesesArray.Abril.public++;
                        }
                        break;
                    case 4:
                        nombremes = "Mayo";
                        mesesArray.Mayo.views += element.views;
                        mesesArray.Mayo.likes += element.like === undefined ? 0 : element.like;
                        mesesArray.Mayo.lives++;
                        if (element.isprivate) {
                            mesesArray.Mayo.private++;
                        } else {
                            mesesArray.Mayo.public++;
                        }
                        break;
                    case 5:
                        nombremes = "Junio";
                        mesesArray.Junio.views += element.views;
                        mesesArray.Junio.likes += element.like === undefined ? 0 : element.like;
                        mesesArray.Junio.lives++;
                        if (element.isprivate) {
                            mesesArray.Junio.private++;
                        } else {
                            mesesArray.Junio.public++;
                        }
                        break;
                    case 6:
                        nombremes = "Julio";
                        mesesArray.Julio.views += element.views;
                        mesesArray.Julio.likes += element.like === undefined ? 0 : element.like;
                        mesesArray.Julio.lives++;
                        if (element.isprivate) {
                            mesesArray.Julio.private++;
                        } else {
                            mesesArray.Julio.public++;
                        }
                        break;
                    case 7:
                        nombremes = "Agosto";
                        mesesArray.Agosto.views += element.views;
                        mesesArray.Agosto.likes += element.like === undefined ? 0 : element.like;
                        mesesArray.Agosto.lives++;
                        if (element.isprivate) {
                            mesesArray.Agosto.private++;
                        } else {
                            mesesArray.Agosto.public++;
                        }
                        break;
                    case 8:
                        nombremes = "Septiembre";
                        mesesArray.Septiembre.views += element.views;
                        mesesArray.Septiembre.likes += element.like === undefined ? 0 : element.like;
                        mesesArray.Septiembre.lives++;
                        if (element.isprivate) {
                            mesesArray.Septiembre.private++;
                        } else {
                            mesesArray.Septiembre.public++;
                        }
                        break;
                    case 9:
                        nombremes = "Octubre";
                        mesesArray.Octubre.views += element.views;
                        mesesArray.Octubre.likes += element.like === undefined ? 0 : element.like;
                        mesesArray.Octubre.lives++;
                        if (element.isprivate) {
                            mesesArray.Octubre.private++;
                        } else {
                            mesesArray.Octubre.public++;
                        }
                        break;
                    case 10:
                        nombremes = "Noviembre";
                        mesesArray.Noviembre.views += element.views;
                        mesesArray.Noviembre.likes += element.like === undefined ? 0 : element.like;
                        mesesArray.Noviembre.lives++;
                        if (element.isprivate) {
                            mesesArray.Noviembre.private++;
                        } else {
                            mesesArray.Noviembre.public++;
                        }
                        break;
                    case 11:
                        nombremes = "Diciembre";
                        mesesArray.Diciembre.views += element.views;
                        mesesArray.Diciembre.likes += element.like === undefined ? 0 : element.like;
                        mesesArray.Diciembre.lives++;
                        if (element.isprivate) {
                            mesesArray.Diciembre.private++;
                        } else {
                            mesesArray.Diciembre.public++;
                        }
                        break;
                    default:
                        break;
                }
            }

            switch (index) {
                case 0:
                    resumenYears["2021"] = mesesArray;
                    break;
                case 1:
                    resumenYears["2022"] = mesesArray;
                    break;
                case 2:
                    resumenYears["2023"] = mesesArray;
                    break;
                case 3:
                    resumenYears["2024"] = mesesArray;
                    break;
                default:
                    break;
            }
        }

        let retorno = [
            {
                ...resumenYears[`${year}`].Enero,
            },
            {
                ...resumenYears[`${year}`].Febrero,
            },
            {
                ...resumenYears[`${year}`].Marzo,
            },
            {
                ...resumenYears[`${year}`].Abril,
            },
            {
                ...resumenYears[`${year}`].Mayo,
            },
            {
                ...resumenYears[`${year}`].Junio,
            },
            {
                ...resumenYears[`${year}`].Julio,
            },
            {
                ...resumenYears[`${year}`].Agosto,
            },
            {
                ...resumenYears[`${year}`].Septiembre,
            },
            {
                ...resumenYears[`${year}`].Octubre,
            },
            {
                ...resumenYears[`${year}`].Noviembre,
            },
            {
                ...resumenYears[`${year}`].Diciembre,
            },
        ];
        return retorno;
    }

    function TipoMembership(id: number) {
        switch (id) {
            case 1:
                return "Free";
            case 2:
                return "Premium";
            case 3:
                return "Pro";
            default:
                return "NaN";
        }
    }

    function formatDate(input: string) {
        const date = new Date(input);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let hour = date.getHours();
        const minutes = date.getMinutes();
        let seconds = date.getSeconds();

        let d = day.toString();
        if (day < 10) {
            d = "0" + day.toString();
        }

        let m = month.toString();
        if (month < 10) {
            m = "0" + month.toString();
        }

        let hr = hour.toString();
        if (hour < 10) {
            hr = '0' + hour;
        }
        let min = minutes.toString();
        if (minutes < 10) {
            min = "0" + minutes;
        }
        return `${d}/${m}/${year} ${hr}:${min}`;
    }

    function ExportToExcel(data: any[], nameFile: string) {
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, nameFile);
        XLSX.writeFile(wb, nameFile + ".xlsx");
    }
    

    const exportExcel = (data: any) => {
        ExportToExcel(data.map((x: any) => {
            return {
                "Id Jugador": x.id,
                "Nombre": x.firstname,
                "Apellido": x.lastname,
                "Nickname": x.nickname,
                "Correo": x.email,
                "Tipo Subscripción": TipoMembership(x.id_membership),
            }
        }), "Jugadores");
    }



    useEffect(() => {
        processState();
    }, [processState])

    return (
        <>
            <div>
                <div>
                    <h1 className="htitle">{t("ClubAdmin.AdminClub")}</h1>
                </div>

            </div>


            <br />

            <Container>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <div
                        style={{
                            width: '300px'
                        }}
                    >
                        <select className="form-select"
                            onChange={(e) => {
                                setLoading(true);
                                setData(undefined);
                                ObtenerDataClub(e.target.value);
                                setClub(parseInt(e.target.value));

                            }}
                        >
                            <option value="0" selected disabled>{t("ClubAdmin.SelectClub")}</option>
                            {clubsAdmin.length > 0 ?
                                clubsAdmin.map((x: any) => {
                                    return (
                                        <Fragment key={x.id}>
                                            <option value={x.id}>
                                                {x.name}
                                            </option>
                                        </Fragment>
                                    );
                                }) : <></>
                            }
                        </select>

                    </div>

                </div>
            </Container>
            <br />

            {
                loading ?
                    <div className="cssload-loader" >
                        <div className="cssload-inner cssload-one"></div>
                        <div className="cssload-inner cssload-two"></div>
                        <div className="cssload-inner cssload-three"></div>
                    </div>
                    :
                    <></>
            }


            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {dataClub ?

                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                className="btn btn-primary"
                                onClick={() => {
                                    navigate(`/eventos?club=${club}`);
                                }}
                            >
                                {t("ClubAdmin.EventClub")}
                            </Button>
                        </div>
                        <br />
                        <h1 className="htitle">{t("ClubAdmin.StadisticClub")}</h1>
                        <br />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <button
                                onClick={() => {
                                    //console.log('Descargar Tabla Excel');
                                    ExportToExcel(datosMeses, 'EstadisticasClubActualYear');
                                }}
                                className='btn btn-primary'>
                                <FontAwesomeIcon style={{ marginRight: "10px", fontSize: "20px" }} icon={faFileDownload} />
                                {t("ClubAdmin.DownloadEstadistic")}
                            </button>
                        </div>
                        <br />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Card className="cardCounts" >
                                <Card.Body>
                                    <Card.Title>{t("ClubAdmin.total")}</Card.Title>
                                    <p> {t("ClubAdmin.streams")} {cantLives}</p>

                                    <div className="cardtext">
                                        <div><p> {t("ClubAdmin.views")}  {cantViews}</p></div>
                                        <div><p> {t("ClubAdmin.likes")} {cantLikes}</p></div>
                                    </div>

                                    <div className="cardtext">
                                        <div>
                                            <p> {t("ClubAdmin.player")} {cantPlayers}</p>
                                        </div>
                                        <div>
                                            <p> {t("ClubAdmin.event")} {cantEvents}</p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>



                        <br />



                        <div

                        >
                            <h5 className="htitle">{t("ClubAdmin.dataYear")}</h5>
                            <div className="show-grid">
                                <FlexboxGrid align="middle" justify="center">
                                    {
                                        datosMeses.length > 0 ?
                                            datosMeses.slice(0).reverse().map((x: any, index: any) => {
                                                if (x.lives === 0) {
                                                    return (<></>);
                                                }

                                                return (
                                                    <FlexboxGrid.Item key={index} as={Col}>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <div style={{ marginBottom: '10px' }}>
                                                                <Fragment key={x.id}>
                                                                    <Card className="cardCounts" >
                                                                        <Card.Body>
                                                                            <Card.Title>{x.mesname}</Card.Title>
                                                                            <p> {t("ClubAdmin.streams")} {x.lives}</p>

                                                                            <div className="cardtext">
                                                                                <div><p> {t("ClubAdmin.views")} {x.views}</p></div>
                                                                                <div><p> {t("ClubAdmin.likes")} {x.likes}</p></div>
                                                                            </div>

                                                                            <div className="cardtext">
                                                                                <div>
                                                                                    <p> {t("ClubAdmin.public")} {x.public}</p>
                                                                                </div>
                                                                                <div>
                                                                                    <p> {t("ClubAdmin.private")} {x.private}</p>
                                                                                </div>
                                                                            </div>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    navigate(`/?club=${club}&month=${x.mes}&year=${year}`);
                                                                                }}
                                                                            >{t("ClubAdmin.viewStr")}</Button>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Fragment>
                                                            </div>
                                                        </div>
                                                    </FlexboxGrid.Item>

                                                );
                                            }) : <>
                                                <div>
                                                {t("ClubAdmin.noData")}
                                                </div>
                                            </>
                                    }
                                </FlexboxGrid>
                            </div>
                        </div>
                    </div>
                    : <></>
                }
            </div>

            {/* tabla players */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >

                <div

                >
                    {
                        dataClub !== undefined ?
                            dataClub.players !== undefined ?
                                <div>
                                    <br />
                                    <h2 className="htitle">
                                    {t("ClubAdmin.Sportmen")}
                                    </h2>

                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <button
                                            onClick={() => {
                                                //console.log('Descargar Tabla Excel');
                                                exportExcel(dataClub.players);
                                            }}
                                            className='btn btn-primary'>
                                            <FontAwesomeIcon style={{ marginRight: "10px", fontSize: "20px" }} icon={faFileDownload} />
                                            {t("ClubAdmin.downExcel")}
                                        </button>
                                    </div>

                                    {
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >


                                            <div>
                                                <table className="table tablausuarios table-striped table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>{t("ClubAdmin.excel.NickName")}</th>
                                                            <th>{t("ClubAdmin.membership")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            dataClub.players.map((x: any, index: any) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <a className="cursortrue"
                                                                                onClick={
                                                                                    () => {
                                                                                        navigate(`/perfil/p?id=${x.id}`);
                                                                                    }
                                                                                }>
                                                                                {x.nickname}
                                                                            </a>
                                                                        </td>
                                                                        <td>{TipoMembership(x.id_membership)}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                </div>
                                : <></>
                            : <></>
                    }
                </div>

            </div>

            {/* tabla lives */}
            {/* <div 
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >

            <div>
            { dataClub !== undefined ?
            dataClub.lives !== undefined ?
                
            <div>
                <h2 className="htitle">
                    Lives
                </h2>
                {
                    <div 
                    style={{
                        width:'85vw',
                        overflow: 'scroll'
                    }}
                    >
                    <table className="table tablaLives table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Player</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Views</th>
                                <th scope="col">Likes</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataClub.lives.map((x: any, index:any) => {
                                    return(
                                        <tr key={index}>
                                            <td>{x.id_player}</td>
                                            <td>{x.description}</td>
                                            <td>{x.views}</td>
                                            <td>{x.likes}</td>
                                            <td>{formatDate(x.initialtime)}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    </div>
                }
            </div>
            :<></> 
            :<></>
            }
            </div>
            
            </div> */}

        </>
    );
}