import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./bundle.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
import global_pr from "./translations/pr/global.json";


const customDectector = {
  name:'customDectector',
  lookup(options){
    const preferredLanguage = window.navigator.language.split('-')[0];
    return preferredLanguage;
  },
  cacheUserLangague(language){
    localStorage.setItem('language', language);
  }
};


const options = {
  order: ['customDectector','localStorage'],
  lookupLocalStorage: 'language',
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'],
};

i18next.use(LanguageDetector).init({
  detection: options,
  interpolation: {escapeValue: false},
  fallbackLng: "es",
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
    pr: {
      global: global_pr,
    },
  },
})


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
