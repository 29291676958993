import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  Button,
  DatePicker,
  DateRangePicker,
  Input,
  Loader,
  Message,
  SelectPicker,
  Uploader,
  useToaster,
} from "rsuite";
import { EventCard } from "../components/eventcard";
import { API_URL, API_URL_LOCAL_DEV ,API_URL_DEV} from "../env";
import { Get, Post, getParameter } from "../utils/consumir";
import { language, Textos } from "../texto";
import ConfigContext from "../utils/Config";
import Swal from "sweetalert2";
import AuthContext from "../utils/Auth";
import { createEvent, getAllEvents } from "../utils/Api/eventsApi";
import { useTranslation } from "react-i18next";
import { Modal as ModalAntd, Input as InputAntd, message, Upload , Button as ButtonAntd } from "antd";
import type { UploadProps } from "antd";
import Lottie from "lottie-react";
import uploadAnim from '../animations/upload.json';
import moment from 'moment';
import ModalComponent from "../components/ModalComponent";

type evento = {
  id: string;
  eventname: string;
  eventdate: string;
  id_club: number;
  eventenddate: string;
  eventendtime: string;
  eventinitdate: string;
  eventinitime: string;
  urlimagen: string;
};




export function Eventos() {
  let club_id = getParameter("club") || "";

  const ideve = window.location.search;
  const URL_API = API_URL || "";
  const [searchParams] = useSearchParams();
  const [fileInfo, setFileInfo] = useState(null);
  const location = useLocation();
  const [t] = useTranslation("global");
  const { currentConfig, GetConfig } = useContext(ConfigContext);
  const [uploading, setUploading] = useState(false);
  const { Dragger } = Upload;

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const { currentUser } = useContext(AuthContext);

  const idioma = {
    placeholder: `${t("Home.Filter.Selected")}`,
    searchPlaceholder: `${t("Home.Filter.Search")}`,
    noResultsText: `${t("Home.Filter.NoResult")}`,
  };

  const [event, setEvent] = useState<evento>({
    id: "",
    eventname: "",
    eventdate: "",
    id_club: 0,
    eventenddate: "",
    eventendtime: "",
    eventinitdate: "",
    eventinitime: "",
    urlimagen: "",
  });
  const [loading, setLoading] = useState(false);
  const [crearEvento, setCrearEvento] = useState(false);
  const [ClubList, setClubList] = useState<any[]>([]);
  const [captureImgEvent, setCaptureImgEvent] = useState('');
  const toaster = useToaster();

  useEffect(() => {
    getEvents();
  }, [API_URL]);

  useEffect(() => {
    getDatas(0);
  }, [window.location.search]);

  let dateEvent: Date = new Date();


  const props = {
    name: 'file',
    multiple: false,
    action: `${API_URL}/upload`,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log('información de la imagen ', info.file);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        setCaptureImgEvent(info.file.response.url)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      message.success(`${e.dataTransfer.files} drop file uploaded successfully.`);
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  function clearEvent() {
    setEvent({
      id: "",
      eventname: "",
      eventdate: "",
      id_club: 0,
      eventenddate: "",
      eventendtime: "",
      eventinitdate: "",
      eventinitime: "",
      urlimagen: "",
    });
  }
  
  const getEvents = () => {
    let id: string = "";
    
    if (club_id === undefined || club_id === null || club_id === "") {
      id = "0";
    } else {
      id = club_id;
    }
    
    // no tiene sentido el club_id siempre llegara vacio
    const data = {
      club_id: "0",
      count: page,
    };
    
    getAllEvents(data).then((resp: any) => {
      if (resp.status === 200) {
        setLoading(false);
        const data = resp.data;
        setData(data.data);
      }
      console.log(resp);
    });
  };
  
  const getDatas = (count: number) => {
    setLoading(true);
    
    let id = "0";
    
    Get(`${URL_API}/event/all/${count}/${id}`).then(
      (res) => {
        let datos = data;
        datos = datos.concat(res.data.data);
        setLoading(false);
        setData(datos);
      },
      (err) => {
        //console.log(err);
      }
      );
    };
    function addZeros(num: number) {
      return String(num).padStart(2, "0");
    }
    
    function formatDate(dateinput: string) {
      let date = new Date(dateinput + " UTC");
      let day = addZeros(date.getDate());
      let month = addZeros(date.getMonth());
      let year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }

  function formatDatetime(dateinput: string) {
    let date = new Date(dateinput + "UTC");
    let day = addZeros(date.getDate());
    let month = addZeros(date.getMonth());
    let year = date.getFullYear();
    let hour = addZeros(date.getHours() + 1);
    let minute = addZeros(date.getMinutes());
    let second = addZeros(date.getSeconds());
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }

  const createEvents = (values:any) => {
    createEvent(values).then((resp:any) =>{
      //console.log("que responde createEvent?",resp);
      if(resp){
            window.location.reload();
            clearEvent();
      }
    }).catch((error:any) =>{
      console.error(" Hubo un error al crear el evento", error.message.toString());
    })
  }

  const crearEventoAPI = () => {
    let emptydata: boolean = false;
    if (event.eventname === "") emptydata = true;
    if (event.eventenddate === "") emptydata = true;
    if (event.eventendtime === "") emptydata = true;
    if (event.eventinitdate === "") emptydata = true;
    if (event.eventinitime === "") emptydata = true;

    if (emptydata) {
      clearEvent();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:`${t("Events.requiredFields")}`,
      });
      return;
    }

    event.eventdate = event.eventdate.includes("GMT-") ? moment(event.eventdate).format('YYYY-MM-DD') :
    event.eventdate;
    event.eventenddate = (event.eventenddate.includes("z") || event.eventenddate.includes("Z")) ? moment(event.eventenddate).format('YYYY-MM-DD'):
    event.eventenddate;
    event.eventinitdate = (event.eventinitdate.includes("z") || event.eventinitdate.includes("Z")) ? moment(event.eventinitdate).format('YYYY-MM-DD'):
    event.eventinitdate;
    event.eventinitime = (event.eventinitime.includes("z") || event.eventinitime.includes("Z")) ? moment(event.eventinitime).format('HH:mm:ss'):
    event.eventinitime;
    event.eventendtime = (event.eventendtime.includes("z") || event.eventendtime.includes("Z")) ? moment(event.eventendtime).format('HH:mm:ss'):
    event.eventendtime;

    //Extraemos las horas y minutos para el mensaje
    let horaDesde = moment(event.eventinitime, "HH:mm:ss").format("HH:mm");
    let horaHasta = moment(event.eventendtime, "HH:mm:ss").format("HH:mm");



    // if (event.eventdate.includes("GMT-")) {
    //   event.eventdate = formatDate(event.eventdate);
    // }
    // if (event.eventenddate.includes("z") || event.eventenddate.includes("Z")) {
    //   event.eventenddate = formatDate(event.eventenddate);
    // }
    // if (
    //   event.eventinitdate.includes("z") ||
    //   event.eventinitdate.includes("Z")
    // ) {
    //   event.eventinitdate = formatDate(event.eventinitdate);
    // }
    // if (event.eventinitime.includes("z") || event.eventinitime.includes("Z")) {
    //   event.eventinitime = formatDatetime(event.eventinitime);
    // }
    // if (event.eventendtime.includes("z") || event.eventendtime.includes("Z")) {
    //   event.eventendtime = formatDatetime(event.eventendtime);
    // }

    // let horaDesde =
    //   event.eventinitime.split(":")[0] + ":" + event.eventinitime.split(":")[1];
    // let horaHasta =
    //   event.eventendtime.split(":")[0] + ":" + event.eventendtime.split(":")[1];

    //console.log(`El evento inicia el: ${event.eventdate} a las ${horaDesde} hasta ${event.eventenddate} a las ${horaHasta}`,)
    Swal.fire({
      title: `${t("Events.Modals.title1")}`,
      text: `${t('Events.Modals.description2')} ${event.eventdate} ${t("Events.Modals.time")} ${horaDesde} ${t("Events.Modals.to")} ${event.eventenddate} ${t("Events.Modals.time")} ${horaHasta}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Events.Buttons.create")}`,
      cancelButtonText: `${t("Events.Buttons.cancel")}`,
      confirmButtonColor: "#060707",
      cancelButtonColor: "#f44336",
    }).then((result) => {
      if (result.isConfirmed) {
        const values ={
          name: event.eventname,
          date: event.eventdate,
          idclub: event.id_club,
          enddate: event.eventenddate,
          endtime: event.eventendtime,
          inittime: event.eventinitime,
          initdate: event.eventinitdate,
          urlimagen: captureImgEvent,
        }
        createEvents(values);
      }
    });
  };
  function cargarClubs() {
    let clubes: any[] = [];

   
    currentConfig?.clubs.map((x: any) => {
      if (x.id_admin === currentUser?.id_player) {
        clubes.push({
          label: x.displayname,
          value: x.id,
        });
      }
    });
    setClubList(clubes);
  }

  return (
    <>
      <div className="flex items-center justify-center my-4">
        <h1 className="text-xl font-semibold text-center text-gray-800">{t("Events.title")}</h1>
      </div>

      {currentUser ? (
        currentUser.id_membership === 0 || currentUser.id_membership === 3 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              className="text-base font-medium text-center text-white bg-black btn hover:text-black hover:bg-red-500 hover:ring-black hover:ring-2 "
              onClick={() => {
                cargarClubs();
                setCrearEvento(true);
              }}
            >
              <FontAwesomeIcon className="eventicon" icon={faAdd} />
              {t("Events.createEvent")}
            </Button>
          </div>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      <div>
        <InfiniteScroll
          dataLength={data.length}
          next={() => {
            let count = page + 1;
            setPage(count);
            getDatas(count);
          }}
          hasMore={true}
          loader={
            loading ? (
              <div className="cssload-loader">
                <div className="cssload-inner cssload-one"></div>
                <div className="cssload-inner cssload-two"></div>
                <div className="cssload-inner cssload-three"></div>
              </div>
            ) : (
              <></>
            )
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>{t("Events.allView")}</b>
            </p>
          }
          refreshFunction={() => {
            getDatas(0);
          }}
          pullDownToRefresh
          pullDownToRefreshThreshold={8}
        >
          <div className="bodypage">
            {loading ? (
              <></>
            ) : data.length > 0 ? (
              <></>
            ) : (
              <h4>{t("Events.notEvent")}</h4>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              {data.length &&
                data
                  .filter((item, index, self) => {
                    return (
                      index ===
                      self.findIndex((element) => element.id === item.id)
                    );
                  })
                  .map((item) => {
                    return (
                      <EventCard
                        key={item.id}
                        id={item.id}
                        club={item.club}
                        dateevent={item.dateevent}
                        streams={item.streams.count}
                        content={item.content}
                        urlimagen={item.urlimagen}
                      />
                    );
                  })}
            </div>
          </div>
        </InfiniteScroll>
      </div>
      <ModalComponent
      open={crearEvento}
      onClose={() => setCrearEvento(false)}
      >
       <div className="flex items-center justify-start w-full mt-3 text-center">
          <InputAntd
            showCount
            maxLength={50}
            onChange={(value: any) => {
              setEvent({
                ...event,
                eventname: value.target.value,
              });
            }}
            placeholder={t("Events.nameEvent")}
          />
        </div>
        {event.eventname === "" && (
          <div>
          <p style={{ color: "red" }} className="mt-2">
            * {t("Events.fieldRequired")}
          </p>
          </div>
        )}

        <div className="flex items-center justify-start w-full mt-3 text-center">
          <div className="flex flex-col items-center justify-start w-1/2">
          <div>
            <p className="pt-2 text-base font-medium text-center from-neutral-700">
              {t("Events.startEvent")}
            </p>
          </div>
            <DatePicker
              format="yyyy-MM-dd HH:mm"
              locale={{
                sunday: `${t("Events.Days.do")}`,
                monday: `${t("Events.Days.lu")}`,
                tuesday: `${t("Events.Days.ma")}`,
                wednesday: `${t("Events.Days.mi")}`,
                thursday: `${t("Events.Days.ju")}`,
                friday: `${t("Events.Days.vi")}`,
                saturday: `${t("Events.Days.sa")}`,
                ok: "OK",
                today: `${t("Events.Days.today")}`,
                yesterday: `${t("Events.Days.yest")}`,
                hours: `${t("Events.Days.hours")}`,
                minutes: `${t("Events.Days.min")}`,
                seconds: `${t("Events.Days.sec")}`,
              }}
              onChange={(value) => {
                let date:any;
                if(value === null) {
                  date = value;
                }
               const start =  moment(date);

                //Formateamos la fecha y hora usando moment
                const dateString = start.format("YYYY-MM-DD");
                const timeString = start.format("HH:mm:ss");

                //Aseguramos que si la hora es 00:00:00,establezca al menos un segundo:
                const eventTime = start.hour() === 0 && start.minute() === 0 && start.second() === 0?
                start.second(1).format("HH:mm:ss") : timeString;

                //Actualizamos el evento con valores de fecha y hora formateado:
                event.eventinitdate = dateString;
                event.eventdate = dateString;
                event.eventinitdate = eventTime;
                setEvent({
                  ...event,
                  eventinitdate: dateString,
                  eventdate: dateString,
                  eventinitime:eventTime,
                });

                // if (value === null) value = new Date();
                // const start = value;
                // let day = start!.getDate();
                // let month = start!.getMonth() + 1;
                // let year = start!.getFullYear();
                // let datestring =
                //   year + "-" + addZeros(month) + "-" + addZeros(day);
                // let hour = start!.getHours();
                // let minute = start!.getMinutes();
                // let second = start!.getSeconds();
                // if (hour === 0 && minute === 0 && second === 0) second = 1;
                // let timestring =
                //   addZeros(hour) +
                //   ":" +
                //   addZeros(minute) +
                //   ":" +
                //   addZeros(second);
                // event.eventinitdate = datestring;
                // event.eventdate = datestring;
                // event.eventinitime = timestring;
                // setEvent({
                //   ...event,
                //   eventinitdate: datestring,
                //   eventdate: datestring,
                //   eventinitime: timestring,
                // });
              }}
            />
          </div>
          <div className="flex flex-col items-center justify-start w-1/2 ml-10">
            <div>
            <p className="pt-2 text-base font-medium text-center from-neutral-700">
              {t("Events.endEvent")}
            </p>
            </div>
            <DatePicker
              format="yyyy-MM-dd HH:mm"
              locale={{
                sunday: `${t("Events.Days.do")}`,
                monday: `${t("Events.Days.lu")}`,
                tuesday: `${t("Events.Days.ma")}`,
                wednesday: `${t("Events.Days.mi")}`,
                thursday: `${t("Events.Days.ju")}`,
                friday: `${t("Events.Days.vi")}`,
                saturday: `${t("Events.Days.sa")}`,
                ok: "OK",
                today: `${t("Events.Days.today")}`,
                yesterday: `${t("Events.Days.yest")}`,
                hours: `${t("Events.Days.hours")}`,
                minutes: `${t("Events.Days.min")}`,
                seconds: `${t("Events.Days.sec")}`,
              }}
              onChange={(value) => {
                //Si value es null, se usa la fecha y hora actual:
                const momentDate = value ? moment(value): moment();

                //Formatea la fecha y hora como string
                const dateEndString = momentDate.format('YYYY-MM-DD');
                const timeString = momentDate.format('HH:mm:ss');

                //Si la hora, minuto y segundo son 0 , se establece el segundo a 1:
                if(momentDate.hour() === 0 && momentDate.minute() === 0 && momentDate.second() === 0){
                  momentDate.second(1);
                }
                //Asegurarnos que el evento la fecha inicial y la fecha final no sean iguales:
                console.log('eventos?:',{
                  fechaInicial: event.eventinitdate,
                  fechaFinal:dateEndString,
                });
                if(event.eventinitdate === dateEndString){
                  Swal.fire('Ups , hubo un problema',`La fecha inicial: ${event.eventinitdate}  y la fecha final: ${dateEndString}  no pueden ser iguales!`,'error');
                  setEvent({
                    ...event,
                    eventenddate:'',
                    eventendtime:'',
                  });
                }else{
                  //Actualizamos el estado con las nuevas fechas formateadas
                  setEvent({
                    ...event,
                    eventenddate: dateEndString,
                    eventendtime: momentDate.format('HH:mm:ss'),
  
                  })

                }
              }}
            />
          </div>
        </div>
        {event.eventinitdate === "" && (
          <div>
          <p style={{ color: "red" }} className="mt-2">
            * {t("Events.fieldRequired")}
          </p>
          </div>
        )}
        <div className="flex flex-col items-center justify-start w-full mt-3 text-center">
          <div>
          <p className="pt-2 text-base font-medium text-center from-neutral-700">
            {t("Events.club")}
          </p>
          </div>
          <SelectPicker
            locale={idioma}
            searchable={false}
            data={ClubList}
            style={{ width: "100%" }}
            onChange={(value: any) => {
              if (value === null) value = 0;
              setEvent({
                ...event,
                id_club: parseInt(value),
              });
            }}
          />
        </div>
        {event.id_club === 0 && (
          <div>
          <p style={{ color: "red" }} className="mt-2">
            * {t("Events.fieldRequired")}
          </p>
          </div>
        )}
        <div className="flex flex-col items-center justify-start w-full mt-3 text-center">
          <div>
        <p className="pt-2 mb-3 text-base font-medium text-center from-neutral-700">
            {t("Events.image")}
          </p>
          </div>
        <Dragger {...props}>
    <div className="flex items-center justify-center w-full ant-upload-drag-icon">
    <Lottie
            animationData={uploadAnim}
            style={{
              width: 120,
              height: 120,
            }}
          />
    </div>
    
    <p className="mx-20 text-base font-medium text-center text-neutral-600">{t("Events.clickorDrag")}</p>
    <p className="font-thin text-center text-red-600">
     {t("Events.clickorDrag_description")}
    </p>
  </Dragger>
  <div className="flex flex-row items-center justify-between w-full px-20 mt-5">
  <ButtonAntd key="back" onClick={() =>{
            setCrearEvento(false);
          }} className="bg-red-500 rounded-xxl hover:bg-red-700">
            <p className="text-base font-medium text-center text-white hover:text-gray-500"> {t("Events.Buttons.cancel")}</p>

          </ButtonAntd>
          <ButtonAntd key='submit' loading={loading} onClick={() =>{
            crearEventoAPI()
          }} className="bg-black rounded-xxl hover:bg-slate-300">
            <p className="text-base font-medium text-center text-white hover:text-black">  {t("Events.Buttons.create")}</p>
          </ButtonAntd>
  </div>
        </div>
      </ModalComponent>
     
    </>
  );
}
