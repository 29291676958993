export function ProcesarNumerosGrandes(numero: number) {
  if (numero > 999) {
    return (numero / 1000).toFixed(0) + "K";
  }
  if (numero > 9999) {
    return (numero / 1000000).toFixed(0) + "M";
  }
  if (numero > 99999) {
    return (numero / 1000000000).toFixed(0) + "B";
  }
  if (numero > 9999999) {
    return (numero / 1000000000000).toFixed(0) + "T";
  }
  return numero.toString();
}

// date inicio, date fin, return HH:mm:ss
export function ProcesarDuracion(dateInicio: Date, dateFin: Date) {
  let diferencia = dateFin.getTime() - dateInicio.getTime();
  let segundos = Math.floor(diferencia / 1000);
  let minutos = Math.floor(segundos / 60);
  let horas = Math.floor(minutos / 60);
  let dias = Math.floor(horas / 24);
  let meses = Math.floor(dias / 30);
  let años = Math.floor(meses / 12);
  let horasRestantes = horas % 24;
  let minutosRestantes = minutos % 60;
  let segundosRestantes = segundos % 60;
  let diasRestantes = dias % 30;
  let mesesRestantes = meses % 12;
  let añosRestantes = años;
  return `${horasRestantes}h ${minutosRestantes}m ${segundosRestantes}s`;
}

export function CaracteresMinimos(cantidad: number) {
  return "Debe contener almenos " + cantidad + " caracteres";
}
export function CaracteresMaximos(cantidad: number) {
  return "Debe contener menos de " + cantidad + " caracteres";
}

export function CampoRequerido(nombrecampo: string) {
  return nombrecampo + " es requerido";
}

export const Paises = [
  { label: "🇦🇷 Argentina", value: "Argentina" },
  { label: "🇦🇺 Australia", value: "Australia" },
  { label: "🇧🇷 Brasil", value: "Brasil" },
  { label: "🇨🇦 Canada", value: "Canada" },
  { label: "🇨🇱 Chile", value: "Chile" },
  { label: "🇨🇴 Colombia", value: "Colombia" },
  { label: "🇪🇸 España", value: "España" },
  { label: "🇺🇸 Estados Unidos", value: "Estados Unidos" },
  { label: "🇬🇧 Gran Bretaña", value: "Gran Bretaña" },
  { label: "🇮🇹 Italia", value: "Italia" },
  { label: "🇲🇽 Mexico", value: "Mexico" },
  { label: "🇵🇪 Peru", value: "Peru" },
  { label: "🇵🇾 Paraguay", value: "Paraguay" },
  { label: "🇻🇪 Venezuela", value: "Venezuela" },
  { label: "🇺🇾 Uruguay", value: "Uruguay" },
  { label: "🇪🇨 Ecuador", value: "Ecuador" },
  { label: "🇵🇹 Portugal", value: "Portugal" },
  { label: "🇳🇱 Holanda", value: "Holanda" },
  { label: "🇩🇪 Alemania", value: "Alemania" },
  { label: "🇫🇷 Francia", value: "Francia" },
  { label: "🇮🇳 India", value: "India" },
  { label: "🇯🇵 Japon", value: "Japon" },
  { label: "🇰🇷 Corea del Sur", value: "Corea del Sur" },
  { label: "🇹🇼 Taiwan", value: "Taiwan" },
  { label: "🇹🇭 Tailandia", value: "Tailandia" },
  { label: "🇨🇳 China", value: "China" },
  { label: "🇷🇺 Rusia", value: "Rusia" },
  { label: "🇸🇪 Suecia", value: "Suecia" },
  { label: "🇳🇴 Noruega", value: "Noruega" },
  { label: "🇩🇰 Dinamarca", value: "Dinamarca" },
  { label: "🇮🇸 Islandia", value: "Islandia" },
  { label: "🇦🇹 Austria", value: "Austria" },
  { label: "🇧🇪 Belgica", value: "Belgica" },
  { label: "🇨🇿 Republica Checa", value: "Republica Checa" },
  { label: "🇭🇺 Hungría", value: "Hungría" },
  { label: "🇮🇪 Irlanda", value: "Irlanda" },
  { label: "🇱🇻 Letonia", value: "Letonia" },
  { label: "🇱🇹 Lituania", value: "Lituania" },
  { label: "🇲🇹 Malta", value: "Malta" },
  { label: "🇵🇱 Polonia", value: "Polonia" },
  { label: "🇷🇴 Rumania", value: "Rumania" },
];
export const getFlag = (country: string) => {
  const type = new Map([
    ["CL", "🇨🇱"],
    ["USA", "🇺🇸"],
    ["BR", "🇧🇷"],
    ["FR", "🇫🇷"],
    ["MX", "🇲🇽"],
    ["EC", "🇪🇨"],
    ["ARG", "🇦🇷"],
    ["UR", "🇺🇾"],
    ["CA", "🇨🇦"],
    ["PY", "🇵🇾"],
    ["PE", "🇵🇪"],
    ["ES", "🇪🇸"],
    ["EN", "🇬🇧"],
  ]);

  return type.get(country.toLowerCase().toUpperCase());
};

//filtro banner TOPHOME
export const filterAdsImgHome = (data: Array<any>, currentLanguage: string) => {
  let sponsorWithImages = data.filter(
    (item) =>
      item.image_carruseltop_path !== null &&
      item.countDownImages !== "" &&
      item.buycarrusel_top === true &&
      item.status === "ACTIVE" &&
      item.isactive === true &&
      item.language === currentLanguage
  );
  if (sponsorWithImages.length === 0) {
    sponsorWithImages = data.filter(
      (item) =>
        item.image_carruseltop_path !== null &&
        item.countDownImages !== "" &&
        item.buycarrusel_top === true &&
        item.status === "ACTIVE" &&
        item.isactive === true &&
        item.language === "en"
    );
  }
  // console.log('Que trae esté elemento?',sponsorWithImages);
  const imagesTop = sponsorWithImages.map((item: any, index: number) => {
    // console.log("Que trae item?",item)
    const name = item.sponsor_name || "";
    const url = item.sponsor_url || "";
    const timeShow = item.countDownImage?.[index] || 5000;
    const image = item.image_carruseltop_path || "";
    return {
      name,
      url,
      timeShow,
      image,
    };
  });
  return imagesTop;
  // const imagesArray = sponsorWithImages.reduce((accumulator, item) => {
  //   const name = item.sponsor_name || "";
  //   const url = item.sponsor_url || "#";
  //   const timeShow = item.countDownImages?.[0] || 5000;
  //   const images = item.image_carruseltop_path || [];

  //   return [
  //     ...accumulator,
  //     ...images.map((image) => ({
  //       name,
  //       url,
  //       timeShow,
  //       image,
  //     })),
  //   ];
  // }, []);
  // return imagesArray;
};

//Filter banner bottomVideos
export const filterAdsImgBvideos = (
  data: Array<any>,
  currentLanguage: string
) => {
  let sponsorWithImages = data.filter(
    (item) =>
      item.image_carruselvideo_path !== null &&
      item.countDownImages !== "" &&
      item.buycarrusel_videos === true &&
      item.status === "ACTIVE" &&
      item.isactive === true &&
      item.language === currentLanguage
  );
  if (sponsorWithImages.length === 0) {
    sponsorWithImages = data.filter(
      (item) =>
        item.image_carruselvideo_path !== null &&
        item.countDownImages !== "" &&
        item.buycarrusel_videos === true &&
        item.status === "ACTIVE" &&
        item.isactive === true &&
        item.language === "en"
    );
  }
  // console.log('Que trae?',sponsorWithImages)
  const imgBottomVideo = sponsorWithImages.map((item: any, index: number) => {
    const name = item.sponsor_name || "";
    const url = item.sponsor_url || "#";
    const timeShow = item.countDownImages?.[0] || 5000;
    const image = item.image_carruselvideos_path || "";

    return {
      name,
      url,
      timeShow,
      image,
    };
  });
  return imgBottomVideo;
};
//Filter banner BTW VIDEOS
export const filterAdsBtwVideos = (
  data: Array<any>,
  currentLanguage: string
) => {
  let sponsorWithImages = data.filter(
    (item) =>
      item.image_btw_videos_path?.length > 0 &&
      item.buy_btwVideos === true &&
      item.status === "ACTIVE" &&
      item.isactive === true &&
      item.language === currentLanguage
  );

  // Si no hay imágenes para el idioma actual, filtrar por inglés
  if (sponsorWithImages.length === 0) {
    sponsorWithImages = data.filter(
      (item) =>
        item.image_btw_videos_path?.length > 0 &&
        item.buy_btwVideos === true &&
        item.status === "ACTIVE" &&
        item.isactive === true &&
        item.language === "en"
    );
  }

  const imagesArray = sponsorWithImages.reduce((accumulator, item) => {
    const name = item.sponsor_name || "";
    const url = item.sponsor_url || "#";
    const timeShow = item.countDownImages?.[0] || 5000;
    const images = item.image_btw_videos_path || [];

    return [
      ...accumulator,
      ...images.map((image) => ({
        name,
        url,
        timeShow,
        image,
      })),
    ];
  }, []);
  return imagesArray;
};
//Filter ads Videos
export const filterAdsVideos = (data: Array<any>, currentLanguage: string) => {
  let sponsorWithVideos: any = data.filter(
    (item) =>
      item.videos_adspath !== "" &&
      item.buyvideo_ads !== false &&
      item.status === "ACTIVE" &&
      item.language === currentLanguage
  );
  //Si no hay videos para el idioma actual , filtrar por ingles.
  if (sponsorWithVideos.length === 0) {
    sponsorWithVideos = data.filter(
      (item: any) =>
        item.videos_adspath !== "" &&
        item.buyvideo_ads !== false &&
        item.status === "ACTIVE" &&
        item.language === "en"
    );
  }

  //Procesar los paths de los videos
  const videosAdsArr = sponsorWithVideos
    .flatMap((item) => {
      if (typeof item.videos_adspath === "string") {
        return item.videos_adspath.split(",");
      } else if (Array.isArray(item.videos_adspath)) {
        return [].concat(...item.videos_adspath);
      }
      return [];
    })
    .map((src) => ({ src }));

  return videosAdsArr;
};
