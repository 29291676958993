import { Home } from "./pages/home";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import { ViewVideo } from "./pages/video";

import { FooterComponent } from "./components/footer";
import { Container, Content, Footer, Header } from "rsuite";
import { NavBar } from "./components/navbar";
import { FC, ReactNode, useContext, useEffect } from "react";
import { LoginPage } from "./pages/auth/login";
import { RegisterPage } from "./pages/auth/register";
import { ResetPage } from "./pages/auth/reset";
import React from "react";
import { StartLive } from "./pages/startlive";
import ConfigContext from "./utils/Config";
import { Acercade } from "./pages/acercade";
import { Faq } from "./pages/faq";
import Contacto from "./pages/contacto";
import Planessocios from "./pages/planessocios";
import Planesclubes from "./pages/planesclubes";
import AuthContext from "./utils/Auth";
import { Perfil } from "./pages/perfil";
import { Eventos } from "./pages/eventos";
import { Evento } from "./pages/evento";
import { ClubAdmin } from "./pages/clubadmin";
import { PerfilPlayer } from "./pages/perfilplayer";
import Cookies from "./components/Cookies";
import PoliciesCookies from "./components/policiesCookies";
import SponsorsView from "./pages/admin/sponsors";
import DataSaveHLProvider from "./context/saveHLInfo";

export function Rutas() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <SuspensedView>
                <Home />
              </SuspensedView>
            }
          />
          <Route
            path="/v"
            element={
              <SuspensedView>
                <DataSaveHLProvider>
                  <ViewVideo />
                </DataSaveHLProvider>
              </SuspensedView>
            }
          />
          <Route
            path="/login"
            element={
              <SuspensedView>
                <LoginPage />
              </SuspensedView>
            }
          />
          <Route
            path="/register"
            element={
              <SuspensedView>
                <RegisterPage />
              </SuspensedView>
            }
          />
          <Route
            path="/reset"
            element={
              <SuspensedView>
                <ResetPage />
              </SuspensedView>
            }
          />
          <Route
            path="/startlive"
            element={
              <SuspensedView>
                <StartLive />
              </SuspensedView>
            }
          />
          <Route
            path="/acercade"
            element={
              <SuspensedView>
                <Acercade />
              </SuspensedView>
            }
          />
          <Route
            path="/faq"
            element={
              <SuspensedView>
                <Faq />
              </SuspensedView>
            }
          />
          <Route
            path="/contacto"
            element={
              <SuspensedView>
                <Contacto />
              </SuspensedView>
            }
          />
          <Route
            path="/planessocios"
            element={
              <SuspensedView>
                <Planessocios />
              </SuspensedView>
            }
          />
          <Route
            path="/planesclubes"
            element={
              <SuspensedView>
                <Planesclubes />
              </SuspensedView>
            }
          />
          <Route
            path="/perfil"
            element={
              <SuspensedView>
                <Perfil />
              </SuspensedView>
            }
          />
          <Route
            path="/perfil/p"
            element={
              <SuspensedView>
                <PerfilPlayer />
              </SuspensedView>
            }
          />
          <Route
            path="/eventos"
            element={
              <SuspensedView>
                <Eventos />
              </SuspensedView>
            }
          />
          <Route
            path="/eventos/e"
            element={
              <SuspensedView>
                <Evento />
              </SuspensedView>
            }
          />
          <Route
            path="/clubadmin"
            element={
              <SuspensedView>
                <ClubAdmin />
              </SuspensedView>
            }
          />
          <Route
            path="/policiesCookies"
            element={
              <SuspensedView>
                <PoliciesCookies />
              </SuspensedView>
            }
          />
          {/* nueva ruta sponsor proximamente */}
          <Route path="/admin/sponsors" element={<SponsorsView />} />
        </Routes>
      </Router>
    </>
  );
}

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SuspensedView: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <>
      <Cookies />
      <Container>
        <Header>
          <NavBar />
        </Header>
        <Content className="main">{children}</Content>
        <div className="footer">
          <FooterComponent />
        </div>
      </Container>
    </>
  );
};
