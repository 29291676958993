import {
  faEye,
  faPlay,
  faThumbsDown,
  faThumbsUp,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../env";
import { language, Textos } from "../texto";
import { Get } from "../utils/consumir";
import { ProcesarNumerosGrandes } from "../utils/utilidades";
const URL_API = API_URL || "";

type Props = {
  status: string | "live" | "stream" | "highlight";
  viewsCount: number;
  LikesCount: number;
  dislikesCount: number;
  duration: string;
  title: string;
  club: string;
  user: string;
  imageuser: string;
  previewimage: string;
  id: string;
  alldata: any;
  cameraName: string;
  date: string;
  highlightcat: number;
};

export function CardPVVideo(props: Props) {
  const navigate = useNavigate();

  let statusclass =
    "status " +
    (props.status === "live"
      ? "live"
      : props.status === "highlight"
      ? "highlight"
      : props.status === "stream"
      ? "stream"
      : "");
     
  return (
    <>
      <div
        style={{
          marginLeft: "20px",
          marginRight: "20px",
          marginTop: "30px",
        }}
      >
        <Card
          style={{
            width: "30rem",
            borderRadius: "30px",
            border: "2px",
            boxShadow: "10px 10px 10px 0px rgba(0,0,0,0.35)",
          }}
        >
          <div className="views">
            <span>
              <FontAwesomeIcon icon={faEye} />{" "}
            </span>
            <span>{ProcesarNumerosGrandes(props.viewsCount)} </span>
            <span>{Textos[language].views}</span>
          </div>

          <div className="likes">
            <span>
              <FontAwesomeIcon icon={faThumbsUp} />{" "}
            </span>
            <span>{ProcesarNumerosGrandes(props.LikesCount)} </span>
            {/* <span><FontAwesomeIcon icon={faThumbsDown} />  </span>
                        <span>{ProcesarNumerosGrandes(props.dislikesCount)}</span> */}
          </div>
          <div className="time">
            <span>
              <FontAwesomeIcon icon={faVideo} />{" "}
            </span>
            <span>{props.duration}</span>
          </div>
          <div className={statusclass}>
            <span>
              <FontAwesomeIcon icon={faVideo} />{" "}
            </span>
            <span>{props.status}</span>
          </div>
          <div
            onClick={() => {
              navigate("/v?id=" + props.id, {
                state: {
                  ...props,
                },
              });
            }}
            className="play"
          >
            <span>
              <FontAwesomeIcon icon={faPlay} />{" "}
            </span>
          </div>

          <Card.Img
            onClick={() => {
              navigate("/v?id=" + props.id, {
                state: {
                  ...props,
                },
              });
            }}
            style={{
              width: "30rem",
              borderRadius: "10px",
            }}
            variant="top"
            src={props.previewimage}
          />
        </Card>
        <Card.Body style={{ width: "30rem" }}>
          <div className="bodycard" style={{ marginTop: "10px" }}>
            <img
              onClick={() => {
                navigate("/?user=" + props.alldata.userdata.id);
              }}
              className="circular--square cursortrue"
              src={props.imageuser}
              alt="user"
            />
            <div
              className="bodycarddata"
              style={{ width: "25rem", marginLeft: "10px", marginTop: "2px" }}
            >
              <label
                onClick={() => {
                  navigate("/v?id=" + props.id, {
                    state: {
                      ...props,
                    },
                  });
                }}
                className="bodycarddatatitle cursortrue"
              >
                {props.title}
              </label>
              <label
                onClick={() => {
                  navigate("/?club=" + props.alldata.clubData.id);
                }}
                className="bodycarddatacd cursortrue"
              >
                {props.club}
              </label>
              <label className="bodycarddatacd">{props.cameraName}</label>
              <label
                onClick={() => {
                  navigate("/?user=" + props.alldata.userdata.id);
                  //console.log(props.alldata.userdata.id_country);
                }}
                className="bodycarddatacd cursortrue"
              >
                {props.user} {" " + props.alldata.userdata.flag ? props.alldata.userdata.flag : ''}
              </label>

              {/* <label> {props.date} </label> */}
            </div>
          </div>
        </Card.Body>
      </div>
    </>
  );
}
