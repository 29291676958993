import { createContext, useState, ReactNode, FC } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { API_URL } from "../env";
import { Post } from "./consumir";
import { IdTokenPromise, logout, auth } from "./firebase";
import { useTranslation } from "react-i18next";

const initialAuth: any = null;

const AuthContext = createContext(initialAuth);
const tokenID = "sportpro-token-v2";
const AuthProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const URL_API = API_URL || '';

    const [currentUser, setCurrentUser] = useState(initialAuth);
    const [t] = useTranslation('global');
    const handleAuth = (e: any) => {
        
        if (currentUser) {
            setCurrentUser(null);
        } else {
            setCurrentUser(e);
        }
    }


    const GetUser2 = async () => {
        const idToken = await IdTokenPromise();
        if (!idToken) {
            const user = await Post(`${URL_API}/api/user/get`, { idToken });
            setCurrentUser(user);
            return user;
        }
    }

    const GetUser = async () => {
        const token = localStorage.getItem(tokenID);
        
        if (token) {
            try {
                let res = await Post(URL_API + "/login/getDataClient", {
                    token: token
                });
                setCurrentUser(res.data);
            } catch (e) {
                

                logout();
                logoutUser();
            }
        }

    }

    const GetTokenLogin = () => {
        const token = localStorage.getItem(tokenID);
        if (token === null) return null;
        return token;
    }

    const logoutUser = () => {
        localStorage.removeItem(tokenID);
        setCurrentUser(null);
    }

    const saveToken = (token: string) => {
        localStorage.setItem(tokenID, token);
    }

   

    const data = { GetUser, handleAuth, saveToken, currentUser, logoutUser, setCurrentUser, GetTokenLogin, GetUser2, auth, t };
    return <AuthContext.Provider value={data} >{children}</AuthContext.Provider>;
}

export { AuthProvider };
export default AuthContext;