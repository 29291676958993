import React from "react";
import { Row, Col } from "rsuite";
import AdminTemplate from "./template/adminTemplate";

const SponsorsView = () => {
  return (
    <AdminTemplate>
      <Row>
        <Col>
          <p>Hello world!</p>
        </Col>
      </Row>
    </AdminTemplate>
  );
};

export default SponsorsView;
