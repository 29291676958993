import { Get, Post, Put } from '../consumir';
import { API_URL_DEV, API_URL, API_URL_LOCAL_DEV } from '../../env';


// const URL_API:any = process.env.REACT_APP_API_URL;
// const URL_API_DEV:any = "https://backendsportprotvdev-p456tcdk5q-ue.a.run.app";

const tokenUser:any = process.env.REACT_APP_TOKEN_SPORTPRO;



export const getAllSponsors = async () => {

    const response = await Get(`${API_URL_DEV}/sponsors`);
    const status = response.status;
    if(status !== 200){
        console.log("ERROR NETWORK CONNECTION GET SPONSOR",status);
    }
    return response;
};

export const getAllImagesBtwVideos = async () =>{
    const response = await Get(`${API_URL_DEV}/sponsors/getAllBtwVideos`);
    const status = response.status;
    if(status !== 200){
        console.error("ERROR NETWORK CONNECTION GET ALL IMG BTW VIDEOS",status);
    }
    return response;
}

// Servicio que se deberá utilizar proximamente

export const createSponsor = async (values:any , token:any) => {

    const response = await Post(`${API_URL_DEV}/sponsors`,values);
    const status = response.status;
    if(status !== 200){
        console.error("ERROR NETWORK CONNECTION CREATE A NEW SPONSOR",status);
    }
    return response;
}
export const updateSponsor = async (values:any , token:any) => {

    const response = await Put(`${API_URL_DEV}/sponsors`,values);
    const status = response.status;
    if(status !== 200){
        console.error("ERROR NETWORK CONNECTION UPDATE A  SPONSOR",status);
    }
    return response;
}

export const incrementViewsBtwVideos = async(id:number) => {
    const response = await Get(`${API_URL_DEV}/sponsors/incrementSponsorView/${id}`);
    const status = response.status;
    if(status !== 200){
        console.error("ERROR NETWORK CONNECTION INCREASE VIEWS BTW",status);
    }
}

export const  incrementViewsAdsVideos = async(id:number) => {
    const response = await Get(`${API_URL_DEV}/sponsors/incrementSponsorVideoAds/${id}`);
    const status = response.status;
    if(status !== 200){
        console.error("ERROR NETWORK CONNECTION INCREASE VIEWS VIDEO ADS ",status);
    }
}
export const  incrementViewsImgBottomVideo = async(id:number) => {
    const response = await Get(`${API_URL_DEV}/sponsors/incrementSponsorBottomVideo/${id}`);
    const status = response.status;
    if(status !== 200){
        console.error("ERROR NETWORK CONNECTION INCREASE VIEWS IMG BT VIDEO ",status);
    }
}
export const  incrementViewsImgBannerHome = async(id:number) => {
    const response = await Get(`${API_URL_DEV}/sponsors/incrementSponsorHome/${id}`);
    const status = response.status;
    if(status !== 200){
        console.error("ERROR NETWORK CONNECTION INCREASE VIEWS BANNER HOME ",status);
    }
}
