import axios from 'axios'

export async function Get(api: string) {
    //console.log(api);
    return await axios.get(api);
}

export async function Post(api: string, data: any) {
    //console.log(api);

    return await axios.post(api, data);
}

export async function Put(api: string, data: any) {
    //console.log(api);

    return await axios.put(api, data);
}

export async function Delete(api: string) {
    //console.log(api);

    return await axios.delete(api);
}

export async function Method(api: string, method: string, data?: any) {

    method = method.toLowerCase();

    if (method === "get") {
        return await axios.get(api);
    }
    if (method === "post") {
        return await axios.post(api, data);
    }
    if (method === "put") {
        return await axios.put(api, data);
    }
    if (method === "delete") {
        return await axios.delete(api);
    }
}

export async function GetToken(URL_API: string) {

    return Get(`${URL_API}/tokens`);

}

export function getParameter(paramName: string) {
    var searchString = window.location.search.substring(1),
        i, val, params = searchString.split("&");
  
    for (i=0;i<params.length;i++) {
      val = params[i].split("=");
      if (val[0] == paramName) {
        return val[1];
      }
    }
    return null;
  }
  