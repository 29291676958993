import { useContext, useEffect, useState } from "react";
import { Divider, Loader, Message, Placeholder, Uploader, useToaster } from "rsuite";
import AvatarIcon from '@rsuite/icons/legacy/Avatar';
import AuthContext from "../utils/Auth";
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { CampoRequerido, CaracteresMaximos, CaracteresMinimos, Paises } from "../utils/utilidades";
import { SelectPicker } from 'rsuite';
import { API_URL } from "../env";
import { Get, getParameter, Post } from "../utils/consumir";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendPasswordReset } from "../utils/firebase";




export function PerfilPlayer() {
    const idplayer = getParameter("id") || "";

    const toaster = useToaster();
    const [uploading, setUploading] = useState(false);
    const [open, setOpen] = useState(false);
    const [terminos, setTerminos] = useState(false);
    const [aceptnotif, setAceptnotif] = useState(false);
    const [aceptinfo, setAceptinfo] = useState(false);
    const [loading, setLoading] = useState(true);

    const [found, setFound] = useState(false);

    const [provider, setProvider] = useState("");
    const tokenID = "sportpro-token-v2";
    const navigate = useNavigate();

    const { currentUser, GetUser, logoutUser, auth } = useContext(AuthContext);
    const [hideTerm, setHideTerm] = useState(false);
    const URL_API = API_URL || '';

    const [fileInfo, setFileInfo] = useState(null);
    function previewFile(file: any, callback: any) {

        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    }


    useEffect(() => {
        setLoading(true);
        Get(URL_API + "/login/getDataPlayer/"+idplayer).then((res) => {
            if(res.data === null || res.data === undefined){
                setFound(false);
                //console.log("No encontrado");
            }else{
                setFound(true);
                setTerminos(res.data.term);
                setHideTerm(res.data.term);
                setProvider(res.data.provider);
                formik.setValues({
                    nombre: res.data.firstname,
                    apellido: res.data.lastname,
                    pais: res.data.country ? res.data.country.countryname ? res.data.country.countryname : "" : "",
                    imagen: res.data.photoicon,
                    nickname: res.data.nickname,
                    check: res.data.term,
                    aceptnotif: res.data.aceptnotif,
                    aceptinfo: res.data.aceptinfo
                });
                setAceptnotif(res.data.aceptnotif);
                setAceptinfo(res.data.aceptinfo);
            }
        }).finally(() => {
            setLoading(false);
        });



    }, [])

    const formik = useFormik({
        initialValues: {
            nombre: '',
            apellido: '',
            pais: '',
            imagen: '',
            nickname: '',
            check: false,
            aceptnotif: false,
            aceptinfo: false

        },
        validationSchema: Yup.object().shape({
            nombre: Yup.string()
                .min(3, CaracteresMinimos(3))
                .max(50, CaracteresMaximos(50))
                .nullable(true)
                .required(CampoRequerido('Nombre')),
            apellido: Yup.string()
                .min(3, CaracteresMinimos(3))
                .max(50, CaracteresMaximos(50))
                .nullable(true)
                .required(CampoRequerido('Apellido')),
            pais: Yup.string()
                .min(3, CaracteresMinimos(3))
                .max(50, CaracteresMaximos(50))
                .nullable(true)
                .required(CampoRequerido('Pais')),
            nickname: Yup.string()
                .min(3, CaracteresMinimos(3))
                .max(50, CaracteresMaximos(50))
                .nullable(true)
                .required(CampoRequerido('Nickname')),

            imagen: Yup.string()
                .min(3, CaracteresMinimos(3))
                .nullable(true)
                .required(CampoRequerido('Imagen')),
            check: Yup.string()
                .nullable(true)
                .matches(/true/, 'Debe aceptar los términos y condiciones')
                .required(CampoRequerido('Terminos y Condiciones')),
            aceptinfo: Yup.boolean(),
            aceptnofif: Yup.boolean(),
            claveactual: Yup.string(),
            nuevaclave: Yup.string(),
            confirmarclave: Yup.string(),
        }),

        validateOnMount: true,
        onSubmit: (values: any) => {

        }
    }
    )
    return (
        <>
            {loading ? <Placeholder.Grid rows={5} /> :

                found?
                <div className="form w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                    <div className="card-body border-top p-9">
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Foto de Perfil</label>
                            <div className="col-lg-8">
                                <Uploader
                                disabled
                                    fileListVisible={false}
                                    listType="picture"
                                    autoUpload={true}
                                    action={URL_API + '/upload'}
                                    onUpload={file => {

                                        setUploading(true);
                                        previewFile(file.blobFile, (value: any) => {
                                            setFileInfo(value);
                                        });
                                    }}
                                    onSuccess={(response, file) => {

                                        formik.setFieldValue('imagen', response.url);
                                        setUploading(false);
                                        toaster.push(<Message type="success">Uploaded successfully</Message>);
                                    }}
                                    onError={() => {

                                        setFileInfo(null);
                                        setUploading(false);
                                        toaster.push(<Message type="error">Upload failed</Message>);
                                    }}
                                >
                                    <button style={{ width: 150, height: 150 }}>
                                        {uploading && <Loader backdrop center />}
                                        {fileInfo ? (
                                            <img src={fileInfo} width="100%" height="100%" />
                                        ) : (
                                            formik.values.imagen ? <>
                                                <img src={formik.values.imagen} width="100%" height="100%" />
                                            </> : <AvatarIcon style={{ fontSize: 80 }} />
                                        )}
                                    </button>
                                </Uploader>

                                <div className="form-text">Permite archivos del tipo: .gif, .png, .jpg, .jpeg</div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Nombre</label>
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                <input readOnly {...formik.getFieldProps('nombre')}
                                    onChange={(value) => {
                                        formik.setFieldValue('nombre', value.target.value);
                                    }}
                                    type="text" name="nombre" className="form-control form-control-lg form-control-solid" placeholder="Nombre" />
                                <div className="fv-plugins-message-container invalid-feedback" /></div>
                        </div>

                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Apellido</label>
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                <input readOnly {...formik.getFieldProps('apellido')}
                                    onChange={(value) => {
                                        formik.setFieldValue('apellido', value.target.value);
                                    }}
                                    type="text" name="apellido" className="form-control form-control-lg form-control-solid"  />
                                <div className="fv-plugins-message-container invalid-feedback" /></div>
                        </div>

                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Apodo / Nombre de Canal</label>
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                <input readOnly {...formik.getFieldProps('nickname')}
                                    onChange={(value) => {
                                        formik.setFieldValue('nickname', value.target.value);
                                    }}
                                    type="text" name="nickname" className="form-control form-control-lg form-control-solid" placeholder="Nickname o Apodo" />
                                <div className="fv-plugins-message-container invalid-feedback" /></div>
                        </div>

                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                <span className="required">País</span>
                                <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" aria-label="Country of origination" data-kt-initialized={1} />
                            </label>
                            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                <SelectPicker
                                readOnly
                                    {...formik.getFieldProps('pais')}
                                    name="pais"
                                    onChange={(value: any) => {
                                        formik.setFieldValue('pais', value);

                                    }}
                                    style={{
                                        width: '100%'
                                    }} data={Paises.sort((a, b) => {
                                        if (a.label < b.label) { return -1; }
                                        if (a.label > b.label) { return 1; }
                                        return 0;
                                    })} />

                            </div>
                        </div>

                        {
                            !hideTerm ?
                                <div className="fv-row mb-8 fv-plugins-icon-container">
                                    <label className="form-check form-check-inline">
                                        <input
                                        disabled
                                            {...formik.getFieldProps('check')}

                                            style={{
                                                transform: "scale(2)"

                                            }} className="form-check-input" type="checkbox" name="toc" onChange={() => {
                                                formik.setFieldValue('check', !formik.values.check);
                                                setTerminos(!terminos);
                                            }} checked={terminos} />
                                        <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">Aceptar
                                            <label onClick={() => {
                                                setOpen(!open)
                                            }} className="ms-1 link-primary" style={{
                                                cursor: "pointer"
                                            }}>Términos y Condiciones</label>  </span>
                                    </label>
                                    <div className="fv-plugins-message-container invalid-feedback" />
                                </div>
                                :
                                <></>
                        }


                        <div className="fv-row mb-8 fv-plugins-icon-container">
                            <label className="form-check form-check-inline">
                                <input
                                disabled
                                    {...formik.getFieldProps('aceptnotif')}

                                    style={{
                                        transform: "scale(2)"

                                    }} className="form-check-input" type="checkbox" name="toc" onChange={() => {
                                        formik.setFieldValue('aceptnotif', !formik.values.aceptnotif);
                                        setAceptnotif(!aceptnotif);
                                    }} checked={aceptnotif} />
                                <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">Acepto notificaciones por email</span>
                            </label>
                            <div className="fv-plugins-message-container invalid-feedback" />
                        </div>

                        <div className="fv-row mb-8 fv-plugins-icon-container">
                            <label className="form-check form-check-inline">
                                <input
                                disabled
                                    {...formik.getFieldProps('aceptinfo')}

                                    style={{
                                        transform: "scale(2)"

                                    }} className="form-check-input" type="checkbox" name="toc" onChange={() => {
                                        formik.setFieldValue('aceptinfo', !formik.values.aceptinfo);
                                        setAceptinfo(!aceptinfo);
                                    }} checked={aceptinfo} />
                                <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">Quiero estar informado acerca de Eventos, Juegos y Ofertas</span>
                            </label>
                            <div className="fv-plugins-message-container invalid-feedback" />
                        </div>

                    </div>
                    
                    {/* </form> */}
                </div>
                :
                <div>
                    <div className="text-center">
                    <h4>El identificador del perfil no se encuentra registrado.</h4>
                    </div>
                </div>
            }
            {/* <form id="kt_account_profile_details_form" className="form w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto" > */}

        </>
    )
}