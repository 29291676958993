import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../styles/carousel.css";

export enum optionImage {
  fill = "fill",
  cover = "cover",
  contain = "contain",
  none = "none",
  scale_down = "scale-down",
}

export enum CarouselContext {
  BannerMain = "BannerMain",
  BtwVideos = "BtwVideos",
  BottomVideo = "BottomVideo",
}

interface CarouselBannerProps {
  data: any;
  time?: number;
  optionImage?: optionImage;
  enableArrows?: boolean;
  autoplay?: boolean;
  isBannerAd?: boolean;
  locate: CarouselContext;
}

const CarouselStyle = styled.img`
  display: block;
  @media (max-width: 2248px) {
    width: 100%;
    max-height: 250px;
  }
  opacity: 0;
  border-radius: 4px;
  object-position: 50% 50%;
  transition: all 1s ease-out;
  transition-duration: 1000ms;
  &.loaded {
    opacity: 1;
  }
`;

const CarouselBox = styled.div`
  width: 970px;
  height: 250px;
  background: #ccc;
  overflow: hidden;
`;
const CarouselButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 15px;
  border-radius: 10px;
`;
const CarouselButton = styled.button`
  color: white;
  background-color: #3584e6;
  padding: 8px;
  margin: 0 5px;
  border-radius: 10px;
`;

const CarouselContent = styled.a`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  background-color: #3584e6;
  width: 100%;
`;

export const CarouselBanner = (props: CarouselBannerProps) => {
  const {
    data,
    time,
    optionImage,
    enableArrows,
    autoplay,
    isBannerAd,
    locate,
  } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(data[0]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (autoplay && !enableArrows) {
      const interval = setTimeout(() => {
        selectedNewImage(selectedIndex, data);
      }, selectedImage.timeShow);

      return () => clearTimeout(interval);
    }
  });

  const selectedNewImage = (
    index: number,
    images: Array<Object>,
    next = true
  ) => {
    setLoader(false);
    setTimeout(() => {
      const condition = next ? index < images.length - 1 : index > 0;
      const nextIndex = next
        ? condition
          ? selectedIndex + 1
          : 0
        : condition
        ? selectedIndex - 1
        : images.length - 1;
      if (locate === CarouselContext.BtwVideos) {
        const randomIndex = Math.floor(Math.random() * data.length);
        setSelectedImage(data[randomIndex]);
      } else {
        setSelectedImage(data[nextIndex]);
      }
      setSelectedIndex(nextIndex);
    }, 500);
  };

  const previous = () => {
    selectedNewImage(selectedIndex, data, false);
  };

  const next = () => {
    selectedNewImage(selectedIndex, data);
  };

  return (
    <div
      className={
        locate === "BtwVideos"
          ? "BtwVideosContainer"
          : locate === "BannerMain"
          ? "MainBannerContainer"
          : locate === "BottomVideo"
          ? "BottomVideoContainer"
          : "CarouselContainer"
      }
    >
      <a href={selectedImage?.url} target="_blank" rel="noreferrer">
        <img
          src={selectedImage.image}
          alt={selectedImage.name}
          onLoad={() => {
            setLoader(true);
          }}
          className={loader ? "loaded" : ""}
        />
      </a>
      {enableArrows && (
        <CarouselButtonContainer>
          <CarouselButton onClick={previous}>{"<"}</CarouselButton>
          <CarouselButton onClick={next}>{">"}</CarouselButton>
        </CarouselButtonContainer>
      )}
    </div>
  );
};
