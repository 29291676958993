import { initializeApp } from "firebase/app";
import firebase from "firebase/app";

// Import the functions you need from the SDKs you need
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  setPersistence,
  browserLocalPersistence,
  FacebookAuthProvider,
} from "firebase/auth";

import { Post } from "./consumir";
import { API_URL } from "../env";
import Swal from "sweetalert2";
import { language, Textos } from "../texto";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const URL_API = API_URL || '';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDIhAWNJ0m7Qn7Ho0iozJXRPxKU6v9RNqY",
  authDomain: "sportprotv.firebaseapp.com",
  projectId: "sportprotv",
  storageBucket: "sportprotv.appspot.com",
  messagingSenderId: "74244759513",
  appId: "1:74244759513:web:794d2a56e6b909b36043c8"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

const facebookProvider = new FacebookAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res: any = await signInWithPopup(auth, googleProvider);
    const user = res.user;

    let resultado = await authLogin(user.accessToken);

    return {
      redirect: resultado.redirect,
      token: resultado.token,
    };

  } catch (err: any) {
    Swal.fire({
      title: "Error",
      text: err.message,
      icon: "error",
    });

  }
};

const signInWithFcebook = async () => {
  try {
    const res: any = await signInWithPopup(auth, facebookProvider);
    debugger;
    const user = res.user;
    let resultado = await authLogin(user.accessToken);
    return {
      redirect: resultado.redirect,
      token: resultado.token,
    };

  } catch (err: any) {
    Swal.fire({
      title: "Error",
      text: err.message,
      icon: "error",
    });

  }
};
const logInWithEmailAndPassword = async (email: string, password: string) => {
  
  try {
    await setPersistence(auth, browserLocalPersistence);
    let user: any = await signInWithEmailAndPassword(auth, email, password);
    let resultado = await authLogin(user.user.accessToken);
    return {
      redirect: resultado.redirect,
      token: resultado.token,
    };

  } catch (err: any) {
    console.error(err);
  }
};

const registerWithEmailAndPassword = async (name: string, email: string, password: string, pais: string) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user: any = res.user;
    let login = await Post(URL_API + "/login", {
      uid: user.uid,
      name: name,
      authProvider: "local",
      email: email,
      pais: pais,
      photoicon: "https://storage.googleapis.com/publicsportprotv/892781.png",
    });
    let resultado = await authLogin(user.accessToken);
    return {
      redirect: resultado.redirect,
      token: resultado.token,
    };

  } catch (err: any) {
    Swal.fire({
      title: "Error",
      text: err.message,
      icon: "error",
    });
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
   

  } catch (err: any) {
   console.error("ERROR FIREBASE: " + err.message);
  }
};
const logout = async () => {
  await signOut(auth);

};
const IdTokenPromise = () => {
  if (auth) {
    let test: any = auth;
    if (test.currentUser) {
      return test.currentUser.getIdToken(true);
    }
    return Promise.resolve(null);
  }
}


const authLogin = async (token: any) => {
  let res = await Post(URL_API + "/login/auth", { token: token });

  localStorage.setItem("sportpro-token-v2", res.data.data);
  return {
    redirect: res.data.redirect,
    token: res.data.data,
  }
}

export {
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  IdTokenPromise,
  signInWithFcebook
};