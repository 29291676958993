import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_URL, REACT_APP_ENV_STATUS_DEV } from "../env";
import AuthContext from "../utils/Auth";
import { Post } from "../utils/consumir";
import { useTranslation } from "react-i18next";
import { SaveSuscriptionPaypalInformation } from "../utils/Api/payPalServices";
const STATUS_ENV = REACT_APP_ENV_STATUS_DEV;

type Props = {
  idplan: string;
  tipoplan: string;
  idcliente: string;
};
const PROD:string =
  "AQt-joggUEmfbqAIltaMjBt7Ix90JFA5NeLIKGZxiC1wUfttTJmG0k9xgEaX8j79IsWkbqSwB2UtGaJd";
const DEV:string =
"AZWak-qpAORhVR212uevLyw0A3LpLovMIvsKE98OVB96MDJ929Cz4u8ucWf4cI1F0iyZoMHab9fmRtLq";
const PLANID_PRODTESTING:string = "P-7DJ7918813472144HMX5E37A";
const PLANID_PROD:string = "P-7TS6228264587662WMNZNM3Q";
const PLANID_DEV:string = "P-28D314351H008881EMNG7JTA";
const PLANID_SWITCH:string = STATUS_ENV  ? PLANID_DEV : PLANID_PROD;
const TOKEN:string = STATUS_ENV ? DEV : PROD;



export function PayPalPopUp(props: Props) {


  const { currentUser, GetUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [keystate, setKeyState] = React.useState(Math.random());
  const [t] = useTranslation("global");

  const URL_API = API_URL || "";
  function GuardarDatosSubscripcion(datos: any, tiposub: String) {
    const data = {
      paypalresponse: datos,
      iduser: currentUser.id_player,
      fecha: new Date(),
      plan: tiposub,
    };

    Post(`${URL_API}/tokens/SavePaypalResponse/`, data).then(
      async (res) => {
        await GetUser();
        Swal.fire(
         `${t("ModalMessages.alreadyMember")}`,
         `${t("ModalMessages.description")}`,
          "success"
        );
        setTimeout(() => {
          
          window.location.reload();
        }, 1500);
      },
      (err) => {
        Swal.fire(
          `${t("ModalMessages.errorMessage")}`,
          "Mensaje de error: " + err.toString(),
          "error"
        );
      }
    );
  }

  const SaveDataSuscription = async (data:ObjectConstructor, tiposub:string) => {
    const values = {
      paypalresponse:data,
      iduser:currentUser.id_player,
      fecha:new Date(),
      plan: tiposub,
    };
    console.log('Datos a enviar:', values, tiposub);
    SaveSuscriptionPaypalInformation(values,tiposub).then((resp:any)=>{
      if(resp){
        console.log('Response callback =>  SaveSuscriptionPaypalInformation',resp);
        GetUser();
        Swal.fire(
          `${t("Suscriptions.ModalMessages.alreadyMember")}`,
          `${t("Suscriptions.ModalMessages.description")}`,
           "success"
         );
         window.location.reload();
      }
    }).catch((err:Error)=>{
      Swal.fire(
        `${t("Suscriptions.ModalMessages.errorMessage")}`,
        "Mensaje de error: " + err.toString(),
        "error"
      );
    });
  }
  const ButtonWrapper = ({ type }: any) => {
        const [{ options }, dispatch] = usePayPalScriptReducer();
        console.log('Que se está mandando en opciones?',options);
        console.log('Como llega Token: ', STATUS_ENV  ? 'DEV' : 'PROD');
    const [loadingInicial, setLoadinginicial] = useState(true);
    useEffect(() => {
      setTimeout(function () {
        dispatch({
          type: "resetOptions",
          value: {
            ...options,
            intent:type,
            // intent: "subscription",
            // "client-id": TOKEN,
            // vault: true,
          },
        });

        setLoadinginicial(false);
      }, 100);

      // setTimeout(function () {
      //   setLoadinginicial(false);
      // }, 3000);
    }, [type]);

    return loadingInicial ? (
      <div className="cssload-loader">
        <div className="cssload-inner cssload-one"></div>
        <div className="cssload-inner cssload-two"></div>
        <div className="cssload-inner cssload-three"></div>
      </div>
    ) : (
      <PayPalButtons
        // onApprove={(data: any, actions: any) => {
        //   ////console.log(data, actions);
        //   GuardarDatosSubscripcion(data, props.tipoplan);
        //   //alert('Pago realizado con éxito');
        //   return actions.order.capture().then(function (details: any) {
        //     // This function shows a transaction success message to your buyer.
        //     alert("Transaction completed by " + details.payer.name.given_name);
        //   });
        // }}
        //plan_id: "P-7TS6228264587662WMNZNM3Q", PROD
        //plan_id: "P-28D314351H008881EMNG7JTA", DESA
        createSubscription={(data, actions) => {
          console.log('Cuales son las acciones a enviar',actions);
          console.log('Que datas se envian?',data);
          console.log('Que planID llega?',PLANID_SWITCH);
          return actions.subscription
            .create({
              // plan_id: props.idplan,
              plan_id:PLANID_SWITCH,
            })
            .then((orderId) => {
              // Your code here after create the order
              console.log('El numero de orden es :',orderId);
              return orderId;
            });
        }}
        onApprove={(data:any,actions:any) =>{
          // GuardarDatosSubscripcion(data,props.tipoplan);
          SaveDataSuscription(data,props.tipoplan);
          console.log('Que devuelve actions?',actions);
          return actions.order;
  
        }}
        
        
        style={{
          label: "subscribe",
          layout:'vertical',
          shape:"pill",
          color:'blue',
        }}
      />
    );
  };

  useEffect(() => {
    setTimeout(function () {
      setKeyState(Math.random());
    }, 500);
  }, []);

  return (
    <>
      {loading ? (
        <div>
          <div className="cssload-loader">
            <div className="cssload-inner cssload-one"></div>
            <div className="cssload-inner cssload-two"></div>
            <div className="cssload-inner cssload-three"></div>
          </div>
          <p style={{ textAlign: "center" }}>{t("paypal.wait")}</p>
          <p style={{ textAlign: "center" }}>
            {t("paypal.ifScreen")}
          </p>
        </div>
      ) : (
        <div key={keystate}>
          <PayPalScriptProvider
            options={{
              // "client-id": "test",
              "client-id":TOKEN,
              components: "buttons",
              intent: "subscription",
              currency:"USD",
              vault: true,
            }}
          >
            <ButtonWrapper type="subscription" />
          </PayPalScriptProvider>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={() => {
                setKeyState(Math.random());
              }}
            >
             {t("paypal.recharge")}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
