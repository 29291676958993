import { faTv, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";
import { Col, Container, Grid, Row } from "rsuite";
import { useNavigate } from "react-router-dom";
import "./eventcard.css";


type Props = {
    id : string,
    dateevent: string;
    streams: number;
    content: string;
    club:string;
    urlimagen: string;
}

export function EventCard(props:Props){
    
    const navigate = useNavigate();
    let datee = props.dateevent;
    let streamse = props.streams;
    let clube = props.club;

    if(clube === undefined)
       clube = "Club no registrado";
    if(streamse === undefined)
        streamse = 0;
    if(datee === undefined)
        datee = "Fecha no registrada";
    
    return (
        <div style={{
            marginLeft: "20px",
            marginRight: "20px",
            marginTop: "30px",
            marginBottom: "30px"
        }}>

        <Card className="cardEvent">
            <Card.Body className="backgroundCard" 
            style={{
                backgroundImage: `url('${props.urlimagen}')`,
            }}
            onClick={
                () => {
                    navigate("/eventos/e?id=" + props.id);
                }
            }>
                <div className="cardtitlebox"></div>
                <div className="cardtitlebox">
                    <h3 className="cardtitle">{props.content}</h3>
                </div>
                <div className="cardtitlebox"></div>
                
            </Card.Body>
            <div className="streams">
                        <span><FontAwesomeIcon icon={faTv} />  </span>
                        <span>{streamse}</span>
                    </div>
                    <div className="time">
                       <strong><span>{datee}</span></strong>
                    </div>
                    <div className="club">
                        <span>{clube}</span>
                    </div>
        </Card>
        </div>
        
    )
}