import acercade2 from "../utils/images/acercade2.jpg";
import {
  faArrowUpRightDots,
  faCertificate,
  faEarthAmerica,
  faShareNodes,
  faShield,
  faUpload,
  faUserGroup,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { language, Textos } from "../texto";

import "./styles.css";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
export function Acercade() {
  const [t] = useTranslation("global");
  return (
    <>
      <div className="container">
        <div className="mt-5 grid-container-dos border-top">
          <div className="mt-5 row">
            <h2 className="text-center display-2 fw-bold">
              {t("WeUs.Title")}
            </h2>
          </div>
          <div
            className="row"
            style={{
              marginTop: "30px",
            }}
          >
            <div className="col">
              <p className="text-center">
                <span>
                  <FontAwesomeIcon
                    className="icono-encabezado-uno"
                    icon={faVideo}
                  />
                    {t("WeUs.Description1")}
                </span>
              </p>
              <p className="text-center">
                <span>
                  <FontAwesomeIcon
                    className="icono-encabezado-uno"
                    icon={faEarthAmerica}
                  />
                    {t("WeUs.Description2")}
                </span>
              </p>

              <p className="text-center">
                <span>
                  <FontAwesomeIcon
                    className="icono-encabezado-uno"
                    icon={faCertificate}
                  />
                    {t("WeUs.Description3")}
                </span>
              </p>
              <p className="text-center">
                <span>
                  <FontAwesomeIcon
                    className="icono-encabezado-uno"
                    icon={faShield}
                  />
                    {t("WeUs.Description4")}
                </span>
              </p>
              <p className="text-center">
                <span>
                  <FontAwesomeIcon
                    className="icono-encabezado-uno"
                    icon={faArrowUpRightDots}
                  />
                   {t("WeUs.Description5")}
                </span>
              </p>
              <div className="flex flex-row items-center justify-center w-full mt-4">
                <img
                  alt="logo-google"
                  src="/google-icon.svg"
                  className="mr-3"
                />
                <p>{Textos[language].acercade6}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 grid-container-dos border-top">
          <div className="mt-5 row">
            <h1 className="text-center display-2 fw-bold">
            {t("WeUs.Title2")}
            </h1>
          </div>
          <div className="row">
            <div className="col-md-3 div-cuadro-uno">
              <div className="row">
                <div className="col div-cuadro-icono">
                  <FontAwesomeIcon icon={faShareNodes} />
                </div>
              </div>
              <h5 className="div-label-cuadro">
              {t("WeUs.ShareContent")}
              </h5>
              <p className="text-center">   {t("WeUs.ShareContent_Description")}</p>
            </div>
            <div className="col-1"></div>
            <div className="col-md-3 div-cuadro-uno">
              <div className="row">
                <div className="col div-cuadro-icono">
                  <FontAwesomeIcon icon={faUpload} />
                </div>
              </div>
              <h5 className="div-label-cuadro">   {t("WeUs.BetterGame")}</h5>
              <p className="text-center">
              {t("WeUs.BetterGame_Description")}
              </p>
            </div>
            <div className="col-1"></div>
            <div className="col-md-3 div-cuadro-uno">
              <div className="row">
                <div className="col div-cuadro-icono">
                  <FontAwesomeIcon icon={faUserGroup} />
                </div>
              </div>

              <h5 className="div-label-cuadro">   {t("WeUs.CreateSportingNetwork")}</h5>
              <p className="text-center">
              {t("WeUs.CreateSportingNetwork_Description")}
              </p>
              {/* <p className="text-center">
                Crea tu propia red. Sigue a tus deportistas preferidos.
              </p> */}
            </div>
            <div className="col-1"></div>
          </div>
        </div>

        <div className="mt-5 grid-container-dos border-top">
          <div className="mt-5 row">
            <h1 className="text-center display-2 fw-bold">
            {t("WeUs.Title3")}
            </h1>
          </div>
          <div className="row">
            <div className="col-md-3 div-cuadro-uno">
              <div className="row">
                <div className="col div-cuadro-icono">
                  <FontAwesomeIcon icon={faUserGroup} />
                </div>
              </div>
              <h5 className="div-label-cuadro">
              {t("WeUs.CatchPlayers")}
              </h5>
              <p className="text-center card-text">
              {t("WeUs.CatchPlayer_Description")}
              </p>
            </div>
            <div className="col-1"></div>
            <div className="col-md-3 div-cuadro-uno">
              <div className="row">
                <div className="col div-cuadro-icono">
                  <FontAwesomeIcon icon={faUpload} />
                </div>
              </div>
              <h5 className="div-label-cuadro">
              {t("WeUs.TransmitionsEventsLives")}
              </h5>
              <p className="text-center card-text">
              {t("WeUs.TransmitionsEventsLives_Description")}
              </p>
            </div>
            <div className="col-1"></div>
            <div className="col-md-3 div-cuadro-uno">
              <div className="row">
                <div className="col div-cuadro-icono">
                  <FontAwesomeIcon icon={faShareNodes} />
                </div>
              </div>
              <h5 className="div-label-cuadro">
              {t("WeUs.GenerateNetworkSocial")}
              </h5>
              <p className="text-center card-text">
              {t("WeUs.GenerateNetworkSocial_Description")}
              </p>
            </div>
            <div className="col-1"></div>
          </div>
        </div>

        <div className="mt-5 grid-container-dos border-top">
          <div className="mt-5 row">
            <h1 className="text-center display-2 fw-bold">
            {t("WeUs.Title4")}
            </h1>
          </div>
          <div className="row">
            <div className="col div-cuadro-uno">
              <p className="text-center mt-15">
              {t("WeUs.SubTitle")}
              </p>
            </div>
            <div className="col-md-6">
              <img
                src={acercade2}
                style={{
                  width: "100%",
                }}
                className="imagen"
                alt="acercade2"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
