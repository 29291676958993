export const Textos = {
    es: {
        keepfree: "Seguir gratis",
        YaSubcribe: "Ya estás suscrito",
        subme: "Suscribirme",
        proximamente: "Próximamente",
        subcribe: "¡Suscríbete!",
        exclusivep: '(Exclusivo Plan Pro)',
        free: '(Gratis)',
        clubadmin:"Club Admin",
        mustbeprotitle: "Debes ser PRO",
        becomingpro: "Conviértete en PRO",
        becomingprotouse: "Conviértete en PRO para usar esta función",
        doesnothavepermission: "No tiene los permisos para realizar esta acción",
        needtobetagged: "Necesitas ser etiquetado para realizar esta acción",
        yesdelete: "Si, eliminar",
        nocancel: "No, cancelar",
        errorhappend: "Ha ocurrido un error",
        requiredtext: 'Este campo es requerido',
        home: "Inicio",
        streamnow: "En vivo ahora",
        mylives: "Mis Transmisiones",
        sportclubs: "Clubes Deportivos",
        sport: "Deportes",
        account: "Cuenta",
        login: "Iniciar sesión",
        logout: "Cerrar sesión",
        views: "Vistas",
        viewslarge: "Visualizaciones",
        likes: "Me gusta",
        dislikes: "No me gusta",
        newhere: "¿Nuevo aquí? ",
        email: "Correo Electrónico",
        password: "Contraseña",
        repassword: "Volver a ingresar contraseña",
        forgotpassword: "¿Olvidaste tu contraseña?",
        or: "o",
        continueWith: "Continuar con",
        createAccount: "Crear una cuenta",
        name: "Nombre",
        register: "Registrar",
        reset: "Restablecer contraseña",
        donthaveaccount: "¿No tienes una cuenta?",
        filter: "Filtrar",
        filtertitle: "Filtrar videos",
        cancel: "Cancelar",
        select: "Seleccionar",
        search: "Buscar",
        noresults: "No hay resultados",
        typevideo: "Tipo de video",
        sort: "Ordenar por",
        live: "En Vivo",
        warning: "Advertencia",
        vod: "Stream",
        highlight: "Highlight",
        latest: "Más Reciente",
        livestartdescribe: "Describe tu juego, entrenamiento o encuentro",
        livestartprivate: "Visualización de la Transmisión",
        livestartduration: "Duración de la Transmisión",
        seconds: "segundos",
        second: "segundo",
        minutes: "Minutos",
        minute: "Minuto",
        hours: "Horas",
        hour: "Hora",
        days: "Días",
        day: "Día",
        weeks: "Semanas",
        week: "Semana",
        months: "Meses",
        month: "Mes",
        years: "Años",
        year: "Año",
        public: "Público (Gratis)",
        private: "Privado (Exclusivo Plan Pro)",
        startlive: "Iniciar Transmisión (Start Streaming)",
        club: "Club Deportivo",
        viewmylive: "Mi transmisión en Vivo",
        stoptranmision: "Finalizar Transmisión",
        stoptranmisiontitle: "Finalizando Transmisión",
        loading: "Un momento, Iniciando Transmisión...",
        stoprecord: "Espere por favor, Deteniendo transmisión...",
        resultstoprecord: "Transmisión detenida exitosamente.",
        livestartdescribeerror: "La Descricpión debe tener al menos 10 caracteres",
        ConfigLive: "Iniciar Transmisión",
        ViewVideos: "Ir a la pagina Principal",
        errorLogin: "Correo y/o contraseña incorrectos",
        resetpwdemail: "Se ha enviado el correo para restablecer la contraseña, revise sus correos en SPAM",
        about: "Acerca de",
        faq: "Preguntas frecuentes",
        contact: "Contacto",
        us: "Nosotros",
        plans: "Planes",
        partnerplans: "Planes Deportistas",
        clubplans: "Planes Clubes",
        accept: "Acepto los términos y condiciones",
        reject: "No acepto los términos y condiciones",
        username: "Nombre de usuario",
        updateok: "Actualización exitosa",
        eliminacionok: "Eliminación exitosa",

        // TEXTOS EVENTOS
        event: "Evento/Torneo",
        events: "Eventos/Torneos",
        eventtitle: "Título del evento/torneo",
        eventdescription: "Descripción del evento/torneo",
        eventdate: "Fecha del evento",
        eventtime: "Hora del evento",
        eventduration: "Duración del evento",
        eventtype: "Tipo de evento",
        eventprivate: "Privacidad del evento",

        eventcreate: "Crear evento",
        eventcreateq: "¿Desea crear el evento?",
        eventcreateok: "Evento creado exitosamente",
        eventcreateerror: "Error al crear el evento",
        
        eventdelete: "Eliminar evento",
        eventdeleteq: "¿Desea eliminar el evento?",
        eventdeleteok: "Evento eliminado exitosamente",
        eventdeleteerror: "Error al eliminar el evento",

        eventupdate: "Actualizar evento",
        eventupdateq: "¿Desea actualizar el evento?",
        eventupdateok: "Evento actualizado exitosamente",
        eventupdateerror: "Error al actualizar el evento",

        eventdeletevideo : "Eliminar video del evento",
        eventdeletevideoq : "¿Desea eliminar el video del evento?",
        eventdeletevideook : "Video eliminado del evento exitosamente",
        eventdeletevideoerror : "Error al eliminar el video del evento",

        eventdeletevideoconfirm: "Sí, eliminar video",
        confirmcancel: "No, cancelar",

        //TEXTOS TAGGEDUSERS
        taggeduserstitle: "Etiquetar Usuarios",
        taggedusers: "Usuarios etiquetados",
        taggedusersalready: "Usuarios ya etiquetados",
        taggedusersdelete: "Eliminar usuario etiquetado",
        taggedusersdeleteq: "¿Desea eliminar el usuario etiquetado?",
        taggedusersdeleteok: "Usuario etiquetado eliminado exitosamente",
        taggedusersdeleteerror: "Error al eliminar el usuario etiquetado",

        taggerusersnew: "Nuevo usuario etiquetado",
        taggerusersnewq: "¿Desea agregar el usuario etiquetado?",
        taggerusersnewok: "Usuarios etiquetados agregados exitosamente",
        taggerusersnewerrortitle: "Error al agregar el usuario etiquetado",
        taggerusersnewerror: "Se ha producido un error al agregar el usuario etiquetado",
        taggerusersnewerror2: "El usuario ya se encuentra etiquetado",
        taggerusersnewerror3: "Debe ingresar al menos un usuario",

        taggedusercheckerror: "El usuario ingresado no existe",
        taggedusercheckerror2: "El usuario ingresado ya se encuentra etiquetado",

        cantedittags: "No puedes editar los tags de este vídeo, sólo los usuarios PRO pueden hacerlo.",
        cantcreateevent: "Para crear un evento es necesario que cuente con una cuenta administradora de club",






        //TEXTOS HIGHLIGHT 

        highlighttitle: "Creación de Highlight",
        highlightduration: "Duración",
        highlightdurationerror: "La duración debe ser mayor a 10 segundos y menor a 2 minutos",
        highlightoncreate:"Creando video destacado",
        highlightoncreateoktitle:"Highlight creado",
        highlightoncreateok:"Se ha creado el video destacado exitosamente",
        highlightoncreateerror:"No se pudo crear el video destacado",
        highlightoncreateerror2:"No se pudo crear el video destacado, el video debe ser de al menos 10 segundos",

        //TEXTOS VISUALIZADOR VIDEO
        vvideoloadingPrivacytitle: "Actualizando ",
        vvideoloadingPrivacy: "Cambiando la privacidad del vídeo.",
        vvideoPrivacyOktitle: "Actualización correcta",
        vvideoPrivacyOk: "Se ha cambiado la privacidad del vídeo correctamente.",
        vvideoPrivacyErrtitle: "Error",
        vvideoPrivacyErr: "Se ha producido un error al cambiar la privacidad del vídeo.",

        // TEXTOS ACERCA DE NOSOTROS
        acercadeh2:"Te damos la mejor tecnología de Streaming del mundo para:",
        acercade1:"¡Transmitir, grabar, compartir tu deporte como un Profesional!",
        acercade2:"SportPro.TV es una empresa Chilena para el mundo.",
        acercade3:"Fundada por Ingenieros con más de 25 años de experiencia en tecnología.",
        acercade4:"Desarrollada bajo los mayores estándares de seguridad.",
        acercade5:"Con una arquitectura tecnológica que permite escalar a nivel mundial.",
        acercade6:"Powered by Google Cloud Platform.",

        acercade1h1:"Como Deportista, por medio de nuestra plataforma podrás:",
        acercade1h5: "Compartir tu contenido",
        acercade1p1:"Podrás transmitir en vivo tu partido, publicar tus grandes jugadas en tus redes sociales o almacenarlas para verlas más tarde. Muéstrale a todos el gran deportista que eres.",
    }
}


export const language = "es";