import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import "./login.css";
import {
  auth,
  logInWithEmailAndPassword,
  signInWithGoogle,
  signInWithFcebook,
} from "../../utils/firebase";
import { Get, Post } from "../../utils/consumir";
import AuthContext from "../../utils/Auth";
import { language, Textos } from "../../texto";
import { API_URL } from "../../env";
import { Button } from "rsuite";
import { PDFObject } from "react-pdfobject";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { getDataUser, updateStatusUser } from "../../utils/Api/usersApi";
import { Document, Page } from "react-pdf";
import "pdfjs-dist/build/pdf.worker.entry";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { pdfjs } from "react-pdf";
import Lottie from "lottie-react";
import loaderPdf from "../../animations/loadingPdf.json";
import { Modal } from "antd";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const device = navigator.userAgent;
let regexp = /android|iphone|kindle|ipad|huawei|zte|honor|galaxy/i;
const isMobileDevice = regexp.test(device);

export function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const { setCurrentUser, saveToken, GetUser, currentUser } =
    useContext(AuthContext);

  const URL_API = API_URL || "";
  const { state } = useLocation();
  const [t, i18n] = useTranslation("global");
  const [open, setOpen] = useState(false);
  const [terminos, setTerminos] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (currentUser) navigate("/");
  }, [user, loading]);

  const loginWithFirebase = () => {
    logInWithEmailAndPassword(email, password)
      .then((resp: any) => {
        if (resp !== undefined) {
          // console.log("Que devuelve resp?",resp);
          if (resp.redirect) {
            navigate("/perfil");
          } else {
            const token = resp.token;
            getDataUser(token)
              .then((resp: any) => {
                // console.log("me trae los datos del usuario?",resp);
                setCurrentUser(resp.data);
                const playerId = resp.data.id_player;
                updateStatusUser(playerId)
                  .then((resp: any) => {
                    // console.log("QUe trae el status user?", resp);
                    if (resp.data) {
                      getDataUser(token).then((resp: any) => {
                        setCurrentUser(resp.data);
                      });
                    }
                    GetUser();
                    let data: any = state;
                    navigate("/");
                    if (data?.video) {
                      navigate("/startlive?video=" + data?.video);
                    }
                  })
                  .catch((err: any) => {
                    console.error("Error al traer el status del usuario", err);
                  });
              })
              .catch((err: any) => {
                console.error("Error al traer los datos del usuario?", err);
              });
          }
        } else {
          Swal.fire({
            title: "Error",
            text: `${t("Login.Modals.errorLogin")}`,
            icon: "error",
          });
        }
      })
      .catch((err: any) => {
        Swal.fire({
          title: "Error",
          text: `${t("Login.Modals.errorLogin")}`,
          icon: "error",
        });
      });
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setTerminos(true);
  }

  return (
    <>
      <div className="p-10 mx-auto rounded shadow-sm w-lg-500px bg-body p-lg-15">
        <form
          className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          id="kt_sign_in_form"
          data-kt-redirect-url="/metronic8/demo1/../demo1/index.html"
          action="#"
        >
          <div className="mb-10 text-center">
            <h1 className="mb-3 text-dark">{t("Login.Title")}</h1>
            <div className="text-gray-400 fs-4">
              {t("Login.Description")}
              <Link to="/register" className="link-primary">
                {t("Login.Description2")}
              </Link>
            </div>
          </div>
          <div className="mb-10 fv-row fv-plugins-icon-container">
            <label className="form-label fs-6 fw-bolder text-dark">
              {t("Login.Email")}
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              name="email"
              autoComplete="off"
              placeholder={t("Login.Email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
          <div className="mb-10 fv-row fv-plugins-icon-container">
            <div className="mb-2 d-flex flex-stack">
              <label className="mb-0 form-label fw-bolder text-dark fs-6">
                {t("Login.Password")}
              </label>
              <Link to="/reset" className="link-primary fs-6 fw-bolder">
                {t("Login.ForgottenPassword")}
              </Link>
            </div>
            <input
              className="form-control form-control-lg form-control-solid"
              type="password"
              name="password"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>

          <div className="text-center">
            <button
              type="button"
              id="kt_sign_in_submit"
              className="mb-5 btn btn-lg btn-primary w-100"
              onClick={async () => {
                loginWithFirebase();
              }}
            >
              <span className="indicator-label">{t("Login.Signin")}</span>
              <span className="indicator-progress">
                {t("Login.Loading")}
                <span className="align-middle spinner-border spinner-border-sm ms-2" />
              </span>
            </button>

            <div className="mb-5 text-center text-muted text-uppercase fw-bolder">
              {Textos[language].or}
            </div>
            <div className="mb-8 fv-row fv-plugins-icon-container">
              <label className="form-check form-check-inline">
                <input
                  style={{
                    transform: "scale(2)",
                  }}
                  className="form-check-input"
                  type="checkbox"
                  name="toc"
                  onChange={() => {
                    setTerminos(!terminos);
                  }}
                  checked={terminos}
                />
                <span className="text-gray-700 form-check-label fw-semibold fs-base ms-1">
                  {t("Login.PolicyAndConditions")}
                  <label
                    onClick={() => {
                      setOpen(!open);
                      // if(isMobileDevice){
                      //   // console.log("Entre aqui es mobile");
                      //  window.open("https://storage.googleapis.com/sportprotv-images/Te%CC%81rminos%20y%20condiciones%20sportpro.pdf",'_blank');
                      // }else{
                      //   // console.log("Entre aqui es desktop");
                      //   setOpen(!open);

                      // }
                    }}
                    className="ms-1 link-primary"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {t("Login.PolicyAndConditions2")}
                  </label>
                  {t("Login.PolicyAndConditions3")}
                </span>
              </label>
              <div className="fv-plugins-message-container invalid-feedback" />
            </div>
            <button
              type="button"
              disabled={terminos ? false : true}
              onClick={async () => {
                let res = await signInWithGoogle();
                // saveToken(res?.data);
                if (res?.redirect) {
                  navigate("/perfil");
                  // navigate("/");
                } else {
                  let resultado = await Post(URL_API + "/login/getDataClient", {
                    token: res?.token,
                  });
                  setCurrentUser(resultado.data);
                  let update = await Get(
                    URL_API + `/tokens/subStatus/${resultado.data.id_player}`
                  );
                  if (update.data) {
                    resultado = await Post(URL_API + "/login/getDataClient", {
                      token: res?.token,
                    });
                    setCurrentUser(resultado.data);
                  }

                  GetUser();
                  let data: any = state;
                  navigate("/");
                  if (data?.video) {
                    navigate("/startlive?video=" + data?.video);
                  }
                }
              }}
              className="mb-5 btn btn-flex flex-center btn-light btn-lg w-100"
            >
              <img alt="Logo" src="/google-icon.svg" className="h-20px me-3" />
              {t("Login.SignGoogle")}
            </button>

            <button
              type="button"
              disabled={terminos ? false : true}
              onClick={async () => {
                let res = await signInWithFcebook();
                //saveToken(res?.data);
                debugger;
                if (res?.redirect) {
                  // navigate("/updateuser");
                  navigate("/");
                } else {
                  let resultado = await Post(URL_API + "/login/getDataClient", {
                    token: res?.token,
                  });
                  setCurrentUser(resultado.data);
                  let update = await Get(
                    URL_API + `/tokens/subStatus/${resultado.data.id_player}`
                  );
                  if (update.data) {
                    resultado = await Post(URL_API + "/login/getDataClient", {
                      token: res?.token,
                    });
                    setCurrentUser(resultado.data);
                  }
                  GetUser();
                  let data: any = state;

                  if (data?.video) {
                    navigate("/startlive?video=" + data?.video);
                  } else {
                    navigate("/");
                  }
                }
              }}
              className="mb-5 btn btn-flex flex-center btn-light btn-lg w-100"
            >
              <img
                alt="LogoFacebook"
                src="/facebook-icon.png"
                className="h-30px w-30px me-3"
              />
              {t("Login.SignFacebook")}
            </button>
          </div>
          <div />
        </form>
      </div>
      <Modal
        open={open}
        title={t("Login.PolicyAndConditions2")}
        onCancel={() => {
          setOpen(false);
        }}
        footer={[
          <Button
            color={terminos ? "green" : "red"}
            onClick={() => {
              if (terminos) {
                setTerminos(true);
                setOpen(!open);
              } else {
                setTerminos(true);
                setOpen(!open);
              }
            }}
            appearance="primary"
          >
            {" "}
            {!terminos
              ? t("Cookies.Cookies_button.accept_button")
              : t("Cookies.Cookies_button.degree_button")}
          </Button>,
        ]}
      >
        <Document
          file={
            i18n.language === "es"
              ? "https://storage.googleapis.com/sportprotv-images/Te%CC%81rminos%20y%20condiciones%20sportproTV%20V4%20Espan%CC%83ol.pdf"
              : i18n.language === "en"
              ? "https://storage.googleapis.com/sportprotv-images/Te%CC%81rminos%20y%20condiciones%20sportproTV%20V4%20Ingle%CC%81s.pdf"
              : "https://storage.googleapis.com/sportprotv-images/Te%CC%81rminos%20y%20condiciones%20sportproTV%20V4%20Portugues.pdf"
          }
          onLoadSuccess={onDocumentLoadSuccess}
          renderMode="canvas"
          onError={() => {
            return (
              <div>
                <p>Error loading pdf...</p>
              </div>
            );
          }}
          loading={() => {
            return (
              <div className="flex items-center justify-center w-full pt-3 ">
                <Lottie
                  animationData={loaderPdf}
                  style={{
                    width: 450,
                    height: 450,
                  }}
                />
              </div>
            );
          }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={isMobileDevice ? 300 : 480}
            />
          ))}
        </Document>
      </Modal>
    </>
  );
}
