import { Delete, Get, Post, Put } from "../consumir";
import { API_URL, API_URL_DEV, API_URL_LOCAL_DEV } from "../../env";
import axios from 'axios';

export const getAllEvents = async (values: any) => {
  const { count, club_id } = values;

  const response = await Get(`${API_URL}/event/all/${count}/${club_id}`);

  const status = response.status;

  if (status !== 200) {
    console.error("ERROR NETWORK CONNECTION GET EVENTS", status);
  }
  return response;
};

export const getEventById = async (id:string) =>{
  try {
    const response =  await Get(`${API_URL}/event/${id}`);

    return response;

  } catch (error) {

    console.error("ERROR NETWORK CONNECTION GET EVENT", error);
    
  }
}


export const getEventsByClubId = async (values:any) => {

// console.log("que me trae los values 🚭", values);

const {id, count} = values;

// console.log('que trae los valores?', values);

  try {

    const response = await axios.get(`${API_URL}/live/event/${id}/${count}`);

    return response
    
  } catch (error) {

    console.error(" ERROR NETWORK CONNECTION GET EVENTS BY ID CLUB", error);
    
  }

}

export const createEvent = async (values: any) => {
  const response = await Post(`${API_URL}/event/crear`, values);

  const status = response.status;


  if (status !== 200) {
    console.error("ERROR NETWORK CONNECTION CREATE EVENT", status);
  }
  return response;
};

export const updateEventByiD = async (id:string , values)=>{
  try {
    const response = await  Put(`${API_URL}/event/${id}`,values);
    return response;
    
  } catch (error) {
    console.error(" ERROR NETWORK CONNECTION UPDATE EVENT", error);
    
  }
}

export const deleteEventById = async (id:string) => {

  try {
    const response = await  Delete(`${API_URL}/event/${id}`);
    return response;
    
  } catch (error) {
    console.error(" ERROR NETWORK CONNECTION DELETE EVENT", error);
  }
} 

export const deleteVideoById = async (id:string) => {
  try {

    const response = await  Get(`${API_URL}/event/quit/${id}`)
    return response;
    
  } catch (error) {
    
    console.error("ERROR NETWORK CONNECTION DELETE VIDEO BY ID EVENT");
  }
}

