import { faCancel, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import React from "react";
import { useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import Swal from "sweetalert2";
import { PayPalPopUp } from "../components/paypalpopup";
import { API_URL , REACT_APP_ENV_STATUS_DEV } from "../env";
import { language, Textos } from "../texto";
import AuthContext from "../utils/Auth";
import { Post } from "../utils/consumir";
import "./planessocios.css";
import { useTranslation } from "react-i18next";
const STATUS_ENV = REACT_APP_ENV_STATUS_DEV;

export default function Planessocios() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [popup, setPopup] = React.useState(false);

  const planPro = [
    {
      title: `${t("Suscriptions.Sucriptions_Pro.FromTo")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.Transmitions")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.ShareIn")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.PrivateAndPublic")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.CreateHL")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.ShareWithCoach")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.Tagged_users")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.NoAdsVideos")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.Download")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.Storage2")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.TrainingTools")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Sucriptions_Pro.MarkBelong")}`,
      icon: faCancel,
      include: false,
    },
  ];
  const planFree = [
    {
      title: `${t("Suscriptions.Suscription_free.FromTo")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscription_free.Transmitions")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscription_free.ShareIn")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscription_free.PrivateAndPublic")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Suscription_free.CreateHL")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Suscription_free.ShareWithCoach")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Suscription_free.Tagged_users")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Suscription_free.NoAdsVideos")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Suscription_free.Download")}`,
      icon: faCancel,
      include: false,
    },
    {
      title: `${t("Suscriptions.Suscription_free.Storage2")}`,
      icon: faCheckCircle,
      include: true,
    },
  ];
  const planSuperPro = [
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.FromTo")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.Transmitions")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.ShareIn")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.PrivateAndPublic")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.CreateHL")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.ShareWithCoach")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.Tagged_users")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.NoAdsVideos")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.Download")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.Storage2")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.TrainingTools")}`,
      icon: faCheckCircle,
      include: true,
    },
    {
      title: `${t("Suscriptions.Suscriptions_SuperPro.MarkBelong")}`,
      icon: faCheckCircle,
      include: true,
    },
  ];

  const URL_API = API_URL || "";
  function GuardarDatosSubscripcion(datos: any) {
    const data = {
      paypalresponse: datos,
      iduser: currentUser.id_player,
      fecha: new Date(),
    };

    Post(`${URL_API}/tokens/SavePaypalResponse/`, data).then(
      (res) => {
        Swal.fire(
          `${t("Suscriptions.modalMessages.alreadyMember")}`,
          `${t("Suscriptions.modalMessages.description")}`,
          "success"
        );
      },
      (err) => {
        Swal.fire(
          `${t("Suscriptions.modalMessages.errorMessage")}`,
          `${t("Suscriptions.modalMessages.description2")}` +
            err.response.data.message,
          "error"
        );
      }
    );
  }
  console.log('En que estado está Paypal?',STATUS_ENV ? 'DEV' : 'PROD');

  // const ButtonWrapper = ({ type }: any) => {
  //   const [{ options }, dispatch] = usePayPalScriptReducer();
  //   //"client-id":"AQt-joggUEmfbqAIltaMjBt7Ix90JFA5NeLIKGZxiC1wUfttTJmG0k9xgEaX8j79IsWkbqSwB2UtGaJd", PROD
  //   //"client-id":"AYUcvj2L6bIh3UXdKKqPtMIHjs5JjK-dfCLkjUChE0iTcm6BTYYU7GmiD2BoE-nnjfRc0hAWmReOrpCx", DESA
  //   useEffect(() => {
  //     let clientID:string = "";
  //     if(DEV){
  //       clientID ="AZWak-qpAORhVR212uevLyw0A3LpLovMIvsKE98OVB96MDJ929Cz4u8ucWf4cI1F0iyZoMHab9fmRtLq"
  //     }else{
  //       clientID = "AQt-joggUEmfbqAIltaMjBt7Ix90JFA5NeLIKGZxiC1wUfttTJmG0k9xgEaX8j79IsWkbqSwB2UtGaJd"
  //     }
  //     dispatch({
  //       type: "resetOptions",
  //       value: {
  //         ...options,
  //         intent: "subscription",
  //         "client-id": clientID,
  //         vault: true,
  //       },
  //     });
  //     console.log('En que estado está Paypal?',DEV ? 'DEV' : 'PROD');
  //   }, [type, DEV]);

  //   return (
  //     <PayPalButtons
  //       onApprove={(data: any, actions: any) => {
  //         //console.log(data, actions);
  //         GuardarDatosSubscripcion(data);
  //         //alert('Pago realizado con éxito');
  //         return actions.order.capture().then(function (details: any) {
  //           // This function shows a transaction success message to your buyer.
  //           alert("Transaction completed by " + details.payer.name.given_name);
  //         });
  //       }}
  //       //plan_id: "P-7TS6228264587662WMNZNM3Q", PROD
  //       //plan_id: "P-28D314351H008881EMNG7JTA", DESA
  //       createSubscription={(data, actions) => {
  //         return actions.subscription
  //           .create({
  //             plan_id: "P-7TS6228264587662WMNZNM3Q",
  //           })
  //           .then((orderId) => {
  //             // Your code here after create the order
  //             return orderId;
  //           });
  //       }}
  //       style={{
  //         label: "subscribe",
  //       }}
  //     />
  //   );
  // };

  return (
    <div className="container">
      <div className="mt-5 row">
        <div className="col-md-4">
          <div className="text-white card card-pricing card-raised container-plan-pro">
            <div className="bg-red-800 rounded-lg card-body">
              <div
                style={{
                  height: "80px",
                }}
              >
                <h5 className="card-category text-titulo-pro">
                  {t("Suscriptions.suscripcionPro")}
                </h5>
                <h5 className="card-category text-titulo-pro"></h5>
                <div className="flex flex-row items-center justify-center">
                  <small className="text-base font-medium text-white">
                    USD
                  </small>
                  <small className="mx-1 text-xl font-semibold text-white">
                    2,9
                  </small>
                  <small className="text-base font-medium text-white">
                    /{t("Suscriptions.month")}
                  </small>
                </div>
              </div>
              <div className="mt-3 border-white row border-bottom"></div>
              {planPro.map((item, index) => {
                const isLastItem = index === planPro.length - 1;
              return(
                <>
                <div key={index} className="flex flex-row items-center justify-start my-3" >
                  <div className="flex items-center justify-center mr-4 ">
                  <FontAwesomeIcon
                            className={
                              "icono-planes-socios-ok " +
                              (item.include ? "ok-gratis" : "cancel")
                            }
                            icon={item.icon}
                          />
                  </div>
                  <div>
                  <p
                            className="text-planes-socios"
                            style={{
                              color: "white",
                            }}
                          >
                            {item.title}
                          </p>
                  </div>
                </div>
                {!isLastItem && <div className="mt-3 border-white row border-bottom"></div>}
                  </>
              )
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                className="mt-3"
              >
                {currentUser ? (
                  <div>
                    {currentUser?.id_membership !== 1 ? (
                      <button
                        className="px-3 py-3 text-base font-medium text-white bg-black rounded-md"
                        disabled
                      >
                        {t("Suscriptions.suscribeOnready")}
                      </button>
                    ) : (
                      <button
                        className="px-3 py-3 text-base font-medium text-white bg-black rounded-md"
                        onClick={() => {
                          setPopup(true);
                        }}
                      >
                        {t("Suscriptions.suscribe")}
                      </button>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      onClick={() => {
                        navigate("/login");
                      }}
                      className="text-lg font-semibold text-center cursor-pointer hover:text-red-300"
                    >
                      {t("SuscriptionClub.NeedsLogin")}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="text-black card card-pricing card-raised card-plain">
            <div className="card-body ">
              <div>
                <div
                  style={{
                    height: "80px",
                  }}
                >
                  <h5 className="card-category text-titulo-gratis">
                    {t("Suscriptions.suscriptionFree")}
                  </h5>
                  <div className="flex flex-row items-center justify-center">
                    <small className="text-base font-medium text-black">
                      USD
                    </small>
                    <small className="text-xl font-semibold text-black mx-">
                      0,00
                    </small>
                    <small className="text-base font-medium text-black">
                      /{t("Suscriptions.month")}
                    </small>
                  </div>
                </div>
                <div className="mt-3 row border-bottom border-top border-gray"></div>
                {planFree.map((item, index) => {
                const isLastItem = index === planFree.length - 1;
              return(
                <>
                <div key={index} className="flex flex-row items-center justify-start my-3" >
                  <div className="flex items-center justify-center mr-4 ">
                  <FontAwesomeIcon
                              className={
                                "icono-planes-socios-" +
                                (item.include ? "ok-gratis" : "cancel")
                              }
                              icon={item.icon}
                            />
                  </div>
                  <div>
                  <p
                            className="text-planes-socios"
                            style={{
                              color: "black",
                            }}
                          >
                            {item.title}
                          </p>
                  </div>
                </div>
                {!isLastItem && <div className="mt-3 border-gray row border-bottom"></div>}
                  </>
              )
              })}
                {/* <table className="table">
                  <tbody>
                    {planFree.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            height: "10px",
                          }}
                        >
                          <td>
                            <FontAwesomeIcon
                              className={
                                "icono-planes-socios-" +
                                (item.include ? "ok-gratis" : "cancel")
                              }
                              icon={item.icon}
                            />
                          </td>
                          <td
                            className="text-left"
                            style={{ fontWeight: "normal", fontSize: 14 }}
                          >
                            <p className="text-planes-socios">{item.title}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="text-white card card-pricing card-raised container-plan-pro">
            <div className="bg-red-800 rounded-lg card-body ">
              <div
                style={{
                  height: "80px",
                }}
              >
                <span
                  className="position-absolute topbar-badge translate-middle badge rounded-pill bg-slate-900"
                  style={{
                    fontSize: 15,
                    marginTop: "-9px",
                    marginLeft: "24px",
                  }}
                >
                  {t("Suscriptions.Comming")}
                </span>

                <h5 className="card-category text-titulo-pro">
                  {t("Suscriptions.suscriptionSuperPro")}
                </h5>
                <h5 className="card-category text-titulo-pro"></h5>
                <div className="flex flex-row items-center justify-center">
                  <small className="text-base font-medium text-white">
                    USD
                  </small>
                  <small className="mx-1 text-xl font-semibold text-white">
                    --
                  </small>
                  <small className="text-base font-medium text-white">
                    / {t("Suscriptions.month")}
                  </small>
                </div>
              </div>
              <div className="mt-3 border-white row border-bottom"></div>
              {planSuperPro.map((item, index) => {
                const isLastItem = index === planPro.length - 1;
              return(
                <>
                <div key={index} className="flex flex-row items-center justify-start my-3" >
                  <div className="flex items-center justify-center mr-4 ">
                  <FontAwesomeIcon
                            className={
                              "icono-planes-socios-ok " +
                              (item.include ? "ok-gratis" : "cancel")
                            }
                            icon={item.icon}
                          />
                  </div>
                  <div>
                  <p
                            className="text-planes-socios"
                            style={{
                              color: "white",
                            }}
                          >
                            {item.title}
                          </p>
                  </div>
                </div>
                {!isLastItem && <div className="mt-3 border-white row border-bottom"></div>}
                  </>
              )
              })}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {
                  // currentUser ?
                  <div>
                    <button
                      disabled
                      className="px-3 py-3 text-base font-medium text-white bg-black rounded-md opacity-60"
                    >
                      {t("Suscriptions.Comming")}
                    </button>
                  </div>
                  // :
                  // <div
                  //   style={{£
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     alignItems: "center",
                  //   }}
                  // >
                  //   <a
                  //     style={{
                  //       fontSize: '21px',
                  //     }}
                  //     onClick={() => {
                  //       navigate('/login');
                  //     }}
                  //     className="htitle">Debes iniciar sesión para subscribirte.</a>
                  // </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={popup} backdrop="static" style={{ top: "30vh" }}>
        <Modal.Body>
          <div>
            {STATUS_ENV ? (
              <PayPalPopUp
                idplan="P-28D314351H008881EMNG7JTA"
                tipoplan="subscription"
                idcliente="desa"
              ></PayPalPopUp>
            ) : (
              <PayPalPopUp
                idplan="P-7TS6228264587662WMNZNM3Q"
                tipoplan="subscription"
                idcliente=""
              ></PayPalPopUp>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            justifyContent: "center",
          }}
        >
          <div>
            <button
              style={{}}
              onClick={() => {
                setPopup(false);
              }}
              className="btn btn-danger"
            >
              {t("Cookies.Cookies_button.degree_button")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
