import React from "react";
import {
  Sidenav,
  Nav,
  Toggle,
  Container,
  Header,
  Footer,
  Sidebar,
  Content,
  Col,
  Row,
  ButtonGroup,
  IconButton,
  Avatar,
  Badge,
  Whisper,
  Tooltip,
} from "rsuite";
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import MagicIcon from "@rsuite/icons/legacy/Magic";
import GearCircleIcon from "@rsuite/icons/legacy/GearCircle";
import {
  RiSlideshow2Line,
  RiSettings5Fill,
  RiNotification3Line,
} from "react-icons/ri";

const AdminTemplate = (props) => {
  const { children } = props;
  const [expanded, setExpanded] = React.useState(true);
  const [activeKey, setActiveKey] = React.useState("1");

  return (
    <Container className="w-full h-full">
      <Sidebar>
        <div style={{ width: 240 }} className="h-full">
          <hr />
          <Sidenav expanded={expanded} defaultOpenKeys={["3", "4"]}>
            <Sidenav.Body>
              <Nav activeKey={activeKey} onSelect={setActiveKey}>
                <Nav.Item eventKey="1" icon={<DashboardIcon />}>
                  Dashboard
                </Nav.Item>
                <Nav.Item eventKey="2" icon={<GroupIcon />}>
                  User Group
                </Nav.Item>
                <Nav.Menu
                  placement="rightStart"
                  eventKey="3"
                  title="Advanced"
                  icon={<MagicIcon />}
                >
                  <Nav.Item eventKey="3-1">Geo</Nav.Item>
                  <Nav.Item eventKey="3-2">Devices</Nav.Item>
                  <Nav.Item eventKey="3-3">Loyalty</Nav.Item>
                  <Nav.Item eventKey="3-4">Visit Depth</Nav.Item>
                </Nav.Menu>
                <Nav.Menu
                  placement="rightStart"
                  eventKey="4"
                  title="Settings"
                  icon={<GearCircleIcon />}
                >
                  <Nav.Item eventKey="4-1">Applications</Nav.Item>
                  <Nav.Item eventKey="4-2">Channels</Nav.Item>
                  <Nav.Item eventKey="4-3">Versions</Nav.Item>
                  <Nav.Menu eventKey="4-5" title="Custom Action">
                    <Nav.Item eventKey="4-5-1">Action Name</Nav.Item>
                    <Nav.Item eventKey="4-5-2">Action Params</Nav.Item>
                  </Nav.Menu>
                </Nav.Menu>
              </Nav>
            </Sidenav.Body>
            <Sidenav.Toggle
              expanded={expanded}
              onToggle={(expanded) => setExpanded(expanded)}
            />
          </Sidenav>
        </div>
      </Sidebar>
      <Container>
        <Header>
          <Row className="w-full rounded-md bg-slate-200">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="flex flex-row items-center justify-between h-auto"
            >
              <h2 className="text-lg font-semibold text-left text-gray-800">
                Dashboard
              </h2>

              <ButtonGroup className="flex items-center">
                <Whisper
                  placement={"bottom"}
                  followCursor
                  speaker={<Tooltip>Ir al sitio </Tooltip>}
                >
                  <IconButton icon={<RiSlideshow2Line size={20} />} circle />
                </Whisper>
                <Whisper
                  placement={"bottom"}
                  followCursor
                  speaker={<Tooltip>Notificaciones </Tooltip>}
                >
                  <IconButton
                    className="mr-3"
                    icon={
                      <Badge content={5}>
                        <RiNotification3Line size={20} />
                      </Badge>
                    }
                    circle
                  />
                </Whisper>
                <Whisper
                  placement={"bottom"}
                  followCursor
                  speaker={<Tooltip>Cuenta </Tooltip>}
                >
                  <IconButton
                    icon={
                      <Avatar
                        circle
                        src={"https://avatars.githubusercontent.com/u/1203827"}
                      />
                    }
                    circle
                    size="sm"
                  />
                </Whisper>
              </ButtonGroup>
            </Col>
          </Row>
        </Header>
        <Content>{children}</Content>
        <Footer>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="flex flex-row items-center justify-center w-full border-t-2 border-t-gray-400"
            >
              <span className="w-full mt-2 text-sm font-medium text-center text-gray-800">
                &copy; {new Date().getFullYear()} by Recneps{" "}
              </span>
            </Col>
          </Row>
        </Footer>
      </Container>
    </Container>
  );
};

export default AdminTemplate;
