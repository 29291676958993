import React, { useContext, useEffect, useRef, useState } from "react";
import videojs from "video.js";
import vjsqs from "@silvermine/videojs-quality-selector";
import "../styles/videoJS.css"; // styles
import "video.js/dist/video-js.css"; // videoJs Default Styles
import "videojs-thumbnail-sprite"; // videoJs Thumbnail Plugin
import ads from "videojs-contrib-ads"; // videoJs ads
import { Button } from "rsuite";
import ToastSwal from "./toastSwal";
import AuthContext from "../utils/Auth";
import Swal from "sweetalert2";
import { language, Textos } from "../texto";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { downloadVideo } from "../utils/Api/downloadVideo";
require("@silvermine/videojs-quality-selector/dist/css/quality-selector.css");

videojs.registerPlugin("ads", ads);

const VideoJsPlayer = (props) => {
  const {
    options,
    onReady,
    isHLVideo,
    enableSprites,
    source,
    adsEnabled,
    handleActionNext,
    spritesVideo,
    isVideoLive,
    adsVideoPlay,
    onVideoRef,
  } = props;
  const { currentUser ,t} = useContext(AuthContext);
  const navigate = useNavigate();
  const [videoSource, setVideoSource] = useState(source);
  const [isClicked, setIsClicked] = useState(false);
  const [showAd, setShowAd] = useState(false);
  const [addPresentation, setAddPresentation] = useState(false);
  const [pausePlayer, setPausePlayer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(10);
  const [watchAds, setWatchAds] = useState(0);
  const [isDownloadFinished, setIsDownloadFinished] = useState(false);
  let countDown = 0;

  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const downloadRef = useRef(null);
  let adsStarted = useRef(false);
  let pre_rollRef = useRef(false);
  let mid_rollRef = useRef(false);
  let post_rollRef = useRef(false);

  useEffect(() => {
    if (!!source) {
      setVideoSource(source);
    }
  }, [source]);

  useEffect(() => {
    if (showAd && pausePlayer === false) {
      countdownCounter();
    } else {
      clearTimeout(countDown);
    }

    return () => {
      clearTimeout(countDown);
    };
  }, [showAd, timeLeft, pausePlayer]);

  const countdownCounter = () => {
    countDown = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
  };

  const adOverlayOptions = {
    countdown: timeLeft.length || 10,
  };

  function playerRandomAd() {
    if (adsVideoPlay.length > 0) {
      const randomIndex = Math.floor(Math.random() * adsVideoPlay.length);
      setWatchAds(randomIndex);
      playerRef.current.src(adsVideoPlay[randomIndex].src);
    }
  }

  const handleVideoRef = () => {
    if (isVideoLive) {
      return;
    } else {
      onVideoRef(playerRef);
    }
  };

  const handleDownloadCancel = async () => {
    downloadRef.current = axios.CancelToken.source();
    setIsDownloadFinished(false);
  };

  useEffect(() => {
    try {
      if (!playerRef.current) {
        const videoElement = videoRef.current;
        if (!videoElement) return;
        vjsqs(videojs);
        const player = (playerRef.current = videojs(
          videoElement,
          options,
          () => {
            if (adsEnabled && adsVideoPlay.length > 0) {
              onReady && onReady(player);
              player.play();
            } else if (!adsEnabled) {
              onReady && onReady(player);
            } else {
              player.load();
            }
          }
        ));

        player.on("pause", () => {
          setPausePlayer(true);
        });
        player.on("play", () => {
          setPausePlayer(false);
        });
        //manipular los chapters
        if (!!enableSprites) {
          player.thumbnailSprite({
            sprites: [
              {
                url: spritesVideo,
                start: 0,
                duration: 1000,
                interval: 10,
                width: 106,
                height: 60,
              },
            ],
          });
        }

        if (isVideoLive) {
          player.on("retryplaylist", function (e) {
            player.load();
          });
          player.ready(() => {
            player.controlBar.removeChild("LiveDisplay");
            class LiveIcon extends videojs.getComponent("Component") {
              constructor(player, options) {
                super(player, options);
              }
              createEl() {
                const el = videojs.dom.createEl("div", {
                  className: "live-icon-display",
                });

                return el;
              }
            }
            videojs.registerComponent("LiveIcon", LiveIcon);
            if (!!player.getChild("LiveIcon") === false) {
              player.controlBar.addChild("LiveIcon", {});
            }
          });
          player.on("ended", () => {
            Swal.fire(
              "Fin de la transmisión!",
              "La Transmisión a finalizado",
              "info"
            );
          });
        }

        //manipular los anuncios
        if (adsEnabled) {
          /*   player.ima(ima); */

          player.ads({
            liveCuePoints: isVideoLive,
            contentisLive: isVideoLive,
            stitchedAds: false,
          });

          if (isHLVideo) {
            console.log(' ES HL ENTRO A VIDEO 1 ANUNCIO HL');

            player.trigger("adsready", function () {
              if (pre_rollRef) {
                player.trigger("nopreroll");
              }
            });

            // request ads whenever there's new video content
            player.on("contentchanged", function () {
              // in a real plugin, you might fetch new ad inventory here
            });

            player.on("readyforpreroll", function () {
              player.ads.startLinearAdMode();
              // play your linear ad content
              // in this example, we use a static mp4
              // console.log("que llego de video?", adsVideoPlay[watchAds].src);
              playerRandomAd();

              // send event when ad is playing to remove loading spinner
              player.one("adplaying", function () {
                player.trigger("ads-ad-started");
                adsStarted.current = true;
              });

              player.one("adended", function () {
                player.ads.endLinearAdMode();
                adsStarted.current = false;
              });
            });
          } else {
            console.log('NO ES HL ENTRO A VIDEO NORMAL');
            player.trigger("adsready", function () {
              if (pre_rollRef) {
                player.trigger("nopreroll");
              }
            });

            player.on("contentchanged", function () {
            });

            player.on("readyforpreroll", function () {
              player.ads.startLinearAdMode();
              playerRandomAd();

              // send event when ad is playing to remove loading spinner
              player.one("adplaying", function () {
                player.trigger("ads-ad-started");
                adsStarted.current = true;
              });
              player.one("adended", function () {
                player.ads.endLinearAdMode();
                adsStarted.current = false;

                pre_rollRef.current = true;
                // console.log(
                //   "entro a cambiar el video ads en el preroll ahora watch es",
                //   watchAds
                // );
              });
            });
            player.trigger("adsready", function () {
              player.ads.isWaitingForAdBreak();
            });

            //Reproducir el anuncio mid-roll cuando corresponda
            player.on(["timeupdate"], function () {
              var currentTime = Number(player.currentTime().toFixed());
              var duration = Number(player.duration().toFixed());
              var durationAdsMax = 1800; // duración maxima para mostrár un anuncio 30 min  "Pre-roll ,Mid-roll "
              //Post-roll maximo de 30 minutos
              var midrollTime = Math.round(duration / 2);
            
              var showAdsTimeout =
                duration < 60 ? midrollTime - 20 : midrollTime - 10;

              if (currentTime === showAdsTimeout) {
                setShowAd(true);
                if (!!player.getChild("AdOverlay") === false) {
                  player.addChild("AdOverlay", adOverlayOptions);
                }
              }

              player.on("seeked", () => {
                const currentTime = Number(player.currentTime().toFixed());
                if (currentTime >= showAdsTimeout) {
                  setShowAd(false);
                  player.removeChild("AdOverlay");
                }
              });

              // console.log("cumple?",duration >= durationAdsMax );

              if (
                duration >= durationAdsMax &&
                !!pre_rollRef.current &&
                currentTime === midrollTime &&
                !mid_rollRef.current &&
                !player.ads.isInAdMode()
              ) {
                // inicia el modo de anuncio lineal y reproducir el video mid-roll
                console.log("entro al mid-roll")
                if (adsVideoPlay) {
                  setShowAd(false);
                  player.removeChild("AdOverlay");
                  setTimeLeft(10);
                  player.ads.startLinearAdMode();
                  // if (watchAds > 0) {
                  //   player.src(adsVideoPlay[watchAds].src);
                  // } else {
                  //   player.src(adsVideoPlay[1].src);
                  // }
                  playerRandomAd();
                  player.one("adplaying", function () {
                    player.trigger("ads-ad-started");
                    adsStarted.current = true;
                  });
                  player.one("adended", function () {
                    player.ads.endLinearAdMode();
                    mid_rollRef.current = true;
                    adsStarted.current = false;
                  });
                }
              }
            });
            player.trigger("adsready", function () {
              player.ads.isWaitingForAdBreak();
            });
            player.on(["timeupdate"], function () {
              var currentTime = Number(player.currentTime().toFixed());
              var duration = Number(player.duration().toFixed());
              var durationAdsMax = 1900; // duración maxima para mostrár un anuncio 30 min  "Pre-roll ,Mid-roll "
              var endedRollTime = duration - 10;
            
              var endedRollShowAdsTimeout = endedRollTime - 10;
              if (!addPresentation) {
                skipAdsButton.style.display = "none";
                console.log()
              }
              // si la duración del video es mayor a 1 minuto
                if (currentTime === endedRollShowAdsTimeout) {
                  setShowAd(true);
                  if (!!player.getChild("AdOverlay") === false) {
                    player.addChild("AdOverlay", adOverlayOptions);
                  }
                }

                player.on("seeked", () => {
                  const currentTime = Number(player.currentTime().toFixed());
                  if (currentTime >= endedRollShowAdsTimeout) {
                    setShowAd(false);
                    player.removeChild("AdOverlay");
                  }
                });
                // console.log('Cumple para el post-roll?',  duration >= durationAdsMax)
                if (
                  duration >= durationAdsMax &&
                  !!mid_rollRef.current &&
                  !post_rollRef.current &&
                  currentTime === endedRollTime &&
                  !player.ads.isInAdMode()
                ) {
                  // inicia el modo de anuncio lineal y reproducir el video ended-roll
                  console.log("entra al post-roll ")
                  if (adsVideoPlay) {
                    setShowAd(false);
                    player.removeChild("AdOverlay");
                    setTimeLeft(10);
                    player.ads.startLinearAdMode();
                    playerRandomAd();
                    player.one("adplaying", function () {
                      player.trigger("ads-ad-started");
                      adsStarted.current = true;
                    });
                    player.one("adended", function () {
                      player.ads.endLinearAdMode();
                      post_rollRef.current = true;
                      adsStarted.current = false;
                    });
                  } else {
                    player.ads.startLinearAdMode();
                    adsStarted.current = false;
                    player.ads.endLinearAdMode();
                  }
                }
              
            });
            player.trigger("adsready", function () {
              player.ads.isWaitingForAdBreak();
            });
          }
        }
        // consigue el componente Button de videoJS
        const Button = videojs.getComponent("Button");
        const rewind = videojs.extend(Button, {
          constructor: function () {
            Button.apply(this, arguments);
            this.addClass("rewindIcon");
            //inicializa el botón de rewind
          },
          handleClick: function () {
            player.currentTime(player.currentTime() - 10);
          },
        });
        if (!isVideoLive) {
          videojs.registerComponent("rewind", rewind);
          player.getChild("ControlBar").addChild("rewind", {}, 2);
        }

        //implementa el botón adelantar
        const fastForward = videojs.extend(Button, {
          constructor: function () {
            Button.apply(this, arguments);
            this.addClass("fast-forward-icon");
            //inicializa el botón de adelantar
          },
          handleClick: function () {
            player.currentTime(player.currentTime() + 10);
          },
        });
        if (!isVideoLive) {
          videojs.registerComponent("fastForward", fastForward);
          player.getChild("ControlBar").addChild("fastForward", {}, 3);
        }

        //implementa el botón de subtitulos
        // const chaptersButton = videojs.extend(Button, {
        //   constructor: function () {
        //     Button.apply(this, arguments);
        //     this.addClass("chapters-button");
        //     //inicializa el botón de subtitulos
        //   },
        //   handleClick: function () {
        //     setIsClicked((prev) => !prev);
        //   },
        // });
        // videojs.registerComponent("chaptersButton", chaptersButton);
        // player.getChild("ControlBar").addChild("chaptersButton", {});
        //implementa el botón saltar anuncio

        const skipAdsButton = document.createElement("button");
        skipAdsButton.className = "ads-presentation-button";
        skipAdsButton.innerText = "Saltar anuncio";
        skipAdsButton.addEventListener("click", () => {
          if (!!isHLVideo) {
            skipAdsButton.style.display = "none";
            console.log("Entre aqui???");
            pre_rollRef.current = true;
            adsStarted.current = false;
            playerRef.current.ads.endLinearAdMode();
          } else if (pre_rollRef.current === false) {
            pre_rollRef.current = true;
            adsStarted.current = false;
            playerRef.current.ads.endLinearAdMode();
            setAddPresentation(false);
          } else if (mid_rollRef.current === false) {
            mid_rollRef.current = true;
            adsStarted.current = false;
            playerRef.current.ads.endLinearAdMode();
            setAddPresentation(false);
          } else if (post_rollRef.current === false) {
            post_rollRef.current = true;
            adsStarted.current = false;
            playerRef.current.ads.endLinearAdMode();
            setAddPresentation(false);
          }
        });

        const playerContainer = player.el();
        const skipButtonContainer = document.createElement("div");
        skipButtonContainer.className = "ads-presentation";
        skipButtonContainer.appendChild(skipAdsButton);
        player.on("adtimeupdate", function () {
          const time = Number(player.currentTime().toFixed());
          if (time === 1) {
            adsStarted.current = true;
          }
          const duration = Number(player.duration().toFixed());
          if (time === duration) {
            adsStarted.current = false;
          }
          const adsEnds = time === duration / 2;
          const endShowButtons = time >= duration - 4;
          if (time === 10) {
            setAddPresentation(true);
            skipAdsButton.style.display = "block";
            playerContainer.appendChild(skipButtonContainer);
          }
          if (!!adsEnds && !!adsStarted.current) {
            skipAdsButton.style.display = "block";
            playerContainer.appendChild(skipButtonContainer);
          } else {
            if (endShowButtons === true || !adsStarted.current) {
              skipAdsButton.style.display = "none";
            } 
          }
        });

        //! implementa el componente pausa publicitaria

        class AdOverlay extends videojs.getComponent("Component") {
          constructor(player, options) {
            super(player, options);
            this.countdown = options.countdown || 10;
            this.timeout = setInterval(() => {
              this.handleTimeUpdate();
            }, 1000);
          }
          createEl() {
            const el = videojs.dom.createEl("div", {
              className: "ad-overlay",
            });
            el.innerHTML = `
            <div class="ad-overlay-content">
            Pausa publicitaria en ${this.countdown ?? 10} segundos </div>
            `;
            return el;
          }
          handleTimeUpdate() {
            if (this.countdown > 0) {
              this.countdown -= 1;
              const countdownEl = this.el().querySelector(
                ".ad-overlay-content"
              );
              countdownEl.innerHTML = `Pausa publicitaria en ${
                this.countdown ?? 10
              } segundos`;
            } else {
              this.hide();
              this.clearInterval(this.timeout);
            }
          }
          dispose() {
            this.clearInterval(this.timeout);
            super.dispose();
          }
        }
        videojs.registerComponent("AdOverlay", AdOverlay);

        //implementa el botón para el siguiente video al final del video o al terminar la barra de video.

        const nextButtonOnSide = videojs.extend(Button, {
          constructor: function () {
            Button.apply(this, arguments);
            this.addClass("next-button-side");
          },
          handleClick: function () {
            handleActionNext();
          },
        });
        videojs.registerComponent("nextButtonOnSide", nextButtonOnSide);
        player.on("timeupdate", () => {
          const time = player.currentTime().toFixed();
          const videoDuration = player.duration();
          const timeToShowButton = videoDuration - 5;
          if (videoDuration !== 0) {
            // if (time >= timeToShowButton) {
            //   if (!!player.getChild("nextButtonOnSide") === false) {
            //     player.addChild("nextButtonOnSide", {});
            //   }
            // } else {
            //   player.removeChild("nextButtonOnSide", {});
            // }
          }
        });

        //botón de descarga
        const buttonDownload = videojs.extend(Button, {
          constructor: function () {
            Button.apply(this, arguments);
            this.addClass("vjs-download-button");
          },
          handleClick: function () {
            if (currentUser) {
              if (currentUser?.id_membership !== 1) {
                handleDownloadCancel();
                videoSource.length > 0 &&
                  handleButtons(`${t("Video.modals.download")}`, {
                    showCancelButton: true,
                    cancelText: `${t("Home.Filter.Filter_button.Deny")}`,
                    html: `${t("Video.modals.progress")}<strong>0%</strong>`,
                    didOpen: (toast) => {
                      downloadVideo(
                        videoSource,
                        (progress) => {
                          // console.log("llega el porcentaje aqui?", progress);
                          Swal.update({
                            html: `${t("Video.modals.progress")}<strong>${progress}%</strong>`,
                          });
                          if (progress === 100) {
                            setTimeout(() => {
                              Swal.close();
                            }, 1500);
                          }
                        },
                        downloadRef.current.token
                      );
                    },
                    icon: "info",
                  }).then((resp) => {
                    // console.log('que devuelve resp?', resp);
                    if (resp.isDismissed && resp.dismiss === "cancel") {
                      //  console.log("llegue aqui?");
                      if (downloadRef.current) {
                        // console.log('Se descargo exitosamente, entro aqui?',downloadRef.current)
                        Swal.fire(
                          `${t("Video.modals.cancelDownload")}`,
                          `${t("Video.modals.cancelDownload")}`,
                          "error"
                        );
                        downloadRef.current.cancel(
                          `${t("Video.modals.description_CancelD")}`
                        );
                      }
                    }
                  });

                player.getChild("ControlBar").removeChild("buttonDownload", {});
              } else {
                Swal.fire({
                  title: `${t("SuscriptionClub.Subscribe")}`,
                  text: `${t("Video.pro")}`,
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonText: `${t("Video.subme")}`,
                  cancelButtonText: `${t("Video.keepFree")}`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/planessocios");
                  }
                });
              }
            } else {
              
              Swal.fire({
                title: `${t("Video.modals.signIn")}`,
                text: `${t("Video.modals.signIn_Description")}`,
                icon: "info",
                showCancelButton: true,
                confirmButtonText: `${t("Header.signIn")}`,
                cancelButtonText: `${t("Home.Filter.Filter_button.Deny")}`,
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/login");
                }
              });
            }
          },
        });
        videojs.registerComponent("buttonDownload", buttonDownload);
        player.on("timeupdate", () => {
          if (adsEnabled === true) {
            if (!!player.ads.isInAdMode() && !isVideoLive) {
              if (
                !!player.getChild("ControlBar").getChild("buttonDownload") ===
                true
              ) {
                player.getChild("ControlBar").removeChild("buttonDownload", {});
              }
            } else {
              if (
                !!player.getChild("ControlBar").getChild("buttonDownload") ===
                false
              ) {
                player.getChild("ControlBar").addChild("buttonDownload", {});
              }
            }
          } else if (!isVideoLive) {
            if (
              !!player.getChild("ControlBar").getChild("buttonDownload") ===
              false
            ) {
              player.getChild("ControlBar").addChild("buttonDownload", {});
            }
          }
        });

        //utilización de las velocidades del video
        player.playbackRates([0.5, 1, 1.5, 2, 2.5]);
        // manipulación con teclas

        player.on("keydown", (e) => {
          const playerVolume = player.volume();
          const playerCurrentTime = player.currentTime();
          switch (e.code) {
            case "Space":
              if (player.paused()) {
                player.play();
              } else {
                player.pause();
              }
              break;
            case "ArrowRight":
              player.currentTime(playerCurrentTime + 10);
              break;
            case "ArrowLeft":
              player.currentTime(playerCurrentTime - 10);
              break;
            case "ArrowUp":
              player.volume(playerVolume + 0.1);
              break;
            case "ArrowDown":
              player.volume(playerVolume - 0.1);
              break;
            case "KeyM":
              player.volume(0);
              break;
            default:
              return;
          }
        });
      }
    } catch (e) {
      console.error(
        "Ha ocurrido un error al iniciar el componente",
        e.name,
        e.message,
        "esto ha ocurrido aqui:",
        e.stack
      );
    }
  }, [
    options,
    adsEnabled,
    enableSprites,
    adsVideoPlay,
    watchAds,
    playerRef,
    adsStarted.current,
    pre_rollRef.current,
    mid_rollRef.current,
    post_rollRef.current,
  ]);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  const handleButtons = (type, values) => {
    const {
      icon,
      timer,
      html,
      didOpen,
      preDeny,
      showCancelButton,
      cancelText,
    } = values;
    return ToastSwal({
      icon: icon,
      title: type,
      positions: "bottom-end",
      timer: timer,
      html: html,
      didOpen: didOpen,
      cancelText: cancelText,
      showButtonCancel: showCancelButton,
      preDeny,
      timeProgressBar: true,
    });
  };

  // const handleSkippedAds = () => {
  //   if (pre_rollRef.current === false) {
  //     pre_rollRef.current = true;
  //     adsStarted.current = false;
  //     playerRef.current.ads.endLinearAdMode();
  //     setAddPresentation(false);
  //   } else if (mid_rollRef.current === false) {
  //     mid_rollRef.current = true;
  //     adsStarted.current = false;
  //     playerRef.current.ads.endLinearAdMode();
  //     setAddPresentation(false);
  //   } else if (post_rollRef.current === false) {
  //     post_rollRef.current = true;
  //     adsStarted.current = false;
  //     playerRef.current.ads.endLinearAdMode();
  //     setAddPresentation(false);
  //   }
  // };

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const isMp4 = videoSource.endsWith(".mp4");
  return (
    <div className="video-container">
      <div className="player">
        <div
          className={`chapters ${isClicked ? "come-from-left" : "go-to-left"}`}
        ></div>
        <div data-vjs-player>
          <video
            ref={videoRef}
            preload="auto"
            className="video-js vjs-matrix vjs-big-play-centered"
            onLoadedMetadata={handleVideoRef}
            onContextMenu={handleContextMenu}
          >
            {!isMp4 ? (
              <source src={videoSource} type="application/x-mpegURL" />
            ) : (
              <source src={videoSource} type="video/mp4" />
            )}
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoJsPlayer;
