import React, { useState, useRef, useEffect, useContext } from "react";
import { Button, Col, Modal, Row, Input, SelectPicker, Toggle } from "rsuite";
import "../styles/highlights.css";
import { BsFillPlayFill, BsFillPauseFill, BsPlayFill } from "react-icons/bs";
import { RiScissorsFill, RiCloseFill } from "react-icons/ri";
import { AiOutlinePlus , AiOutlineMinus } from "react-icons/ai";
import ConfigContext from "../utils/Config";
import { createHighLightVideo } from "../utils/Api/videoApi";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RangeSlider from "./RangeSlider";
import { Slider } from "antd";
import { useTranslation } from "react-i18next";
import ToastSwal from "./toastSwal";



const CreateHightLighs = (props) => {
  const {
    closeHL,
    videoOnplay,
    token,
    cameraId,
    vidOnplay,
    openHL,
    onHandleSave,
    saveDataHL,
    flagCtx,
    adsEnable,
  } = props;

  let {
    to: toContext,
    description: descriptionContext,
    category: categoryContext,
    isPrivate: isPrivateCtx,
  } = saveDataHL;
  const { currentConfig } = useContext(ConfigContext);
  const [t] = useTranslation("global");

  const { current } = vidOnplay;

  const [previewVideo, setPreviewVideo] = useState(false);
  const [valueHL, setValueHL] = useState({
    from: 0,
    to: 0,
  });
  const [currentT, setCurrentT] = useState(0);
  const [openModalDescHL, setOpenModalDescHL] = useState(false);
  const [durationT, setDurationT] = useState(0);
  const [valueCT, setValueCT] = useState(0);
  const [valueCTPrev, setValueCTPrev] = useState(0);
  const [isPause, setIsPause] = useState(false);
  const [isPlayingPrev, setIsPlayingPrev] = useState(false);
  const [toValueHL, setToValueHL] = useState(60);
  const [fromValueHL, setFromValueHL] = useState(Math.round(current.currentTime()));
  const [hlData, setHlData] = useState({
    title: "",
    categoryID: 0,
    isPrivate: false,
  });

  const videoRef = useRef(null);
  const rangesl = useRef();
  const rangeHL = useRef();
  const prevrangesl = useRef();



  const schema = yup.object().shape({
    description: yup
      .string()
      .required(`${t("CreateHL.validation.req1")}`)
      .min(10, `${t("CreateHL.validation.req2")}`)
      .max(100, `${t("CreateHL.validation.req3")}`),
    category: yup
      .number()
      .typeError(`${t("CreateHL.validation.req4")}`)
      .required(`${t("CreateHL.validation.req5")}`),
  });

  useEffect(() => {
    if (current) {
      const player = current;
      if(adsEnable){
        if (current.ads?.isInAdMode() === true) {
          setTimeout(() => {
            closeHL(false);
          }, 2000);
      }
      }

      player.on("timeupdate", () => {
        let currentTime = Number(player.currentTime().toFixed());
        const duration = Number(player.duration().toFixed());
        let nHasta = currentTime + (valueHL.to - valueHL.from);
        if (Math.round(nHasta) > duration) {
          nHasta = Math.round(duration);
        }
        setValueHL({
          ...valueHL,
          from: currentTime,
        });

        setCurrentT(currentTime);
        setDurationT(duration);
      });
      if (valueCT) {
        let newValue = Math.round(valueCT);
        player.currentTime(newValue);
      }
      if (rangesl.current !== undefined) {
        rangesl.current.value.max = player.currentTime();
      }

      player.on("play", () => {
        setIsPause(false);
      });

      player.on("pause", () => {
        setIsPause(true);
      });
    }
  }, [current, valueCT]);

  useEffect(() => {
    dataSaving();
  }, [fromValueHL, toValueHL, valueHL]);

  useEffect(() => {
    if (!flagCtx) {
      current.play();
    }
  }, [saveDataHL, flagCtx]);

  useEffect(() => {
    if(openHL){
      if(current.currentTime() >= 0){
        current.play();
        setTimeout(() => {
          current.pause();
        }, 500);
      }
    }
   
  }, [])
  

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const handleClose = () => {
    closeHL(false);
    setPreviewVideo(false);
  };

  const dataSaving = () => {
    let fromDataSend;
    let toDataSend;
    if(fromValueHL > durationT || fromValueHL === durationT){
      fromDataSend = fromValueHL - 5;
    } else{
      fromDataSend = fromValueHL;
    }
    if(toValueHL > 0){
      toDataSend = toValueHL;
    }else{
      toDataSend = toValueHL + 1;
    }


    let data = {
      from: fromDataSend,
      to: toDataSend,
      description: hlData.description,
      isPrivate: hlData.isPrivate,
      category: hlData.category,
      duration: durationT,
    };

    // console.log("Que está llegando en data?",data);

    onHandleSave(data);
  };

  const handleVideoError = (err, values) => {
    const {
      icon,
      timer,
      html,
      didOpen,
      preDeny,
      progressBar,
      showCancelButton,
      cancelText,
    } = values;
    ToastSwal({
      icon: icon,
      title: err,
      positions: "bottom-end",
      timer: timer,
      html: html,
      didOpen: didOpen,
      preDeny: preDeny,
      timeProgressBar: progressBar,
      showButtonCancel: showCancelButton,
      cancelText: cancelText,
    });
  };
  const createHL = (data) => {
    let fromDataSend;
    let toDataSend;
    if(fromValueHL > durationT || fromValueHL === durationT){
      fromDataSend = fromValueHL - 5;
    } else{
      fromDataSend = fromValueHL;
    }
    if(toValueHL > 0){
      toDataSend = fromValueHL + toValueHL;
    }else{
      toDataSend = fromValueHL + toValueHL + 1;
    }


    const values = {
      tokenuser: token,
      cameraid: cameraId,
      categoria: hlData.categoryID,
      descripcion: hlData.title,
      desde: secondsToString(fromDataSend),
      hasta: secondsToString(toDataSend),
      privacidad: hlData.isPrivate,
      videopath: videoOnplay,
    };
   
    createHighLightVideo(values)
      .then((resp) => {
        if (resp) {
          handleClose();
          setTimeout(() => {
            Swal.fire({
              title: hlData.title,
              text: `${t("CreateHL.modals.text1")}`,
              icon: "success",
              footer: `${t("CreateHL.modals.text2")}  ${
                hlData.isPrivate === true ? `${t("CreateHL.modals.public")}` : `${t("CreateHL.modals.private")}`
              }`,
            });
          }, 1000);
          setHlData({
            title: "",
            categoryID: 0,
            isPrivate: false,
          });
          setValueHL([0, 0]);
          reset();
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const secondsToString = (seconds) => {
    const hour = Math.floor(seconds / 3600);
    const strhour = hour < 10 ? "0" + hour : hour;
    const minute = Math.floor((seconds / 60) % 60);
    const strminute = minute < 10 ? "0" + minute : minute;
    const second = seconds % 60;
    const strsecond = second < 10 ? "0" + second : second;
    return strhour + ":" + strminute + ":" + strsecond;
  };

  const handleClosePreviewVideo = () => {
    setPreviewVideo(false);
  };
  const handleCloseDescHL = () => {
    setOpenModalDescHL(false);
  };

  const tooglePlayingPrev = () =>{

    if(videoRef.current?.paused && videoRef.current?.currentTime >= 0){
      setIsPlayingPrev(true);
    }else{
      setIsPlayingPrev(false);
    }
  }

  const fromValue = fromValueHL;

  const toValue = fromValueHL + toValueHL;
  const result = toValue - fromValue;

  return (
    current && (
      <>
        
          <Row className="flex flex-col h-auto">
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="py-6">
              <p className="text-lg font-semibold text-left text-zinc-900">
              {t("CreateHL.create")}
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="flex items-center justify-center py-6 "
            >
              <p className="text-lg font-semibold text-center text-zinc-900">
              {t("CreateHL.select")}
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="flex items-center justify-center"
            >
              <p className="text-lg font-semibold text-left text-zinc-900">
                {secondsToString(valueHL.from)}
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="flex items-center justify-between w-full mt-2"
            >
              <Button
                className="bg-black rounded-full ring-2 ring-red-500 hover:ring-white hover:text-white"
                disabled={current.currentTime() === 0 ? true : false}
                onClick={() => {
                  let nhasta = current.currentTime()

                  if(nhasta <= 0 ){
                    nhasta = 0;
                  }else{
                    nhasta = nhasta  - 1 
                  }

                  current.currentTime(nhasta);
                  setFromValueHL(Math.round(nhasta))
                }}
              >
              <AiOutlineMinus size={25} className="text-red-500 hover:text-white" />
              </Button>
              {current !== null && (
                <Slider
                  className="w-full"
                  max={Math.round(durationT)}
                  defaultValue={current.currentTime()}
                  value={current.currentTime()}
                  step={1}
                  tooltip={{ open: false }}
                  onChange={(e) => {
                    current.currentTime(e);
                    
                    // setValueHL({
                    //   from: e,
                    // });
                    setFromValueHL(e);
                  }}
                  trackStyle={{
                    backgroundColor:"#FF0000",
                    paddingTop:5,
                    paddingBottom:5,
                    borderRadius:6,
                  }}
                  handleStyle={{
                   width:"20px !important",
                   height:"20px !important",
                   backgroundColor:"#FF0000 !important",
                   boxShadow:"0 0 2px #ff0000",
                   bottom: "2px",
                   
                  }}
                  railStyle={{
                    backgroundColor:"#000000",
                    paddingTop:5,
                    paddingBottom:5,
                    borderRadius:6,
                  }}
                />
              )}
              <Button
                className="bg-black rounded-full ring-2 ring-red-500 hover:ring-white hover:text-white"
                disabled={current.duration() === current.currentTime() ? true : false}
                onClick={() => {
                  let nhasta = current.currentTime();
                  if (nhasta > durationT) {
                  }else {
                    nhasta = nhasta + 1;
                  }

                  current.currentTime(nhasta);
                  setFromValueHL(Math.round(nhasta));
                }}
              >
                 <AiOutlinePlus size={25} className="text-red-500 hover:text-white" />
              </Button>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="flex items-center justify-center py-6"
            >
              <p className="text-lg font-semibold text-center text-zinc-900">
              {t("CreateHL.selectDuration")}
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="flex items-center justify-center"
            >
              <p className="text-lg font-semibold text-left text-zinc-900">
                {secondsToString(toContext > 0 ? toContext : toValueHL)}
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="flex items-center justify-center"
            >
              <span className="text-sm font-semibold text-left text-zinc-900">
              {t("CreateHL.duration")}
              </span>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="flex items-center justify-between w-full mt-2"
            >
              <Button
                className="bg-black rounded-full ring-2 ring-red-500 hover:ring-white hover:text-white"
                disabled={toValueHL === 0 ? true : false}
                onClick={() => {
                  if (toValueHL <= 0) {
                    setToValueHL(toValueHL);
                  } else {
                    setToValueHL(toValueHL - 1);
                  }
                }}
              >
                  <AiOutlineMinus size={25} className="text-red-500 hover:text-white" />
              </Button>
              {current !== null && (
                 <Slider
                 className="w-full"
                 max={120}
                 defaultValue={toContext > 0 ? toContext : valueHL.from - valueHL.to}
                 value={toContext > 0 ? toContext : toValueHL}
                 step={1}
                 tooltip={{ open: false }}
                 onChange={(e) => {
                 
                    setToValueHL(e);
                  
                 }}
                 trackStyle={{
                   backgroundColor:"#FF0000",
                   paddingTop:5,
                   paddingBottom:5,
                   borderRadius:6,
                 }}
                 handleStyle={{
                  width:"20px !important",
                  height:"20px !important",
                  backgroundColor:"#FF0000 !important",
                  boxShadow:"0 0 2px #ff0000",
                  bottom: "2px",
                  
                 }}
                 railStyle={{
                   backgroundColor:"#000000",
                   paddingTop:5,
                   paddingBottom:5,
                   borderRadius:6,
                 }}
               />
                
              )}
              <Button
                className="w-auto bg-black rounded-full ring-2 ring-red-500 hover:ring-white hover:text-white"
                disabled={toValueHL === 120? true : false}
                onClick={() => {
                  if (toValueHL === 120) {
                    setToValueHL(toValueHL);
                  } else {
                    setToValueHL(toValueHL + 1);
                  }
                }}
              >
               <AiOutlinePlus size={25} className="text-red-500 hover:text-white" />
              </Button>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="flex items-center justify-center py-6"
            >
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="flex items-center justify-center w-full"
              >
               
                <Button
                  className="mx-5 text-base font-medium text-center text-red-500 rounded-md ring-2 ring-red-600 hover:ring-white hover:text-white hover:opacity-90 hover:bg-red-500"
                  onClick={() => {
                    setOpenModalDescHL(true);
                  }}
                >
                  <RiScissorsFill size={20} className="mr-3" />
                  {t("CreateHL.buttons.create")}
                </Button>
              </Col>
            </Col>
          </Row>

        <Modal
          open={previewVideo}
          onClose={handleClosePreviewVideo}
          className=" mobile:w-full mobile:h-full tablet:w-full tablet:h-full desktop:w-3/4 desktop: h-3/4"
        >
          <Modal.Header>
            <p className="text-lg font-semibold text-left text-zinc-900">
            {t("CreateHL.buttons.preview")}
            </p>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} className="mb-3 ">
                  <div className="font-medium text-center text-black">
                  {t("CreateHL.since")}
                  </div>
                  <div className="font-light text-center text-red-600">
                    {secondsToString(fromValueHL)}
                  </div>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <div className="font-medium text-center text-black">
                  {t("CreateHL.durat")}
                  </div>
                  <div className="font-light text-center text-red-600">
                    {secondsToString(result)}
                  </div>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <div className="font-medium text-center text-black">
                  {t("CreateHL.to")}
                  </div>
                  <div className="font-light text-center text-red-600">
                    {secondsToString(toValue)}
                  </div>
                </Col>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <video
                  ref={videoRef}
                  className={"bg-slate-800 w-full h-full rounded-md relative"}
                  controls={false}
                  controlsList="nodownload"
                  autoPlay={false}
                  playsInline={true}
                  src={videoOnplay}
                  onTimeUpdate={(e) => {
                    setValueCTPrev(e.target.currentTime);
                    if(e.target.currentTime >= toValue){
                      e.target.pause();
                      e.target.currentTime = fromValueHL
                    }
                  }}
                  onLoadedData={() => {
                    if (fromValue >= 0) {
                      videoRef.current.currentTime = fromValueHL //establecer en milisegundos;
                    }
                    if (videoRef.current.currentTime === toValue) {
                      videoRef.current.pause();
                      videoRef.current.currentTime = fromValueHL
                    }
                  }}
                />
                <div
                  className="absolute flex flex-row justify-between w-11/12 left-4 item-center"
                  style={{
                    bottom: "10px",
                  }}
                >
                  <div className="flex items-center justify-center ">
                    <Button
                      className="bg-black ring-2 ring-red-500 hover:ring-white"
                      onClick={() => {
                        if (videoRef.current?.paused && videoRef.current?.currentTime >= 0) {
                          videoRef.current?.play();
                        } else {
                          videoRef.current?.pause();
                        }
                      }}
                    >
                      { videoRef.current?.currentTime === 0 || videoRef.current?.paused  ? (
                        <BsFillPlayFill className="text-red-600 hover:text-white" />
                      ) : videoRef.current?.play ? (
                        <BsFillPauseFill className="text-red-600 hover:text-white" />
                      ):(
                        <BsFillPlayFill className="text-red-600 hover:text-white" />
                      )}
                    </Button>
                  </div>
                  <div className="flex items-start w-4/5 mr-12 ">

                  <Slider
                  className="w-full"
                 min={fromValueHL}
                  max={toValue}
                  value={valueCTPrev}
                  step={1}
                  tooltip={{ open: false }}
                  onChange={(e) =>{
                   let valueInitial = e ;
                   videoRef.current.currentTime = valueInitial ;
                   setValueCTPrev(valueInitial);
                  }}
                  trackStyle={{
                    backgroundColor:"#FF0000",
                    paddingTop:5,
                    paddingBottom:5,
                    borderRadius:6,
                  }}
                  handleStyle={{
                   width:"20px !important",
                   height:"20px !important",
                   backgroundColor:"#FF0000 !important",
                   boxShadow:"0 0 2px #ff0000",
                   bottom: "2px",
                   
                  }}
                  railStyle={{
                    backgroundColor:"#000000",
                    paddingTop:5,
                    paddingBottom:5,
                    borderRadius:6,
                  }}
                />
                  </div>
                  <div className="flex items-center w-60 ">
                    <p className="pl-4 font-semibold text-center text-red-600 ">{secondsToString(Math.round(videoRef.current?.currentTime ?? 0))} / {secondsToString(toValue)}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="flex flex-row items-center justify-center my-3"
            >
              <Button
                className="w-1/3 mx-4 text-base font-medium text-center text-red-500 rounded-md ring-2 ring-red-600 hover:ring-white hover:text-white hover:opacity-90 hover:bg-red-500"
                onClick={() => {
                  setPreviewVideo(false);
                  // current.play();
                }}
              >
                <RiCloseFill size={20} className="mr-3" /> 
                {t("CreateHL.buttons.closePreview")}
              </Button>
             
            </Col>
          </Modal.Footer>
        </Modal>
        <Modal
          open={openModalDescHL}
          onClose={handleCloseDescHL}
          className="w-full h-full tablet:max-2kScreen:h-3/4"
        >
          <Modal.Body>
            <Row className="">
                <Col xs={24} sm={24} md={24} lg={24} className="w-full mt-3 mb-5 ml-2 tablet:max-2kScreen:ml-28">
              <Button
                  className="text-base font-medium text-center text-red-500 rounded-md ring-2 ring-red-600 hover:ring-white hover:text-white hover:opacity-90 hover:bg-red-500"
                  onClick={() => {
                    setPreviewVideo((prev) => !prev);
                    // current.pause();
                  }}
                >
                  <BsPlayFill size={20} className="mr-3" />
                  {previewVideo ?   t("CreateHL.buttons.closePreview"):   t("CreateHL.buttons.preview")}
                </Button>

                </Col>
              <Col xs={24} sm={24} md={18} lg={18} xl={18} className="mb-4 tablet:max-2kScreen:ml-28">
                <Controller
                  control={control}
                  name="description"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      type="text"
                      placeholder={  t("CreateHL.title")}
                      value={descriptionContext.length >0 ? descriptionContext :  field.value}
                      onChange={(e) => {
                        setHlData({
                          ...hlData,
                          title: e,
                        });

                        field.onChange(e);
                      }}
                      style={{
                      borderColor: errors.description?.message ? 'red' : null
                      }}
                    />
                  )}
                />
                <div className="flex flex-row items-center justify-between">
                  <span className="text-sm font-normal text-left text-red-600 ">
                    {errors.description?.message ??   t("CreateHL.required")}
                  </span>
                  <span className="text-base font-medium text-left ">
                    {hlData.title.length} / 100
                  </span>
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={18}
                lg={18}
                xl={18}
                className="flex flex-row items-center justify-between tablet:max-2kScreen:ml-28"
              >
                <Controller
                  control={control}
                  name="category"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <SelectPicker
                      placeholder={t("CreateHL.selectCat")}
                      searchable={false}
                      value={categoryContext.length > 0 ? categoryContext :  field.value}
                      data={
                        currentConfig?.categoriashl.map((item) => {
                          return {
                            label: item.category,
                            value: item.id,
                          };
                        }) || []
                      }
                      className="w-auto tablet:max-2kScreen:w-1/2"
                      onChange={(e) => {
                        setHlData({
                          ...hlData,
                          categoryID: e,
                        });
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                <div>
                  <Toggle
                    className="mr-2"
                    checkedChildren={t("CreateHL.modals.private")}
                    unCheckedChildren={t("CreateHL.modals.public")}
                    checked={hlData.isPrivate}
                    onChange={(e) => {
                      setHlData({
                        ...hlData,
                        isPrivate: e,
                      });
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-4 tablet:max-2kScreen:ml-28">
                <div>
                  <span className="text-sm font-normal text-left text-red-600 ">
                    {errors.category?.message ?? t("CreateHL.required")}
                  </span>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="flex flex-row items-center justify-center my-3"
            >
              <Button
                className="w-1/3 mx-4 text-base font-medium text-center text-red-500 rounded-md ring-2 ring-red-600 hover:ring-white hover:text-white hover:opacity-90 hover:bg-red-500"
                onClick={() => {
                  setOpenModalDescHL(false);
                }}
              >
                <RiCloseFill size={20} className="mr-3" /> {t("CreateHL.buttons.cancel")}
              </Button>
              <Button
                className="w-1/3 mx-4 text-base font-medium text-center text-red-500 rounded-md ring-2 ring-red-600 hover:ring-white hover:text-white hover:opacity-90 hover:bg-red-500"
                onClick={handleSubmit(createHL)}
              >
                <RiScissorsFill size={20} className="mr-3" />
                {t("CreateHL.buttons.createC")}
              </Button>
            </Col>
          </Modal.Footer>
        </Modal>
      </>
    )
  );
};

export default CreateHightLighs;
