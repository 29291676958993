import axios from 'axios';
import {saveAs } from 'file-saver';
import React from 'react';
import Swal from 'sweetalert2';
import ToastSwal from '../../components/toastSwal';





const handleButtons = (type, values) => {
    const { icon, timer,html,didOpen } = values;
    ToastSwal({
      icon: icon,
      title: type,
      positions: "bottom-end",
      timer: timer,
      html:html,
      didOpen: didOpen,
      timeProgressBar: false,
    });
};

let  percentageValue:number = 0;

export const downloadVideo = async (url:string,onProgress:any, cancelToken:any) =>{
    
    try {
    const response = await axios.get(url,{
        responseType: 'blob',
        onDownloadProgress:(progressEvent) =>{
            const {loaded , total} = progressEvent;
            percentageValue = Math.floor((loaded/total)*100);
            onProgress(percentageValue);
        },
        cancelToken,
    });


        const fileName = url.split('/').pop();
        if(response.status === 200){
            handleButtons("Descarga realizada con exito!",{icon:"success",timer:3000})
        }
        saveAs(response.data, fileName);
        return response;
    } catch (error) {
        if(axios.isCancel(error)){
            console.error("Error al descargar el video",error);
        }else{
            console.error("Error al descargar el video",error);

        }
    }
}