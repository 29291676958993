import Swal from "sweetalert2";

enum positions {
  "top",
  "top-start",
  "top-end",
  "center",
  "center-start",
  "center-end",
  "bottom",
  "bottom-start",
  "bottom-end",
}
enum iconsMessage {
  "info",
  "success",
  "warning",
  "error",
}

interface ToastProps {
  timer?: number;
  positions: positions | any;
  timeProgressBar?: boolean;
  didOpen?: () => void;
  willOpen?: () => void;
  preDeny?: () => void;
  didClose?: () => void;
  icon: iconsMessage | any;
  title: string;
  html?: any;
  showButtonCancel?: boolean;
  showButtonConfirm?: boolean;
  cancelText?: string;
  confirmText?: string;
}

const ToastSwal = async (props: ToastProps) => {
  const {
    timer,
    positions,
    timeProgressBar,
    didOpen,
    preDeny,
    didClose,
    html,
    icon,
    title,
    showButtonConfirm,
    showButtonCancel,
    cancelText,
    confirmText,
  } = props;

  const Toast = Swal.mixin({
    toast: true,
    position: positions,
    showConfirmButton: showButtonConfirm,
    confirmButtonText: confirmText,
    showCancelButton: showButtonCancel,
    cancelButtonText: cancelText,
    timer: timer,
    timerProgressBar: timeProgressBar,
    willOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  return Toast.fire({
    title: title,
    icon: icon,
    html: html,
    didOpen,
    preDeny,
    didClose,
  });
};

export default ToastSwal;
