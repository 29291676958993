import React from "react";
import axios from "axios";
import { API_URL, API_URL_LOCAL_DEV, API_URL_DEV } from "../../env";
import { Get, Post } from "../consumir";

export const uploadProfileImage = async (file: any) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await Post(`${API_URL}/upload`, formData);

    const status = response.status;
    if (status !== 200 && status !== 201) {
      console.log("ERROR NETWORK CONNECTION UPLOAD FILE", status);
    }
    return response;
  } catch (error) {
    console.log("ERROR NETWORK CONNECTION POST UPLOAD FILE", error);
  }
};
