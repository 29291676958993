import { faCancel, faContactCard, faMoneyBillTransfer, faPause, faPersonCircleQuestion, faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export function Faq() {
  const [t] = useTranslation("global");
  return (
    <>
      <div className='container'>
        <div className="mt-5 grid-container-dos border-top">
          <div className="mt-5 row">
            <h1 className="text-center card-title display-2 fw-bold">{t("FAQ.Title1")}</h1>
            <p className="mt-5 text-center">{t("FAQ.subTitle")}</p>
          </div>

          <div className='row'>
            <div className='col-md-3 div-cuadro-uno'>
              <div className='row'>
                <div className='col div-cuadro-icono'>
                  <FontAwesomeIcon icon={faVideoCamera} />
                </div>
              </div>
              <h5 className="div-label-cuadro">{t("FAQ.HowStartTransmition")}</h5>
              <p className="text-center">{t("FAQ.HowEndTransmition_Description")}</p>
            </div>
            <div className='col-1'></div>
            <div className='col-md-3 div-cuadro-uno'>
              <div className='row'>
                <div className='col div-cuadro-icono'>
                  <FontAwesomeIcon icon={faPause} />
                </div>
              </div>
              <h5 className="div-label-cuadro">{t("FAQ.HowStopTransmition")}</h5>
              <p className="text-center">{t("FAQ.HowStopTransmition_Description")}</p>
            </div>
            <div className='col-1'></div>
            <div className='col-md-3 div-cuadro-uno'>
              <div className='row'>
                <div className='col div-cuadro-icono'>
                  <FontAwesomeIcon icon={faContactCard} />
                </div>
              </div>
              <h5 className="div-label-cuadro">{t("FAQ.HowDoCatchClub")}</h5>
              <p className="text-center">{t("FAQ.HowDoCatchClub_Description.p1")}<Link to="/contacto">{t("FAQ.HowDoCatchClub_Description.p2")}</Link>{t("FAQ.HowDoCatchClub_Description.p3")}</p>
              <p className="text-center">{t("FAQ.HowDoCatchClub_Description.p4")}<Link to="/planesclubes">{t("FAQ.HowDoCatchClub_Description.p5")}</Link></p>
            </div>
            <div className="col-1"></div>
          </div>
          <div className='mt-3 row'>

            <div className='col-md-3 div-cuadro-uno'>
              <div className='row'>
                <div className='col div-cuadro-icono'>
                  <FontAwesomeIcon icon={faMoneyBillTransfer} />
                </div>
              </div>
              <h5 className="div-label-cuadro">{t("FAQ.WhatsPayingMethods")}</h5>
              <p className="text-justify ">{t("FAQ.WhatsPayingMethods_Description")}</p>
            </div>
            <div className='col-1'></div>
            <div className='col-md-3 div-cuadro-uno'>
              <div className='row'>
                <div className='col div-cuadro-icono'>
                  <FontAwesomeIcon icon={faCancel} />
                </div>
              </div>
              <h5 className="div-label-cuadro">{t("FAQ.HowCancelSuscription")}</h5>
              <p className="text-justify">{t("FAQ.HowCancelSuscription_Description")}</p>
            </div>
            <div className='col-1'></div>
            <div className='col-md-3 div-cuadro-uno'>
              <div className='row'>
                <div className='col div-cuadro-icono'>
                  <FontAwesomeIcon icon={faPersonCircleQuestion} />
                </div>
              </div>

              <h5 className="div-label-cuadro">{t("FAQ.SupportClub")}</h5>
              <p className="text-justify">{t("FAQ.SupportClub_Description")}</p>
            </div>
            <div className='col-1'></div>
          </div>
        </div>

        <div className="mt-5 grid-container-dos border-top">
          <div className="mt-5 row">
            <h1 className="text-center display-2 fw-bold">{t("FAQ.Title2")}</h1>
          </div>
          <div className="row">
            <div className="col div-cuadro-uno">
              <h5 className="mt-3 text-center display-6">{t("FAQ.subTitle2")}</h5>
              <h5 className="mt-3 text-center display-6">{t("FAQ.subTitle3")}</h5>
              <h5 className="mt-3 text-center display-6">{t("FAQ.contactUs.p1")} <a href={t("FAQ.contactUs.link")}>{t("FAQ.contactUs.link")}</a></h5>
              <p className="mt-3 text-center display-6">{t("FAQ.contactUs.p2")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
