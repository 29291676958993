import React from "react";
import { API_URL, API_URL_LOCAL_DEV, API_URL_DEV } from "../../env";
import { Get, Post } from "../consumir";

export const updateStatusUser = async (id: number) => {

    try {
        const response = Get(API_URL + `/tokens/subStatus/${id}`);
        return response;
        
    } catch (error) {
        console.error("ERROR NETWORK CONNECTION UPDATE STATUS USER",error);
    }
};

export const getDataUser = async (token: string) => {
  try {
    const response = await Post(API_URL + "/login/getDataClient", { token });
    return response;
  } catch (error) {
    console.error(
      "ERROR NETWORK CONNECTION GET DATA USER: " + JSON.stringify(error)
    );
  }
};

export const updateDataUser = async (values: any) => {
  console.log(" que llega en values?", values);
  const response = await Post(`${API_URL}/login/updateDataClient/`, values);

  const status = response.status;

  if (status !== 200) {
    console.error("ERROR NETWORK CONNECTION UPDATE USER", response);
  }

  return response;
};
