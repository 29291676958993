import React from "react";
import { WhatsappIcon } from "react-share";
import { Button, Tooltip, Whisper } from "rsuite";
import './whatsapp.css';

type Props = {
    children: React.ReactNode; // 👈️ type children
  };

export function WhatsAppBubble(prop:Props) {
    const [show, setShow] = React.useState(false);
    const number = "573003000000";
    const message = "Hola, le hablo desde SportPro.tv!";

    return (
        <div style={{
            position:"relative"
        }}>
            {
                show?
            <div className="faketooltip">
                <label className="tttext">Te podemos ayudar, contáctanos.</label>
            </div>:<></>
            }
            <div className="whatsappbubble">
                    <Button className="whatsappbutton" 
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}
                    onClick={()=>{
                        window.open("https://wa.link/bdp0w2", "_blank");
                    }}>
                        <WhatsappIcon size={48} round />
                    </Button>
            </div>
            <div>
                {prop.children}
            </div>
        </div>
        
    );
}

