import {
  faCut,
  faLink,
  faLock,
  faLockOpen,
  faMinusCircle,
  faPause,
  faPlay,
  faPlusCircle,
  faScissors,
  faShareNodes,
  faThumbsDown,
  faThumbsUp,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "lottie-react";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import {
  Button,
  Divider,
  Form,
  Input,
  SelectPicker,
  Tag,
  TagGroup,
  Toggle,
  Whisper,
  IconButton,
  ButtonGroup,
} from "rsuite";
import Swal from "sweetalert2";
import loaderVideo from "../animations/loaderVideo.json";
import { CardPVVideo } from "../components/cardpvvideo";
import { CarouselBanner, CarouselContext } from "../components/CarouselBanner";
import CreateHightLighs from "../components/createHighLights";
import RangeSlider from "../components/RangeSlider";
import ToastSwal from "../components/toastSwal";
import VideoJsPlayer from "../components/videoJS";
import { language, Textos } from "../texto";
import { getAllSponsors } from "../utils/Api/sponsorApi";
import axios from "axios";
import {
  countedVideoScreen,
  getRankingTop10Videos,
  getTaggedUserOnVideo,
  getVideoOnPlay,
} from "../utils/Api/videoApi";
import AuthContext from "../utils/Auth";
import ConfigContext from "../utils/Config";
import { Get, GetToken, Post, Put } from "../utils/consumir";
import {
  ProcesarDuracion,
  filterAdsImgBvideos,
  filterAdsVideos,
} from "../utils/utilidades";
import "./video.css";
import { API_URL, APP_TOKEN_SPORTPRO } from "../env";
import { SaveDataHLProvider } from "../context/saveHLInfo";
import { RiVideoDownloadLine, RiMapPinUserLine } from "react-icons/ri";
import { downloadVideo } from "../utils/Api/downloadVideo";
import { useTranslation } from "react-i18next";
import {
  RiThumbUpLine,
  RiThumbDownLine,
  RiThumbUpFill,
  RiThumbDownFill,
} from "react-icons/ri";
import {
  Tooltip,
  AutoComplete,
  Input as InputAntd,
  Skeleton,
  Space,
} from "antd";
import {
  getLikeVideo,
  removeDislike,
  removeLike,
  updateDislike,
  updateLike,
  voteDislike,
  voteLike,
} from "../utils/Api/likesApi";
import {
  deleteTagUserByName,
  getPredictiveUser,
  updateTagsUsers,
} from "../utils/Api/taggedUserApi";
const URL_API: any = API_URL;
const tokenUser: any = APP_TOKEN_SPORTPRO;

export function ViewVideo() {
  const location = useLocation();
  const [t, i18n] = useTranslation("global");
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const { currentUser } = useContext(AuthContext);
  const { saveDataHL, addSaveHLData } = useContext(SaveDataHLProvider);

  //HOOKS USE STATES
  const navigate = useNavigate();
  const [getIdVideo, setGetIdVideo] = useState<string>("");
  const [videoPlayerScreen, setVideoPlayerScreen] = useState<string>("");
  const [isLive, setIsLive] = useState<boolean>(false);
  const [videoProps, setVideoProps] = useState<any>({
    cameraData: "",
    clubDataProps: {},
    description: "",
    disLike: 0,
    endTime: "",
    id: "",
    idCamera: 0,
    idEvent: "",
    idHightLightCategory: "",
    idPlayer: 0,
    initialTime: "",
    isHighLight: false,
    isRecorded: false,
    like: 0,
    photoPath: "",
    playingTime: 0,
    sportDataProps: {},
    streamingUrl: "",
    taggedPlayers: "",
    userDataProps: {},
    viewers: 0,
  });
  const [openModal, setOpenModal] = useState(false);
  const [imagesAds, setImagesAds] = useState([]);
  const [playListVideo, setPlayListVideo] = useState<any>([]);
  const [watcher, setWatcher] = useState(0);
  const [playingNow, setPlayingNow] = useState(null);
  const [optionsPlayers, setOptionsPlayers] = useState([]);
  const [liked, setliked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [videosAds, setVideosAds] = useState<any[]>([]);
  const [taggedUsers, setTaggedUsers] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [taggednewuser, setTaggednewuser] = useState("");
  const [ready, setready] = useState<boolean>(false);
  const [savingHL, setSavingHL] = useState<boolean>(false);
  const [othervideo, setOthervideo] = useState<any[]>([]);
  const { currentConfig, GetConfig } = useContext(ConfigContext);
  const { GetTokenLogin } = useContext(AuthContext);
  const [videojsRef, setVideojsRef] = useState<any>(null);
  const [modalTags, setmodalTags] = useState<boolean>(false);
  const [isDownloadFinished, setIsDownloadFinished] = useState(false);
  const [valuesSaving, setValuesSaving] = useState({});
  const [arrayUsers, setarrayUsers] = useState<Array<any>>([]);
  const [loadingTags, setLoadingTags] = useState(false);

  //REF DE VIDEOS
  const cancelDownload = useRef<any>();

  const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));
  const titleshare = `${t("Video.titleShare")}`;

  //HOOKS EFFECTS

  useEffect(() => {
    if (id !== undefined) {
      getVideoOnScreen();
      getBestVideos();
    }
  }, [id]);

  useEffect(() => {
    if (id !== undefined) {
      getLikeOrDislikeOnvideo();
    }
  }, [id, liked, disliked]);

  useEffect(() => {
    getSponsors();
    getSponsorsImages();
  }, []);

  useEffect(() => {
    if (videoProps !== undefined) {
      setLoadingTags(true);
      taggedUsersOnVideo();
    }
  }, [videoProps]);

  useEffect(() => {
    addSavedHLData();
  }, [savingHL]);

  useEffect(() => {
    if (id !== videoProps.id) {
      // console.log('Entra al efecto?');
      videoReproductionCounter(id);
      setready(true);
      getVideoOnScreen();
    }
  }, [id]);

  // console.log("videoPROPS",videoProps);
  //!manipulamos el cambio del video Otra funcionalidad
  // useEffect(() => {
  //   if (playListVideo.length !== 0) {
  //     setPlayingNow(playListVideo[watcher].src);
  //   }
  //   // Proximamente ...
  // }, [watcher, playListVideo]);

  //!Consultamos si el usuario le dio like o dislike anteriormente al video.
  const getLikeOrDislikeOnvideo = () => {
    const videoId = id;
    const token = GetTokenLogin();

    if (id.length > 0) {
      if (token && id) {
        getLikeVideo(videoId, token)
          .then((resp: any) => {
            const data = resp.data;
            const result = data.userVote;
            // console.log("resultado al traer el like",resp);
            if (result === null) {
              localStorage.removeItem("likes");
              localStorage.removeItem("dislike");
            } else if (result.likes > 0) {
              localStorage.setItem("likes", result.likes);
              setliked(true);
            } else if (result.dislikes > 0) {
              localStorage.setItem("dislikes", result.dislikes);
              setDisliked(true);
            }
          })
          .catch((err: any) => {
            console.error(
              "error al consultar like del usuario",
              err.message.toString()
            );
          });
      }
    }
  };

  //!Le enviamos valor que damos like al video
  const likeThisVideo = (like: boolean) => {
    const getVote = localStorage.getItem("likes");
    const values = {
      token: GetTokenLogin(),
      videoId: videoProps.id,
      isLike: Number(getVote) === 1 ? false : like,
    };

    if (getVote === null || getVote === undefined) {
      voteLike(values)
        .then((resp: any) => {
          if (resp) {
            setliked(true);
            // if (disliked) {
            //   setliked(true);
            //   setDisliked(false);
            // }

            updateLike(values.videoId)
              .then((resp: any) => {
                // if (resp.status) {

                localStorage.setItem("likes", resp.likes);
                setVideoProps({
                  ...videoProps,
                  like: resp.likes,
                });
                // if (Number(localStorage.getItem("dislkes")) >= 0) {
                //   localStorage.removeItem("dislikes");
                //   localStorage.setItem("likes", resp.likes);
                //   setVideoProps({
                //     ...videoProps,
                //     like: resp.likes
                //   })
                // }
                // }
              })
              .catch((err: any) => {
                console.error(
                  "No se pudo actualizar los likes",
                  err.message.toString()
                );
              });
            if (Number(localStorage.getItem("dislikes")) > 0) {
              removeDislike(values.videoId).then((resp: any) => {});
            }
          }
        })
        .catch((err: any) => {
          console.error(
            "Hubo un error a darle like al video",
            err.message.toString()
          );
        });
    } else {
      voteLike(values).then((resp: any) => {
        localStorage.removeItem("likes");
        if (resp) {
          setliked(false);
          // if (resp.status === "Ok") {
          //   localStorage.removeItem("likes");
          // }
        }
        removeLike(values.videoId)
          .then((resp: any) => {
            setVideoProps({
              ...videoProps,
              like: resp.like,
            });
          })
          .catch((err: any) => {
            console.error(
              "Hubo un error en remover el like",
              err.message.toString()
            );
          });
      });
    }
  };

  //! Le enviamos valor que damos dislike al video
  const dislikeThisVideo = (dislike: boolean) => {
    const getVote = localStorage.getItem("dislikes");
    const values = {
      token: GetTokenLogin(),
      videoId: videoProps.id,
      isLike: Number(getVote) === 1 ? false : dislike,
    };

    if (getVote === null) {
      voteDislike(values)
        .then((resp: any) => {
          if (resp) {
          }
          if (liked) {
            setDisliked(true);
            setliked(false);
          }
          updateDislike(values.videoId)
            .then((resp: any) => {
              if (resp.status === false) {
                localStorage.setItem("dislikes", resp.dislikes);
              }
            })
            .catch((err: any) => {
              console.error(
                "No se pudo actualizar los dislikes",
                err.message.toString()
              );
            });
          if (Number(localStorage.getItem("likes")) > 0) {
            removeLike(values.videoId).then((resp: any) => {});
          }
        })
        .catch((err: any) => {
          console.error(
            "Hubo un error a darle dislike al video",
            err.message.toString()
          );
        });
    } else {
      voteDislike(values)
        .then((resp: any) => {
          if (resp) {
            setDisliked(false);
            if (resp.status === "Ok") {
              localStorage.removeItem("dislikes");
            }
          }
          removeDislike(values.videoId)
            .then((resp: any) => {})
            .catch((err: any) => {
              console.error(
                "Error al remover el dislike",
                err.message.toString()
              );
            });
        })
        .catch((err: any) => {
          console.error(
            "Hubo un error en remover el dislike",
            err.message.toString()
          );
        });
    }
  };

  //toast que muestra el mensaje de error de las llamadas
  const handleVideoError = (err: any, values: any) => {
    const {
      icon,
      timer,
      html,
      didOpen,
      preDeny,
      progressBar,
      showCancelButton,
      cancelText,
    } = values;
    ToastSwal({
      icon: icon,
      title: err,
      positions: "bottom-end",
      timer: timer,
      html: html,
      didOpen: didOpen,
      preDeny: preDeny,
      timeProgressBar: progressBar,
      showButtonCancel: showCancelButton,
      cancelText: cancelText,
    });
  };

  //Manipula lo que pasa en el reproductor de videoJS
  const handleVideoJsRef = (ref: any) => {
    setVideojsRef(ref);
  };

  //muestra el video en pantalla (principal)
  const getVideoOnScreen = () => {
    setready(true);
    let tokenID: any = "";
    if (id !== "" || id !== undefined) {
      tokenID = tokenUser ? tokenUser.toString() : "";

      let token_user: string = localStorage.getItem(tokenID);

      if (token_user === null || token_user === "") {
        token_user = "";
      }
      const values = {
        tokenuser: token_user,
        token: GetTokenLogin(),
      };
      getVideoOnPlay(id, values)
        .then((res: any) => {
          if (res.status === 200) {
            window.scrollTo(0, 0);
            const data: any = res.data;
            const result: any = data.data;
            setVideoProps({
              cameraData: result.cameraData,
              clubDataProps: result.clubData,
              description: result.description,
              disLike: result.dislike,
              endTime: result.endtime,
              id: result.id,
              idCamera: result.id_camera,
              idEvent: result.id_event,
              idHightLightCategory: result.id_highlightcat,
              idPlayer: result.id_player,
              initialTime: result.initialtime,
              isHighLight: result.ishighlight,
              isRecorded: result.isrecorded,
              like: result.like,
              photoPath: result.photopath,
              playingTime: result.playingtime,
              sportDataProps: result.sportData,
              streamingUrl: result.streamingurl,
              taggedPlayers: result.tagged_players,
              userDataProps: result.userdata,
              viewers: result.views,
            });
            setIsLive(result.islive);
            setVideoPlayerScreen(result.videopath);
            const newPlayerlist = [
              ...playListVideo,
              {
                id: result.id,
                src: result.videopath,
                type: "mp4",
                label: "HD",
                res: 720,
              },
            ];
            newPlayerlist.length
              ? setPlayListVideo(newPlayerlist)
              : setPlayListVideo([]);
            setIsPrivate(result.isprivate);
            setready(false);
            setTaggedUsers(result.taggedUsers);
          } else {
            return;
          }
        })
        .catch((err: any) => {
          console.error("cual es el error getVideo", err.message);
        });
    }
  };

  //trae a los sponsors para los ads
  const getSponsors = () => {
    // const videosAdsArr: any = [];
    getAllSponsors()
      .then((resp: any) => {
        const data: any = resp.data;
        const currentLanguage = i18n.language;
        const videoAdsArr = filterAdsVideos(data, currentLanguage);
        setVideosAds(videoAdsArr);
      })
      .catch((err: any) => {
        handleVideoError(err, { icon: "error", timer: 4000 });
        console.error("error video ads? ", err);
      });
  };

  //Muestra los mejores videos que tengan mayor visualización

  const getBestVideos = () => {
    if (id !== "" || id !== undefined) {
      getRankingTop10Videos(id)
        .then((resp: any) => {
          if (resp.status === 200) {
            const data: any = resp.data;
            const result: any = data.data;

            setOthervideo(result);
            if (playListVideo.length >= 0) {
              result.map((item: any) => {
                const addedPlayListArray = [
                  ...playListVideo,
                  {
                    id: item.id,
                    src: item.src,
                    type: "mp4",
                    label: "HD",
                    res: 720,
                  },
                ];
                setPlayListVideo(addedPlayListArray);
              });
            }
          }
        })
        .catch((err: any) => {
          handleVideoError(err, { icon: "error", timer: 4000 });
          console.error("cual es el error BEST10", err.message);
        });
    }
  };
  // visualización de la cantidad de vistas al video
  const videoReproductionCounter = (id: string) => {
    // console.log("entra aqui a contar las visualizaciones?");
    let tokenUs = localStorage.getItem(tokenUser.toString());
    const values = {
      token: tokenUs,
      videoId: id,
    };

    // Obtiene el registro de videos vistos del localStorage
    const viewedVideos = JSON.parse(
      localStorage.getItem("viewedVideos") || "[]"
    );

    //Comprueba si el navegador ya ha visto el video.
    const hasViewedVideo = viewedVideos.includes(id);

    //Si el navegador no ha visto el video , incrementar el contador y actualizar el registro de videos vistos
    // if (!hasViewedVideo) {
    // viewedVideos.push(id);
    // localStorage.setItem("viewedVideos", JSON.stringify(viewedVideos));

    if (tokenUs !== null) {
      // console.log("tengo token?",tokenUser);
      countedVideoScreen(values)
        .then((resp: any) => {})
        .catch((err) => {
          handleVideoError(err, { icon: "error", timer: 4000 });
          console.error("ERROR COUNTED VIDEO ", err.message);
        });
    } else {
      // console.log("no tengo usuario entro aqui");
      GetToken(URL_API)
        .then((resp: any) => {
          const data = resp.data;
          if (resp.status === 200) {
            localStorage.setItem("token", data.token);
            const values = {
              token: localStorage.getItem("token"),
              videoId: id,
            };
            countedVideoScreen(values)
              .then((resp: any) => {
                // console.log("responde el resp?",resp);
              })
              .catch((err) => {
                handleVideoError(err, { icon: "error", timer: 4000 });
                console.error("ERROR COUNTED VIDEO", err.message);
              });
          }
        })
        .catch((err: any) => {
          handleVideoError(err, { icon: "error", timer: 4000 });
          console.error("ERROR GET TOKEN", err.message);
        });
    }
    // }
  };

  const viewedVideos = JSON.parse(localStorage.getItem("viewedVideos") || "[]");

  //trae los usuarios que están etiquetados al video
  const taggedUsersOnVideo = () => {
    getTaggedUserOnVideo(id)
      .then((resp: any) => {
        if (resp.status === 200) {
          const data: any = resp.data;
          if (!data.error) {
            setLoadingTags(false);
            let result: any = data.data;
            setTaggedUsers(result);
          }
        }
      })
      .catch((err: any) => {
        console.error("hubo un error en traer los usuarios etiquetados", err);
        // handleVideoError(err, { icon: "error", timer: 4000 });
      });
  };

  //trae los sponsors imagenes
  // const getSponsorsImages = () => {
  //   const imagesArray: any[] = [];
  //   getAllSponsors()
  //     .then((resp: any) => {
  //       if (resp.status === 200) {
  //         const data: any = Array.from(resp.data);
  //         const currentLanguage = i18n.language;
  //         if (data.length > 0) {
  //           const sponsorWithImages: any = data.filter(
  //             (item: any) =>
  //               item.image_carruseltop_path !== null &&
  //               item.image_carruselvideo_path !== null &&
  //               item.countDownImages !== "" &&
  //               item.buycarrusel_videos === true &&
  //               item.status !== "NOTPAID" &&
  //               item.status !== "INACTIVE" &&
  //               item.language ===  currentLanguage ? currentLanguage :item.language === 'en'
  //           );
  //           sponsorWithImages.forEach((item: any) => {
  //             imagesArray.push({
  //               name: item.sponsor_name,
  //               url: item.sponsor_url ?? "#",
  //               timeShow:
  //                 typeof item.countDownImages !== "undefined" &&
  //                 item.countDownImages.length > 1
  //                   ? item.countDownImages[1]
  //                   : 5000,
  //               image: item.image_carruseltop_path,
  //             });
  //           });
  //         }
  //         setImagesAds(imagesArray);
  //       }
  //     })
  //     .catch((err: any) => {
  //       handleVideoError(err, { icon: "error", timer: 4000 });
  //       console.error("errores get img ads?", err.message);
  //     });
  // };
  const getSponsorsImages = () => {
    getAllSponsors()
      .then((resp: any) => {
        if (resp.status === 200) {
          const data: any = Array.from(resp.data);
          const currentLanguege = i18n.language;
          // console.log('Que trae data de sponsor API?', data);
          console.log("Current language: " + currentLanguege);
          const imgBottomVideo = filterAdsImgBvideos(data, currentLanguege);
          setImagesAds(imgBottomVideo);

          //   let sponsorWithImages = data.filter((item)=>
          //     item.image_carruseltop_path !== null &&
          //     item.image_carruselvideo_path !== null &&
          //     item.countDownImages !== "" &&
          //     item.buycarrusel_top === true &&
          //     item.status === "ACTIVE" &&
          //     item.language === currentLanguege
          //   );
          //   // si no hay imagenes para el idioma actual , filtrar por inglés
          //   if(sponsorWithImages.length === 0){
          //     sponsorWithImages = data.filter((item)=>
          //     item.image_carruseltop_path !== null &&
          //     item.image_carruselvideo_path !== null &&
          //     item.countDownImages !== "" &&
          //     item.buycarrusel_top === true &&
          //     item.status === "ACTIVE" &&
          //     item.language === 'en'
          //     );

          //   }
          //   console.log('Los items que están filtrados son:',sponsorWithImages);
          //   const imagesArray = sponsorWithImages.map((item,index) => ({
          //     name: item.sponsor_name,
          //     url: item.sponsor_url ?? "#",
          //     timeShow: item.countDownImages[index] ??  5000,
          //     image: item.image_carruseltop_path,
          //   }));
          //   setImagesAds(imagesArray);
          // }
        }
      })
      .catch((err: any) => {
        handleVideoError(err, { icon: "error", timer: 4000 });
        console.error("errores get img ads?", err.message);
      });
  };

  const videoSrc: any = {
    controls: true,
    playsinline: true,
    autoPlay: true,
    responsive: true,
    fluid: true,
    controlBar: ["qualitySelector"],
  };

  const handleAction = (actionName: string) => {
    if (actionName === "next") {
      if (watcher === playListVideo.length - 1) {
        setWatcher(0);
      } else {
        setWatcher((prev) => prev + 1);
      }
    } else if (actionName === "before") {
      if (watcher === 0) {
        setWatcher(playListVideo.length - 1);
      } else {
        setWatcher((prev) => prev - 1);
      }
    }
  };

  //Tiene membresia?
  const hasMembership = () => {
    if (currentUser) {
      if (currentUser.id_membership !== 1) {
        return true;
      } else {
        return false;
      }
    }
  };

  //permite cambiar el acceso al video si es publico o privado
  const changePrivacy = () => {
    if (currentUser.id_membership !== 1) {
      const MySwal: any = Swal;

      MySwal.fire({
        title: `${t("Video.modals.updateVideo")}`,
        text: `${t("Video.modals.updateVideo_description")}`,
        willOpen: () => {
          MySwal.showLoading(null);
        },
        showConfirmButton: false,
      });
      Post(`${URL_API}/live/changeprivacy/`, {
        token: GetTokenLogin(),
        videoId: id,
      })
        .then((res) => {
          if (!res.data.error) {
            Swal.fire(
              `${t("Video.modals.updateVideo_ok")}`,
              `${t("Video.modals.privacyVideo_ok")}`,
              "success"
            );

            // setVideoProps({
            //   ...videoProps,
            //   isPrivate: res.data.data.isprivate,
            // });

            setIsPrivate(res.data.data.isprivate);
          } else {
            Swal.fire(
              `${t("Video.modals.privacyError")}`,
              res.data.data.Error,
              "error"
            );
            if (res.data.login) {
              navigate("/login");
            }
          }
        })
        .catch((err) => {});
    } else {
      Swal.fire({
        title: `${t("SuscriptionClub.Subscribe")}`,
        text: `${t("Video.pro")}`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: `${t("Video.subme")}`,
        cancelButtonText: `${t("Video.keepFree")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/planessocios");
        }
      });
    }
  };

  const onSearchUser = (value: string) => {
    setTaggednewuser(value);

    getPredictiveUser(value)
      .then((resp: any) => {
        const result = resp.data;

        setOptionsPlayers(result.response);
      })
      .catch((err: any) => {
        console.error(
          "Error al obtener las coincidencias de los jugadores",
          err
        );
      });
  };

  const handleUserSelection = (value: number) => {
    setSelectedUsers([...selectedUsers, value]);
  };

  const renderItem = (title: string, res: any) => ({
    value: title,
    label: (
      <Button
        className="flex flex-row justify-between w-full hover:bg-slate-300 "
        onClick={() => {
          handleUserSelection(res);
        }}
      >
        <p className="w-full text-base font-medium text-left text-black hover:text-red-700">
          {title}
        </p>
      </Button>
    ),
  });
  const optionDataUser = optionsPlayers.map((item, index) => ({
    label: item.firstname + " " + item.lastname,
    options: [renderItem(item.nickname, item)],
  }));

  const updateTaggedUsers = () => {
    const arrayIdPlayers = selectedUsers.map((item) => item.nickname);

    updateTagsUsers(id, arrayIdPlayers)
      .then((res: any) => {
        const result = res.data;
        if (!result.error) {
          setTaggedUsers(result.data);
          setmodalTags(false);
          setTaggednewuser("");
          setSelectedUsers([]);
          if (result.warning) {
            Swal.fire(
              `${t("Video.modals.updateVideo_ok")}`,
              `${t("Video.modals.userTagged")}` + ":" + result.alreadyexist,
              "warning"
            );
          } else {
            Swal.fire(
              `${t("Video.modals.updateVideo_ok")}`,
              `${t("Video.modals.userTagged")}`,
              "success"
            );
          }
        } else {
          Swal.fire(
            `${t("Video.modals.userTagged_error")}`,
            result.data,
            "error"
          );
          setmodalTags(false);
        }
      })
      .catch((err: any) => {
        console.error(
          "hubo un error al actualizar las etiquetas de usuario",
          err
        );
      });
  };

  // console.log("IDS taggeados",taggedUsers);
  const deleteTaggedUser = (nickname: string) => {
    //preguntar si desea eliminar
    const videoIdProperty = videoProps.userDataProps.id;

    console.log("que trae taggedUsers", taggedUsers);

    if (currentUser.id_player === videoIdProperty) {
      showDeleteConfirmation(nickname);
    } else if (
      taggedUsers.some((user: any) => user.id === currentUser.id_player)
    ) {
      showDeleteConfirmation(nickname);
    } else {
      Swal.fire(
        `${t("Video.modals.userTagged_noPermission")}`,
        `${t("Video.modals.userTagged_noPermission_description")}`,
        "error"
      );
    }
  };

  const showDeleteConfirmation = (nickname: string) => {
    const videoIdProperty = videoProps.userDataProps.id;
    Swal.fire({
      title: `${t("Video.modals.title1")}`,
      text: `${t("Video.modals.userTagged_delete")}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#d33",
      confirmButtonText: `${t("Video.Buttons.yesDelete")}`,
      cancelButtonText: `${t("Video.Buttons.noCancel")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTagUserByName(id, nickname)
          .then((resp: any) => {
            const result = resp.data;
            console.log("que trae el result?", result);
            if (!result.error) {
              setTaggedUsers(result.data);
              Swal.fire(
                `${t("Video.modals.deleteOK")}`,
                `${result.userDeleted}  ${t(
                  "Video.modals.deleteOK_description"
                )}`,
                "success"
              );
              if (!currentUser.id_player === videoIdProperty) {
                navigate("/?user=" + currentUser.id_player);
              }
            } else {
              Swal.fire(
                `${t("Video.modals.userTagged_errorDelete")}`,
                result.data,
                "error"
              );
              setmodalTags(false);
            }
          })
          .catch((err: any) => {
            console.error(
              "hubo un error al borrar las etiquetas de usuario",
              err
            );
          });
      }
    });
  };

  const handleSavingDataHL = (values: any) => {
    setValuesSaving(values);
  };

  const addSavedHLData = () => {
    if (savingHL) {
      addSaveHLData(valuesSaving);
    }
  };

  const handleCancelDownloadVideo = async () => {
    cancelDownload.current = await axios.CancelToken.source();
    setIsDownloadFinished(false);
  };

  const handleHLOpen = () => {
    if (videojsRef !== null) {
      if (videojsRef.current.hasStarted() === false) {
        videojsRef.current.play();
      }

      setOpenModal(true);
      setSavingHL(true);
    } else {
      Swal.fire({
        title: `${t("Video.modals.Hl_error")}`,
        text: `${t("Video.modals.Hl_error_description")}`,
        icon: "warning",
      });
    }
  };
  // console.log("que trae othervideos?",othervideo);
  return (
    <>
      {ready ? (
        <div className="flex items-center justify-center w-full pt-20 ">
          <Lottie
            animationData={loaderVideo}
            style={{
              width: 450,
              height: 450,
            }}
          />
        </div>
      ) : (
        <Container
          style={{
            margin: "0 auto",
          }}
          className="pt-10 "
        >
          <Row>
            <Col md={12} lg={9} className="static">
              {isLive ? (
                currentUser?.email === videoProps?.userDataProps.email && (
                  <button
                    onClick={() => {
                      Swal.fire({
                        title: `${t("Video.modals.title1")}`,
                        text: `${t("Video.modals.description1")}`,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: `${t("Video.Buttons.yes")}`,
                        cancelButtonText: `${t("Video.Buttons.No")}`,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          const MySwal: any = Swal;
                          MySwal.fire({
                            title: `${t("Video.modals.title2")}`,
                            text: `${t("Video.modals.description2")}`,
                            willOpen: () => {
                              MySwal.showLoading(null);
                            },
                            showConfirmButton: false,
                          });
                          Post(`${URL_API}/recordvideo/stopRecord/`, {
                            token: GetTokenLogin(),
                            videoId: videoProps.id,
                          }).then((res) => {
                            Swal.fire(
                              "",
                              `${t("Video.modals.resultStop")}`,
                              "success"
                            );
                            GetConfig(true);

                            navigate("/");
                          });
                        }
                      });
                    }}
                    style={{
                      width: "100",
                      marginBottom: "25px",
                      fontSize: "20px",
                    }}
                    className="btn btn-danger"
                  >
                    {t("Video.modals.title3")}
                  </button>
                )
              ) : (
                <></>
              )}
              {isLive ? (
                <VideoJsPlayer
                  source={videoProps.streamingUrl}
                  isVideoLive={isLive}
                  enableSprites={false}
                  adsVideoPlay={videosAds}
                  options={videoSrc}
                  handleActionNext={() => handleAction("next")}
                  handleActionBefore={() => handleAction("before")}
                  adsEnabled={false}
                />
              ) : currentUser ? (
                currentUser.id_membership !== 1 ? (
                  videoPlayerScreen && (
                    <VideoJsPlayer
                      source={videoPlayerScreen}
                      isHLVideo={videoProps.isHighLight}
                      isVideoLive={isLive}
                      enableSprites={false}
                      adsVideoPlay={videosAds}
                      options={videoSrc}
                      onVideoRef={handleVideoJsRef}
                      handleActionNext={() => handleAction("next")}
                      handleActionBefore={() => handleAction("before")}
                      adsEnabled={hasMembership() ? false : true}
                    />
                  )
                ) : (
                  videoPlayerScreen && (
                    <VideoJsPlayer
                      source={videoPlayerScreen}
                      isHLVideo={videoProps.isHighLight}
                      isVideoLive={isLive}
                      enableSprites={false}
                      options={videoSrc}
                      onVideoRef={handleVideoJsRef}
                      adsVideoPlay={videosAds}
                      handleActionNext={() => handleAction("next")}
                      handleActionBefore={() => handleAction("before")}
                      adsEnabled={hasMembership() ? false : true}
                    />
                  )
                )
              ) : (
                <div className="static">
                  {videoPlayerScreen && (
                    <VideoJsPlayer
                      source={videoPlayerScreen}
                      isHLVideo={videoProps.isHighLight}
                      isVideoLive={isLive}
                      enableSprites={false}
                      options={videoSrc}
                      adsVideoPlay={videosAds}
                      onVideoRef={handleVideoJsRef}
                      handleActionNext={() => handleAction("next")}
                      handleActionBefore={() => handleAction("before")}
                      adsEnabled={hasMembership() ? false : true}
                    />
                  )}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <div>
                  <label className="labelviews">
                    {videoProps.viewers + " " + t("Video.viewers")}
                  </label>
                </div>
                <div className="flex flex-col items-center justify-end laptop:max-2kScreen:hidden ">
                  <IconButton
                    icon={
                      <RiVideoDownloadLine
                        size={15}
                        onClick={() => {
                          if (currentUser) {
                            if (currentUser?.id_membership !== 1) {
                              handleCancelDownloadVideo();
                              Swal.fire({
                                icon: "info",
                                title: `${t("Video.modals.download")}`,
                                html: `${t(
                                  "Video.modals.progress"
                                )}<strong>0%</strong>`,
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonText: "Cancelar",
                                willOpen: (toast) => {
                                  toast.addEventListener(
                                    "mouseenter",
                                    Swal.stopTimer
                                  );
                                  toast.addEventListener(
                                    "mouseleave",
                                    Swal.resumeTimer
                                  );
                                },
                                didOpen: (toast) => {
                                  Swal.isLoading();
                                  downloadVideo(
                                    videoPlayerScreen,
                                    (progress) => {
                                      if (
                                        !isDownloadFinished &&
                                        Swal.isVisible()
                                      ) {
                                        Swal.update({
                                          html: ` ${t(
                                            "Video.modals.progress"
                                          )} <strong>${progress}%</strong>`,
                                        });
                                      }
                                      if (progress === 100) {
                                        Swal.hideLoading();
                                        setIsDownloadFinished(true);
                                        setTimeout(() => {
                                          Swal.close();
                                        }, 1500);
                                      }
                                    },
                                    cancelDownload.current.token
                                  );
                                },
                              }).then((res) => {
                                console.log("Que cambia aqui?", res);
                                if (
                                  res.isDismissed &&
                                  res.dismiss as Swal.DismissReason === Swal.DismissReason.cancel
                                ) {
                                  if (cancelDownload.current) {
                                    setIsDownloadFinished(true);
                                    cancelDownload.current.cancel(
                                      `${t("Video.modals.description_CancelD")}`
                                    );
                                    Swal.fire(
                                      `${t("Video.modals.cancelDownload")}`,
                                      `${t(
                                        "Video.modals.description_CancelD"
                                      )}`,
                                      "error"
                                    );
                                  }
                                }
                              });
                            } else {
                              Swal.fire({
                                title: `${t("SuscriptionClub.Subscribe")}`,
                                text: `${t("Video.pro")}`,
                                icon: "info",
                                showCancelButton: true,
                                confirmButtonText: `${t("Video.subme")}`,
                                cancelButtonText: `${t("Video.keepFree")}`,
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  navigate("/planessocios");
                                }
                              });
                            }
                          } else {
                            Swal.fire({
                              title: `${t("Video.modals.signIn")}`,
                              text: `${t("Video.modals.signIn_Description")}`,
                              icon: "info",
                              showCancelButton: true,
                              confirmButtonText: `${t("Login.Signin")}`,
                              cancelButtonText: `${t("Profil.Buttons.cancel")}`,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                navigate("/login");
                              }
                            });
                          }
                        }}
                      />
                    }
                    circle
                  />
                </div>

                {videoProps ?? videoProps.idHightLightCategory !== undefined ? (
                  videoProps.idHightLightCategory !== "" ? (
                    <div>
                      <label className="labelviews">
                        {`${t("Video.category")}` +
                          videoProps.idHightLightCategory}
                      </label>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Whisper
                      trigger="click"
                      placement="auto"
                      speaker={
                        <div className="whisperShare">
                          <div className="shareTitle">
                            <p>{t("Video.shareIn")}</p>
                          </div>
                          <Row className="rowLink">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-arround",
                              }}
                            >
                              <div className="mb-3 input-group inputlink">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={window.location.href}
                                  readOnly
                                />
                              </div>

                              <div className="input-group-append copylink">
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      window.location.href
                                    );
                                    Swal.fire({
                                      title: "¡Copiado!",
                                      icon: "success",
                                      showConfirmButton: false,
                                      timer: 1000,
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faLink} />
                                  {t("Video.copy")}
                                </button>
                              </div>
                            </div>
                          </Row>
                          <Row className="rowIconShare">
                            <Col className="colIconShare">
                              <FacebookShareButton
                                url={window.location.href}
                                quote={titleshare}
                                className="share-button"
                              >
                                <FacebookIcon size={32} round />
                              </FacebookShareButton>
                            </Col>
                            <Col className="colIconShare">
                              <FacebookMessengerShareButton
                                url={window.location.href}
                                appId="521270401588372"
                                className="share-button"
                              >
                                <FacebookMessengerIcon size={32} round />
                              </FacebookMessengerShareButton>
                            </Col>
                            <Col className="colIconShare">
                              <TwitterShareButton
                                url={window.location.href}
                                title={titleshare}
                                className="share-button"
                              >
                                <TwitterIcon size={32} round />
                              </TwitterShareButton>
                            </Col>
                            <Col className="colIconShare">
                              <WhatsappShareButton
                                url={window.location.href}
                                title={titleshare}
                                separator=":: "
                                className="share-button"
                              >
                                <WhatsappIcon size={32} round />
                              </WhatsappShareButton>
                            </Col>
                            <Col className="colIconShare">
                              <EmailShareButton
                                url={window.location.href}
                                subject={titleshare}
                                body={t("Video.bodyShare")}
                                className="share-button"
                              >
                                <EmailIcon size={32} round />
                              </EmailShareButton>
                            </Col>
                          </Row>
                        </div>
                      }
                    >
                      <button className="buttonlikedislike buttonprivate">
                        <FontAwesomeIcon
                          className="buttonlikedislikeicon"
                          icon={faShareNodes}
                        />
                        {t("Video.share")}
                      </button>
                    </Whisper>
                  </div>
                  {!currentUser ? (
                    <></>
                  ) : isLive ? (
                    <></>
                  ) : videoProps.isHightLight ? (
                    <></>
                  ) : openModal ? (
                    <div>
                      <button
                        onClick={() => {
                          setSavingHL(true);
                          setOpenModal(false);
                        }}
                        className="buttonlikedislike buttonprivate"
                      >
                        <FontAwesomeIcon
                          className="buttonlikedislikeicon"
                          icon={faScissors}
                        />
                        {t("Video.closeHL")}
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        onClick={() => {
                          if (hasMembership()) {
                            handleHLOpen();
                          } else {
                            Swal.fire({
                              title: `${t("SuscriptionClub.Subscribe")}`,
                              text: `${t("Video.pro")}`,
                              icon: "info",
                              showCancelButton: true,
                              confirmButtonText: `${t("Video.subme")}`,
                              cancelButtonText: `${t("Video.keepFree")}`,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                navigate("/planessocios");
                              }
                            });
                          }
                        }}
                        className="buttonlikedislike buttonprivate"
                      >
                        <FontAwesomeIcon
                          className="buttonlikedislikeicon"
                          icon={faScissors}
                        />
                        {t("Video.openHL")}
                      </button>
                    </div>
                  )}

                  <div className="flex items-center justify-center ">
                    <Tooltip
                      placement="bottom"
                      title={t("Video.likes")}
                      arrow={true}
                    >
                      <Button
                        appearance={"default"}
                        className=" ring-slate-600"
                        onClick={() => {
                          if (currentUser?.email) {
                            // setLikeVideo(true);
                            likeThisVideo(true);
                          } else {
                            Swal.fire({
                              title: `${t("Video.modals.signIn")}`,
                              text: `${t("Video.modals.signIn_Description")}`,
                              icon: "info",
                              showCancelButton: true,
                              confirmButtonText: `${t("Login.Signin")}`,
                              cancelButtonText: `${t("Profil.Buttons.cancel")}`,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                navigate("/login");
                              }
                            });
                          }
                        }}
                      >
                        {liked ? (
                          <RiThumbUpFill className="text-lg text-red-600" />
                        ) : (
                          <RiThumbUpLine className="text-lg" />
                        )}

                        <p className="pl-5 text-base font-medium text-left ">
                          {videoProps.like}
                        </p>
                      </Button>
                    </Tooltip>
                    {/* <ButtonGroup>
                      <Tooltip
                        placement="bottom"
                        title={t("Video.likes")}
                        arrow={true}
                      >
                        <Button
                          appearance={"default"}
                          className=" ring-slate-600"
                          onClick={() => {
                            if (currentUser?.email) {
                              // setLikeVideo(true);
                              likeThisVideo(true);
                            } else {
                              Swal.fire({
                                title: `${t("Video.modals.signIn")}`,
                                text: `${t("Video.modals.signIn_Description")}`,
                                icon: "info",
                                showCancelButton: true,
                                confirmButtonText: `${t("Login.Signin")}`,
                                cancelButtonText: `${t(
                                  "Profil.Buttons.cancel"
                                )}`,
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  navigate("/login");
                                }
                              });
                            }
                          }}
                        >
                          {liked ? (
                            <RiThumbUpFill className="text-lg text-red-600" />
                          ) : (
                            <RiThumbUpLine className="text-lg" />
                          )}

                          <p className="pl-5 text-base font-medium text-left ">
                            {videoProps.like}
                          </p>
                        </Button>
                      </Tooltip>
                      <Tooltip
                        placement="bottom"
                        title={t("Video.dislikes")}
                        arrow={true}
                      >
                        <Button
                          appearance={"default"}
                          className=" ring-slate-600"
                          onClick={() => {
                            if (currentUser?.email) {
                              dislikeThisVideo(true);
                            } else {
                              Swal.fire({
                                title: `${t("Video.modals.signIn")}`,
                                text: `${t("Video.modals.signIn_Description")}`,
                                icon: "info",
                                showCancelButton: true,
                                confirmButtonText: `${t("Login.Signin")}`,
                                cancelButtonText: `${t(
                                  "Profil.Buttons.cancel"
                                )}`,
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  navigate("/login");
                                }
                              });
                            }
                          }}
                        >
                          {disliked ? (
                            <RiThumbDownFill className="text-lg text-red-600" />
                          ) : (
                            <RiThumbDownLine className="text-lg " />
                          )}
                        </Button>
                      </Tooltip>
                    </ButtonGroup> */}
                    {/* <button
                      onClick={() => {
                        if (currentUser?.email) {
                          handlePolls(likeVideo);
                        } else {
                          Swal.fire({
                            title: `${t("Video.modals.signIn")}`,
                            text: `${t("Video.modals.signIn_Description")}`,
                            icon: "info",
                            showCancelButton: true,
                            confirmButtonText: `${t("Login.Signin")}`,
                            cancelButtonText: `${t("Profil.Buttons.cancel")}`,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              navigate("/login");
                            }
                          });
                        }
                      }}
                      className="buttonlikedislike buttonlike"
                    >
                      <FontAwesomeIcon
                        className="buttonlikedislikeicon"
                        icon={faThumbsUp}
                      />
                      {videoProps.like }
                    </button>
                    <button
                      onClick={() => {
                        if (currentUser?.email) {
                          handlePolls(likeVideo);
                        } else {
                          Swal.fire({
                            title: `${t("Video.modals.signIn")}`,
                            text: `${t("Video.modals.signIn_Description")}`,
                            icon: "info",
                            showCancelButton: true,
                            confirmButtonText: `${t("Login.Signin")}`,
                            cancelButtonText: `${t("Profil.Buttons.cancel")}`,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              navigate("/login");
                            }
                          });
                        }
                      }}
                      className="buttonlikedislike buttonlike"
                    >
                      <FontAwesomeIcon
                        className="buttonlikedislikeicon"
                        icon={faThumbsDown}
                      />
                    </button> */}
                  </div>
                  {!currentUser ? (
                    <></>
                  ) : videoProps.idPlayer === currentUser.id_player ? (
                    !isPrivate ? (
                      <div>
                        <button
                          onClick={() => {
                            changePrivacy();
                          }}
                          className="buttonlikedislike buttonprivate"
                        >
                          <FontAwesomeIcon
                            className="buttonlikedislikeicon"
                            icon={faLockOpen}
                          />
                          {t("Video.public")}
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          onClick={() => {
                            changePrivacy();
                          }}
                          className="buttonlikedislike buttonprivate"
                        >
                          <FontAwesomeIcon
                            className="buttonlikedislikeicon"
                            icon={faLock}
                          />
                          {t("Video.private")}
                        </button>
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {openModal && (
                <CreateHightLighs
                  videoOnplay={videoPlayerScreen ?? null}
                  closeHL={setOpenModal}
                  openHL={openModal}
                  adsEnable={hasMembership() ? false : true}
                  token={GetTokenLogin()}
                  vidOnplay={videojsRef}
                  onHandleSave={handleSavingDataHL}
                  saveDataHL={saveDataHL}
                  cameraId={videoProps.idCamera}
                  flagCtx={savingHL}
                />
              )}
              {/* AQUI VA EL ANUNCIO */}
              {imagesAds.length > 0 && (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className="flex flex-row items-center justify-center w-full mb-3 "
                >
                  <CarouselBanner
                    data={imagesAds}
                    autoplay={true}
                    enableArrows={false}
                    isBannerAd={true}
                    locate={CarouselContext.BottomVideo}
                  />
                </Col>
              )}
              <Card.Body>
                <div className="bodycard">
                  <img
                    className="circular--square"
                    src={
                      videoProps.userDataProps.photoicon
                        ? videoProps.userDataProps.photoicon
                        : "https://storage.googleapis.com/publicsportprotv/892781.png"
                    }
                    alt="user"
                  />
                  <div className="bodycarddata" style={{}}>
                    <label className="bodycarddatatitle">
                      {videoProps.description}
                    </label>
                    <label className="bodycarddatacd">
                      {videoProps.clubDataProps.displayname +
                        " " +
                        videoProps.clubDataProps.countryicon}
                    </label>
                    <label className="bodycarddatacd">
                      {videoProps.cameraData}
                    </label>
                    <label
                      className="bodycarddatacd hover:text-cyan-400 hover:cursor-pointer"
                      onClick={() => {
                        navigate("/?user=" + videoProps.userDataProps.id);
                        //console.log(props.alldata.userdata.id_country);
                      }}
                    >
                      {videoProps.userDataProps.firstname}{" "}
                      {videoProps.userDataProps.flag}
                    </label>
                  </div>
                </div>
              </Card.Body>

              <Row>
                <Col md={12} lg={9}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div>
                      <Button
                        className="my-3 text-base font-medium text-center text-white bg-black ring-1 ring-white hover:bg-red-500"
                        onClick={() => {
                          if (hasMembership()) {
                            setmodalTags(true);
                          } else {
                            Swal.fire({
                              title: `${t("SuscriptionClub.Subscribe")}`,
                              text: `${t("Video.pro")}`,
                              icon: "info",
                              showCancelButton: true,
                              confirmButtonText: `${t("Video.subme")}`,
                              cancelButtonText: `${t("Video.keepFree")}`,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                navigate("/planessocios");
                              }
                            });
                          }
                        }}
                      >
                        {t("Video.userTag")}
                        <FontAwesomeIcon
                          icon={faUserPlus}
                          style={{ marginLeft: "10px" }}
                        />
                      </Button>
                    </div>
                  </div>
                  <div>
                    {loadingTags ? (
                      <Space>
                        <Skeleton.Button
                          active={true}
                          size={"large"}
                          shape={"round"}
                          block={false}
                        />
                        <Skeleton.Button
                          active={true}
                          size={"large"}
                          shape={"round"}
                          block={false}
                        />
                        <Skeleton.Button
                          active={true}
                          size={"large"}
                          shape={"round"}
                          block={false}
                        />
                        <Skeleton.Button
                          active={true}
                          size={"large"}
                          shape={"round"}
                          block={false}
                        />
                        <Skeleton.Button
                          active={true}
                          size={"large"}
                          shape={"round"}
                          block={false}
                        />
                        <Skeleton.Button
                          active={true}
                          size={"large"}
                          shape={"round"}
                          block={false}
                        />
                        <Skeleton.Button
                          active={true}
                          size={"large"}
                          shape={"round"}
                          block={false}
                        />
                      </Space>
                    ) : (
                      <TagGroup>
                        {Array.isArray(taggedUsers)
                          ? taggedUsers.map((taggeduser: any, index) => {
                              return (
                                <Fragment key={index}>
                                  <Tag
                                    closable
                                    onClose={() => {
                                      if (hasMembership()) {
                                        deleteTaggedUser(taggeduser.nickname);
                                      } else {
                                        Swal.fire({
                                          title: `${t(
                                            "SuscriptionClub.Subscribe"
                                          )}`,
                                          text: `${t("Video.pro")}`,
                                          icon: "info",
                                          showCancelButton: true,
                                          confirmButtonText: `${t(
                                            "Video.subme"
                                          )}`,
                                          cancelButtonText: `${t(
                                            "Video.keepFree"
                                          )}`,
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            navigate("/planessocios");
                                          }
                                        });
                                      }
                                    }}
                                    className="text-lg font-semibold text-white bg-red-600 ring-1 ring-black"
                                  >
                                    <label className="taguserlabel">
                                      {taggeduser.nickname}
                                    </label>
                                  </Tag>
                                </Fragment>
                              );
                            })
                          : null}
                      </TagGroup>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={3}>
              <div className="my-4 separator"></div>
              {othervideo.map((item: any, index) => {
                return (
                  <CardPVVideo
                    key={index}
                    status={
                      item.ishighlight
                        ? "highlight"
                        : item.islive
                        ? "live"
                        : "stream"
                    }
                    viewsCount={item.views}
                    LikesCount={item.like}
                    dislikesCount={item.dislike}
                    cameraName={item.cameraData}
                    duration={ProcesarDuracion(
                      new Date(item.initialtime),
                      new Date(item.endtime)
                    )}
                    title={item.description}
                    club={
                      item.clubData.displayname +
                      "  " +
                      item.clubData.countryicon
                    }
                    user={item.userdata.firstname} // + " " + item.userdata.lastname}
                    imageuser={
                      item.userdata.photoicon
                        ? item.userdata.photoicon
                        : "https://storage.googleapis.com/publicsportprotv/892781.png"
                    }
                    previewimage={
                      item.photopath === null
                        ? "https://via.placeholder.com/320x180"
                        : item.photopath
                    }
                    id={item.id}
                    alldata={item}
                    date={item.initialtime}
                    highlightcat={item.highlightcat}
                  />
                );
              })}
            </Col>
          </Row>
        </Container>
      )}

      <Modal show={modalTags} backdrop="static" style={{ top: "30vh" }}>
        <Modal.Body>
          <Container>
            <Row>
              <Col style={{ textAlign: "center", justifyContent: "center" }}>
                <h2 className="text-lg text-center text-black ">
                  {" "}
                  {t("Video.userTag")}
                </h2>
              </Col>
            </Row>
            <br />
            <Row style={{ textAlign: "center", justifyContent: "center" }}>
              <div className="flex justify-start w-full">
                <AutoComplete
                  popupClassName="certain-category-search-dropdown z-99999999"
                  options={optionDataUser}
                  onSearch={onSearchUser}
                  value={taggednewuser}
                  placeholder="Input here..."
                  notFoundContent={"No hubo coincidencias"}
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <InputAntd.Search
                    size="large"
                    placeholder="Input here"
                    style={{ width: "100%" }}
                    type="search"
                  />{" "}
                  {/* Establecer el ancho del input al 100% */}
                </AutoComplete>
              </div>
            </Row>
            <Row>
              <TagGroup>
                {selectedUsers.map((x: any, index: number) => {
                  return (
                    <Tag
                      key={index}
                      className="text-lg font-semibold text-white bg-red-600"
                      closable
                      onClose={() => {
                        selectedUsers.splice(selectedUsers.indexOf(x), 1);
                        setSelectedUsers([...selectedUsers]);
                      }}
                    >
                      <div className="flex flex-row items-center justify-start">
                        <div className="pr-2">
                          <RiMapPinUserLine />
                        </div>
                        <p>{x.nickname}</p>
                      </div>
                    </Tag>
                  );
                })}
              </TagGroup>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="text-base font-medium text-white bg-black hover:bg-white hover:text-black hover:ring-2 hover:ring-black "
            onClick={() => {
              if (selectedUsers.length === 0) {
                Swal.fire(
                  ` ${t("Video.modals.title1")}`,
                  ` ${t("Video.modals.taggedUser")}`,
                  "warning"
                );
                return;
              }
              updateTaggedUsers();
            }}
          >
            {t("Video.userTag")}
          </Button>

          <Button
            className="text-base font-medium text-white bg-red-600 hover:bg-red-400"
            onClick={() => {
              setmodalTags(false);
              setarrayUsers([]);
            }}
          >
            {t("Video.Buttons.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
