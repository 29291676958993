
import { HTMLAttributes } from "react";
import { AiOutlineClose } from "react-icons/ai";
interface Props {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
    classNameProps?: string;

}
const ModalComponent = ({open,onClose,children , classNameProps}:Props) => {
    return(
        <div
        onClick={onClose}
        className={`
        fixed inset-0 flex justify-center items-center transaction-colors
        ${open ? "visible bg-black/20" : "invisible"}
        `}
        >
            <div
            onClick={(e) => e.stopPropagation()}
            className={`${classNameProps ? classNameProps : null}
            bg-white rounded-xl shadow p-6 transition-all
            ${open ? "scale-100 opacity-100": "scale-125 opacity-0"}
            `}>
                <button onClick={onClose}
                className="absolute p-2 text-gray-400 bg-white rounded-lg top-2 right-2 hover:bg-gray-50 hover:text-gray-600">
                 <AiOutlineClose size={20}/>
                </button>
                <div className="mt-8">

            {children}
                </div>
            </div>
        </div>
    )
}
export default ModalComponent;