import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { language, Textos } from "../../texto";
import AuthContext from "../../utils/Auth";
import { auth, sendPasswordReset } from "../../utils/firebase";
import "./reset.css";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

export function ResetPage() {

  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const { setCurrentUser, saveToken, GetUser, currentUser ,t} = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (currentUser) navigate("/");
  }, [user, loading]);

  const generateNewPassword = () =>{
    sendPasswordReset(email).then((resp:any) => {
      Swal.fire({
        title: `${t("Login.Modals.resetPasswordTitle")}` + email,
        text: `${t("Login.Modals.resetPasswordDescription")}`,
        icon: "success",
      });
    }).catch((err)=>{
      Swal.fire({
        title: "Error",
        text: err.message,
        icon: "error",
      });
    })
  }

  return (
    <div className="p-10 mx-auto rounded shadow-sm w-lg-500px bg-body p-lg-15">
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" id="kt_sign_in_form" data-kt-redirect-url="/metronic8/demo1/../demo1/index.html" action="#">
        <div className="mb-10 text-center">
          <h1 className="mb-3 text-2xl text-center text-black ">{t("Reset.title")}</h1>
        </div>
        <div className="mb-10 fv-row fv-plugins-icon-container">
          <label className="form-label fs-6 fw-bolder text-dark">{t("Login.Email")}</label>
          <input className="form-control form-control-lg form-control-solid" type="text" name="name" autoComplete="off" value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>

        <div className="text-center">
          <button type="button" id="kt_sign_in_submit" className="mb-5 btn btn-lg btn-primary w-100"
            onClick={() => {
              generateNewPassword();
            }}
          >
            <span className="indicator-label">{t("Reset.title")}</span>
            <span className="indicator-progress">{t("Login.Loading")}
              <span className="align-middle spinner-border spinner-border-sm ms-2" /></span>
          </button>

          <div className="mb-5 text-center text-muted text-uppercase fw-bolder">{t("Reset.or")}</div>
          <div className="mb-10 fv-row fv-plugins-icon-container">
            <label className="form-label fs-6 fw-bolder text-dark">{t("Reset.notHaveAccount")}</label>
            <button type="button"
            
              className="mb-5 btn btn-flex flex-center btn-success btn-lg w-100"
              onClick={() => navigate("/register")}
              >
              {t("Reset.createAccount")}
             </button>
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
        </div>
        <div />
      </form>
    </div>

  );
}