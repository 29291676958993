import { useCallback, useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { CardPVVideo } from "../components/cardpvvideo";
import { FilterVideo } from "../components/filtervideo";
import { API_URL, API_URL_DEV, API_URL_LOCAL_DEV } from "../env";
import { useQuery } from "../Rutas";
import AuthContext from "../utils/Auth";
import { Get, getParameter, Post } from "../utils/consumir";
import { ProcesarDuracion, filterAdsBtwVideos, filterAdsImgHome } from "../utils/utilidades";
import { CarouselBanner, CarouselContext, optionImage } from "../components/CarouselBanner";
import { AdCard } from "../components/Ad/AdCard";
import { getAllSponsors } from "../utils/Api/sponsorApi";
import ToastSwal from "../components/toastSwal";
import { Col } from "rsuite";
import { useTranslation } from "react-i18next";
export function Home() {
  const URL_API = API_URL || "";
  const [searchParams] = useSearchParams();
  const [t , i18n] = useTranslation("global");
  let params = window.location.search;

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [ImagesAds, setImagesAds] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const { GetTokenLogin, GetUser } = useContext(AuthContext);
  let query = useQuery();
  let count = 0;

  useEffect(() => {
    getData(0);
  }, [window.location.search]);

  useEffect(() => {
   if(!window.location.search.includes('?user=')){
    // eslint-disable-next-line no-restricted-globals
    setLoading(true);
    setHasMore(true);
   }
  }, [])
  

  useEffect(() => {
    getSponsorsImages();
  }, []);

  const ArmarBody = () => {
    let body = {};
    let month = getParameter("month") || "";
    let year = getParameter("year") || "";

    let club_id = getParameter("club") || "";
    let sport_id = getParameter("sport") || "";
    let typev = getParameter("typev") || "";
    let user = getParameter("user") || "";
    let sortingv = getParameter("sort") || "";

    if (month !== "") {
      body = { ...body, month: month };
    }
    if (year !== "") {
      body = { ...body, year: year };
    }

    if (club_id !== "") {
      {
        body = {
          ...body,
          club: club_id.split(","),
        };
      }
    }
    if (sport_id !== "") {
      {
        body = {
          ...body,
          sport: sport_id.split(","),
        };
      }
    }
    if (user !== "") {
      {
        body = {
          ...body,
          useridfilter: user.split(","),
        };
      }
    }
    if (typev !== "") {
      if (typev !== "live") {
        setIsLive(false);
      } else {
        setIsLive(true);
      }
      {
        body = {
          ...body,
          typev: typev.split(","),
        };
      }
    }
    if (sortingv !== "") {
      {
        if (sortingv === "like") {
          sortingv = "stream.live.like";
        }
        body = {
          ...body,
          sort: sortingv,
        };
      }
    }

    let token = GetTokenLogin();
    if (token !== null) {
      body = {
        ...body,
        token: token,
      };
    }
    return body;
  };

  const getData = useCallback(
    (count: number) => {
      setLoading(true);
      Post(`${API_URL}/live/videos/${count}`, ArmarBody()).then(
        (res) => {
          let sort: any = ArmarBody();
          if (sort.sort) {
            if (sort.sort === "stream.live.like") {
              res.data.data.sort((a: any, b: any) => b.like - a.like);
            }
            if (sort.sort === "views") {
              res.data.data.sort((a: any, b: any) => b.views - a.views);
            }
          }
          setLoading(false);
          setData(res.data.data);
          if(res.status !== 200){
            setHasMore(false);
            setLoading(false);
          }
        },
        (err) => {
          setData([]);
          setHasMore(false);
          setLoading(false);
          //console.log(err);
        }
      );
    },
    [API_URL]
  );
  const getDatas = (count: number) => {
    setLoading(true);

    Post(`${API_URL}/live/videos/${count}`, ArmarBody()).then(
      (res) => {
        let datos = data;
        datos = datos.concat(res.data.data);
        setLoading(false);
        setData(datos);
       
      },
      (err) => {
        if(window.location.search){
          setTimeout(() => {
            setHasMore(false);
            setLoading(false);
            
          }, 3000);
        }
      }
    );
  };

  const handleToast = (err: any, values: any) => {
    const { icon, timer } = values;
    ToastSwal({
      icon: icon,
      title: err,
      positions: "bottom-end",
      timer: timer,
      timeProgressBar: false,
    });
  };

  const getSponsorsImages = () => {
    getAllSponsors()
      .then((resp: any) => {
        if (resp.status === 200) {
          const data: any = resp.data;
          const currentLanguege = i18n.language;
          const imgTop = filterAdsImgHome(data,currentLanguege);
          setImagesAds(imgTop);
        }
      })
      .catch((err: any) => {
        handleToast(err, { icon: "error", timer: 3000 });
      });
  };
  return (
    <>
      {ImagesAds.length > 0 && (
        <div className="w-full">
          <CarouselBanner
            data={ImagesAds}
            isBannerAd={false}
            optionImage={optionImage.cover}
            enableArrows={false}
            autoplay={true}
            locate={CarouselContext.BannerMain}
          />
        </div>
      )}
      <div>
        <InfiniteScroll
          dataLength={data.length} //This is important field to render the next data
          next={() => {
            let count = page + 1;
            setPage(count);
            getDatas(count);
          }}
          hasMore={hasMore}
          loader={
            loading ? (
              <div className="cssload-loader">
                <div className="cssload-inner cssload-one"></div>
                <div className="cssload-inner cssload-two"></div>
                <div className="cssload-inner cssload-three"></div>
              </div>
            ) : (
              <></>
            )
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>{t("Home.NoVideo")}</b>
            </p>
          }
          refreshFunction={() => {
            if(hasMore){
              getData(0);

            }
          }}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          // pullDownToRefreshContent={
          //     <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
          // }
          // releaseToRefreshContent={
          //     <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          // }
        >
          <div className="bodypage">
            {loading ? (
              <></>
            ) : data.length > 0 ? (
              <></>
            ) : isLive ? (
              <h4>{t("Home.NoVideo")}</h4>
            ) : (
              <h4>{t("Home.NoVideo")}</h4>
            )}
            {params === "?typev=live" ? (
              <div
                style={{
                  display: "flex",
                  position: "static",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1%",

                  width: "100%",
                }}
              >
                <FilterVideo />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingTop: "1%",
                  paddingBottom: "1%",
                  width: "100%",
                  // backgroundColor:'pink',
                }}
              >
                <FilterVideo />
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-around",
                flexFlow: "wrap",
                flexGrow: "initial",
                flexBasis: data.length > 1 ? null : 1,
              }}
            >
              {data.map((item: any, index: number) => {
                return (
                  <>
                    <CardPVVideo
                      key={index +1}
                      status={
                        item.ishighlight
                          ? "highlight"
                          : item.islive
                          ? "live"
                          : "stream"
                      }
                      viewsCount={item.views}
                      LikesCount={item.like}
                      dislikesCount={item.dislike}
                      cameraName={item.cameraData}
                      duration={ProcesarDuracion(
                        new Date(item.initialtime),
                        item.endtime ? new Date(item.endtime) : new Date()
                      )}
                      title={item.description}
                      club={
                        item.clubData.displayname +
                        "  " +
                        item.clubData.countryicon
                      }
                      user={item.userdata.firstname}
                      imageuser={
                        item.userdata.photoicon
                          ? item.userdata.photoicon
                          : "https://storage.googleapis.com/publicsportprotv/892781.png"
                      }
                      previewimage={
                        item.photopath === null
                          ? "https://via.placeholder.com/320x180"
                          : item.photopath
                      }
                      id={item.id}
                      alldata={item}
                      date={item.initialtime}
                      highlightcat={item.highlightcat}
                    />
                    {index % 5 === 4 && (
                      <AdCard/>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
}
