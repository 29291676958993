import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { getAllImagesBtwVideos } from "../../utils/Api/sponsorApi";
import { CarouselBanner, CarouselContext, optionImage } from "../CarouselBanner";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { filterAdsBtwVideos } from "../../utils/utilidades";

export const AdCard = () => {
  const [imgBtwVideos, setImgBtwVideos] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    getBtwImages();

    return () =>{
      getBtwImages();
    }
  }, []);
  const imageNotFound = 'https://frontendsportprotvdev-p456tcdk5q-ue.a.run.app/img/Sport-pro-tv.png';
  const getBtwImages = () => {
    setLoading(true);
    const currentLanguage = i18next.language;
    getAllImagesBtwVideos()
      .then((resp: any) => {

        if(resp.status === 200 &&  Array.isArray(resp.data)){
          const data = resp.data;
          
          const imagesArray = filterAdsBtwVideos(data,currentLanguage);
                     //console.log('al final que imagenes trae?',imagesArray);
                    setLoading(false)
                    setImgBtwVideos(imagesArray);
        }
       
      })
      .catch((err: any) => {
        console.error("Error al llamar al servicio", err);
      });
  };
  return (
    <div
      style={{
        marginLeft: "20px",
        marginRight: "20px",
        marginTop: "20px",
      }}
    >
      <Card
        style={{
          width: "380px",
          height: "250px",
          borderRadius: "10px",
          boxShadow: "10px 10px 10px 0px rgba(0,0,0,0.15)",
          padding:'1%',
        }}
      >
        {!loading ?(
        <CarouselBanner
            data={imgBtwVideos}
            isBannerAd={false}
            optionImage={optionImage.cover}
            enableArrows={false}
            autoplay={true}
            locate={CarouselContext.BtwVideos}
          />
        

        ):(
          <div className="flex flex-col items-center justify-center">
            <img src={imageNotFound} alt='img provisional'/>
            <span className="flex items-center justify-center text-xs font-normal">Ads not found for your country</span>
          </div>
        )}
      </Card>
    </div>
  );
};
