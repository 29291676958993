import { API_URL, API_URL_DEV , API_URL_LOCAL_DEV } from "../../env";
import { Post } from "../consumir";
import axios from 'axios';


// consultar si el usuario tiene like en el video.
export const getLikeVideo = async (videoId:string , token:string) =>{

    try {
        const response = await Post(`${API_URL_DEV}/likes/vote/${videoId}`,{token});
        return response
        
    } catch (error) {
        
        console.error("ERROR NETWORK CONN LIKES SERVICE", error.status.toString());
    }
}

// se hace voto de like al video.
export const voteLike = async(values:any) =>{

    try {
        const response = await Post(`${API_URL_DEV}/likes/votelike`,values);
        return response
        
    } catch (error) {
        console.error("ERROR NETWORK CONN LIKE VOTE SERVICE", error.status.toString());
        
    }
}

// se hace voto de dislike al video.
export const voteDislike = async(values:any) =>{

    try {
        const response = await Post(`${API_URL_DEV}/likes/votedislike`,values);
        return response
        
    } catch (error) {
        console.error("ERROR NETWORK CONN DISLIKE VOTE SERVICE", error.status.toString());
        
    }
}

  
  export const updateLike = async (videoId :string) =>{
  
    try {
      const response = await axios.post(`${API_URL_DEV}/live/${videoId}/like`)
      return response.data;
      
    } catch (error) {
      console.error('Error al dar like al video:', error.message.toString());
    }
    
  }
  export const removeLike = async (videoId :string) =>{
  
    try {
      const response = await axios.post(`${API_URL_DEV}/live/${videoId}/unlike`)
      return response.data;
      
    } catch (error) {
      console.error('Error al remover el like al video:', error.message.toString());
    }
    
  }
  
  export const updateDislike = async (videoId :string) =>{
    try {
       const response = await axios.post(`${API_URL_DEV}/live/${videoId}/dislike`);
       return response.data;
    } catch (error) {
      console.error('Error al dar dislike al video:', error);
    }
  }
  
  export const removeDislike = async (videoId :string) =>{
    try {
       const response = await axios.post(`${API_URL_DEV}/live/${videoId}/undislike`);
       return response.data;
    } catch (error) {
      console.error('Error al remover el dislike al video:', error);
    }
  }
  