import { createContext, useState, ReactNode, FC, useContext } from "react";
import { API_URL } from "../env";
import AuthContext from "./Auth";
import { Get, Post } from "./consumir";
import { logout } from "./firebase";

const initialConfig: any = null

const ConfigContext = createContext(initialConfig);

const ConfigProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const URL_API = API_URL || '';
    const tokenID = "sportpro-token-v2";

    const [currentConfig, setCurrentConfig] = useState(initialConfig);
    const [visibleNav, setVisibleNav] = useState(false);

    const handleConfig = (e: any) => {
        if (currentConfig) {
            setCurrentConfig(null);
        } else {
            setCurrentConfig(e);
        }
    }

    const GetConfig = (force = false) => {
        if (force) {
            Post(URL_API + "/configuration", {
                token: localStorage.getItem(tokenID)
            }).then(res => {
                setCurrentConfig(res.data);
            }).catch(err => {
            });
        } else {
            if (currentConfig) {
                return currentConfig;
            } else {
                Post(URL_API + "/configuration", {
                    token: localStorage.getItem(tokenID)
                }).then(res => {
                    setCurrentConfig(res.data);
                }).catch(err => {
                });
            }
        }

    }

    const data = { GetConfig, currentConfig, setVisibleNav, visibleNav };
    return <ConfigContext.Provider value={data} >{children}</ConfigContext.Provider>;
}

export { ConfigProvider };
export default ConfigContext;