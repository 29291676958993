import { useContext, useEffect, useState, useRef, ChangeEvent } from "react";
import { Loader, Message, Placeholder, Uploader, useToaster } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import AuthContext from "../utils/Auth";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  CampoRequerido,
  CaracteresMaximos,
  CaracteresMinimos,
  Paises,
} from "../utils/utilidades";
import { SelectPicker } from "rsuite";
import { API_URL, API_URL_LOCAL_DEV, API_URL_DEV } from "../env";
import { Get, Post } from "../utils/consumir";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendPasswordReset } from "../utils/firebase";
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { PayPalPopUp } from "../components/paypalpopup";
import { Modal } from "react-bootstrap";
import { Button, Modal as ModalAntd } from "antd";
import { useTranslation } from "react-i18next";
import Upload, { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { uploadProfileImage } from "../utils/Api/uploadApi";
import { RiUploadCloud2Fill } from "react-icons/ri";
import { getDataUser, updateDataUser } from "../utils/Api/usersApi";
import { cancelSubscriptionPaypal, getInfoUserPaypal } from "../utils/Api/payPalServices";



interface paypalResponse {
  _id: string;
  iduser: string;
  paypalresponse: any;
  fecha: string;
  fechacancelacion: string;
  estado: string;
}

export function Perfil() {
  const toaster = useToaster();
  const [t] = useTranslation("global");
  const [popup, setPopup] = useState<boolean>(false);

  const [hoverImage, setHoverImage] = useState(false);
  const [open, setOpen] = useState(false);
  const [terminos, setTerminos] = useState(false);
  const [aceptnotif, setAceptnotif] = useState(false);
  const [aceptinfo, setAceptinfo] = useState(false);
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewTitle, setPreviewTitle] = useState("");

  const [substatus, setSubstatus] = useState<paypalResponse>();
  const tokenID = "sportpro-token-v2";
  const navigate = useNavigate();

  const {
    setCurrentUser,
    currentUser,
    GetUser,
    logoutUser,
    auth,
    GetTokenLogin,
  } = useContext(AuthContext);
  const [hideTerm, setHideTerm] = useState(false);
  const URL_API = API_URL || "";

  const inputRef = useRef(null);
  let token:string;

  useEffect(() => {
    // let hola: any = currentUser;
    const firrst = async () => {
       // eslint-disable-next-line react-hooks/exhaustive-deps
       token = localStorage.getItem(tokenID);
      setLoading(true);
      if (token == null) {
        navigate("/");
      }

      if (!currentUser) {
        await GetUser();
      }
      // Post(URL_API + "/login/getDataClient", {
      //   token: token,
      // }).then((res) => {
      //   console.log("Que trae?", res.data);
      //   setTerminos(res.data.term);
      //   setHideTerm(res.data.term);
      //   setProvider(res.data.provider);
      //   formik.setValues({
      //     id: res.data.id_player,
      //     nombre: res.data.firstname,
      //     apellido: res.data.lastname,
      //     pais: res.data.country
      //       ? res.data.country.countryname
      //         ? res.data.country.countryname
      //         : ""
      //       : "",
      //     imagen: res.data.photoicon,
      //     nickname: res.data.nickname,
      //     check: res.data.term,
      //     aceptnotif: res.data.aceptnotif,
      //     aceptinfo: res.data.aceptinfo,
      //     allData: res.data,
      //   });
      //   getPaypalResponse();
      //   setLoading(false);
      //   setAceptnotif(res.data.aceptnotif);
      //   setAceptinfo(res.data.aceptinfo);
      // });
      gerUserInformation(token);
    };
    firrst();
  }, []);

  useEffect(() => {
    if(currentUser !== undefined){
      getDataSubscriptionPaypal();
    }
  }, [currentUser]);
  

  function formatearFecha(fechainput: any) {
    var fecha = new Date(fechainput);
    var d = fecha.getDate();
    var m = fecha.getMonth() + 1;
    var yyyy = fecha.getFullYear();
    var h = fecha.getHours();
    var min = fecha.getMinutes();
    var s = fecha.getSeconds();

    var dd = d.toString();
    var mm = m.toString();
    var hh = h.toString();
    var minn = min.toString();
    var ss = s.toString();

    if (d < 10) {
      dd = "0" + dd;
    }
    if (m < 10) {
      mm = "0" + mm;
    }
    if (h < 10) {
      hh = "0" + hh;
    }
    if (min < 10) {
      minn = "0" + min;
    }
    if (s < 10) {
      ss = "0" + ss;
    }

    var fechaFormateada =
      dd + "-" + mm + "-" + yyyy + " " + hh + ":" + minn + ":" + ss;
    return fechaFormateada;
  }

  function recargo(inputfecha: any) {
    var fecha = new Date(inputfecha);
    //add a month
    fecha.setMonth(fecha.getMonth() + 1);
    //show dd-mm-yyyy
    var d = fecha.getDate();
    var m = fecha.getMonth() + 1;
    var yyyy = fecha.getFullYear();

    var dd = d.toString();
    var mm = m.toString();
    if (d < 10) {
      dd = "0" + dd;
    }
    if (m < 10) {
      mm = "0" + mm;
    }

    return dd + "-" + mm + "-" + yyyy;
  }

  function mesesSub(fechainput: any) {
    const fecha = new Date(fechainput);
    const hoy = new Date();
    const diff = hoy.getTime() - fecha.getTime();
    //diff month
    const diffMonth = diff / (1000 * 60 * 60 * 24 * 30);
    //round diffmonth
    return Math.round(diffMonth);
  }

 const gerUserInformation = (token:string)=>{
  getDataUser(token).then((resp:any)=>{
    if(resp){
      console.log("Que trae?", resp.data);
      setTerminos(resp.data.term);
      setHideTerm(resp.data.term);
      setProvider(resp.data.provider);
      formik.setValues({
        id: resp.data.id_player,
        nombre: resp.data.firstname,
        apellido: resp.data.lastname,
        pais: resp.data.country
          ? resp.data.country.countryname
            ? resp.data.country.countryname
            : ""
          : "",
        imagen: resp.data.photoicon,
        nickname: resp.data.nickname,
        check: resp.data.term,
        aceptnotif: resp.data.aceptnotif,
        aceptinfo: resp.data.aceptinfo,
        allData: resp.data,
      });
      setAceptnotif(resp.data.aceptnotif);
      setAceptinfo(resp.data.aceptinfo);
    }
    setCurrentUser(resp.data);
  }).catch((err:Error)=>{
    console.error('Hubo un error al traer la información del usuario',err);
  }).finally(()=>{
    setLoading(false);
  })
 }
 
 const getDataSubscriptionPaypal = () =>{
  const userId:number = currentUser?.id_player;
  getInfoUserPaypal(userId).then((resp:any)=>{
    if(resp){
      console.log('Response service InfoUserPaypal =>', resp);
      const data = resp.data.data;
      let response:paypalResponse ={
        _id: data[0]?._id,
        iduser: data[0]?.iduser,
        paypalresponse: data[0]?.paypalresponse,
        fecha: data[0]?.fecha,
        fechacancelacion: data[0]?.fechacancelacion,
        estado: data[0]?.estado,
      }
      console.log('Storage data in response val  =>',response);
      if(response !== undefined){
        setSubstatus(response);
      }
    }
  }).catch((err:Error)=>{
    console.error('Hubo un error al traer la información del usuario',err);
    // Swal.fire(
    //   `${t("Suscriptions.ModalMessages.errorMessage")}`,
    //   `${t("Suscriptions.ModalMessages.description2")}` ,
    //   "error"
    // );
  })
 }

 const cancellationSubscriptionPaypal =  (values:Object) =>{
  cancelSubscriptionPaypal(values).then((resp:any)=>{
    console.log('Que responde el resp?',resp)
    if(resp){
            Swal.fire(
            `${t("Profil.Modals.cancel")}`,
            `${t("Profil.Modals.descriptionCancel")}`,
            "success"
          );

          setTimeout(() => {
            gerUserInformation(token);
            window.location.reload();
          }, 1000);
    }
  }).catch((err:any)=>{
        Swal.fire(
            `${t("Suscriptions.ModalMessages.errorMessage")}`,
            `${t("Suscriptions.ModalMessages.errorDescription")}` +
              err.toString(),
            "error"
        );
  })
}

  function cancelSubscription() {
    Swal.fire({
      title: `${t("Profil.Modals.areSure")}`,
      text: `${t("Profil.Modals.description")}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#d33",
      confirmButtonText: `${t("Profil.Modals.confirm")}`,
      cancelButtonText: `${t("Profil.Buttons.cancel")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        const values ={
          iduser:currentUser?.id_player,
          idplan:substatus?.paypalresponse[0].subscriptionID ?? "",
          token:substatus?.paypalresponse[0].facilitatorAccessToken ?? "",
          fecha: substatus?.fecha,
          paypalData: substatus.paypalresponse[0],
        }
        cancellationSubscriptionPaypal(values);
      }
    });
  }
  const handlePreview = async (event: any) => {
   
    const file = event.target.files && event.target.files[0];
    if (file) {
      console.log("entro aqui? trae el file?", file);
      setPreviewImage(file);
      setPreviewOpen(true);
    }
  };

  const uploadImageBucket = () => {
    if (previewImage) {
      console.log("entro a cargar al bucket?", previewImage);
      const fileExtension = previewImage.name.split(".").pop(); // Obtener la extensión del archivo
      const fileType = `image/${fileExtension}`; // Construir el tipo de archivo según la extensión
      const file = new File([previewImage], previewImage.name, {
        type: fileType,
      });
      console.log("Archivo seleccionado:", file);
      uploadProfileImage(file)
        .then((resp: any) => {
          console.log("QUE RESPONDE RESP?", resp);
          if (resp) {
            const tokenJWT = localStorage.getItem(tokenID);
            let values = {
              uid: formik.values.id,
              token: tokenJWT,
              data: {
                nombre: formik.values.nombre,
                apellido: formik.values.apellido,
                pais:
                  formik.values.pais.length > 0 ? formik.values.pais : "Chile",
                imagen: resp.data.url,
                check: formik.values.check,
                aceptnotif: formik.values.aceptnotif,
                aceptinfo: formik.values.aceptinfo,
                nickname: formik.values.nickname,
                // nickname: formik.values.nickname,
                // nombre: formik.values.nombre,
                // imagen: resp.data.url,
              },
            };

            updateDataUser(values)
              .then((resp: any) => {
                if (resp) {
                  console.log("imagen actualizada");
                  setPreviewOpen(false);
                  toaster.push(
                    <Message type="success">{t("Events.uploadDone")}</Message>
                  );
                }
              })
              .catch((err: any) => {
                console.error(
                  "Ha ocurrido un error al actualizar el usuario",
                  err
                );
                toaster.push(
                  <Message type="error">{t("Events.uploadError")}</Message>
                );
              });
          }
        })
        .catch((err: any) => {
          toaster.push(
            <Message type="error">{t("Events.uploadError")}</Message>
          );
          console.log(err, "HUBO UN ERROR");
        });
    }
  };

  const updateUser = () => {
    const token = localStorage.getItem(tokenID);

    // const values = {
    //   token: token,
    //   data: formik.values,
    // };

    const values = {
      uid: formik.values.id,
      token: token,
      data: {
        nombre: formik.values.nombre,
        apellido: formik.values.apellido,
        pais:
          formik.values.pais.length > 0 ? formik.values.pais : "Chile",
        imagen: formik.values.imagen,
        check: formik.values.check,
        aceptnotif: formik.values.aceptnotif,
        aceptinfo: formik.values.aceptinfo,
        nickname: formik.values.nickname,
      },
    }
    

    updateDataUser(values).then((resp: any) => {
      const result = resp.data;

      if(result.message === 'ok'){
        GetUser();
        Swal.fire({
          title: `${t("Profil.Modals.dataUpdate")}`,
          text: `${t("Profil.Modals.dataUpdate_description")}`,
          icon: "success",
        });
      }else{
        Swal.fire({
          title: `${t("Profil.Modals.dataUpdate_error")}`,
          text: result.message,
          icon: "error",
        });
      }
    }).catch((err:any)=>{
      console.error("Hubo un error al actualizar los datos de usuario",err);
      Swal.fire({
        title: `${t("Profil.Modals.dataUpdate_error")}`,
        text: err.message,
        icon: "error",
      });
    })
  };

  const handleRejectImage = () => {
    setPreviewImage(null);
    setPreviewOpen(false);
  };

  function statusSub() {
    const idplan =
      substatus?.paypalresponse[0].subscriptionID === undefined
        ? ""
        : substatus?.paypalresponse[0].subscriptionID;

    Get(`${URL_API}/tokens/subStatus/${currentUser?.id_player}`).then(
      (res) => {
        //console.log(res.data);
      },
      (err) => {
        Swal.fire(
          `${t("Suscriptions.ModalMessages.errorMessage")}`,
          `${t("Suscriptions.ModalMessages.description2")}` + err.toString(),
          "error"
        );
      }
    );
  }

  const handleContainerClick = () => {
    inputRef.current.click();
  };

  const formik = useFormik({
    initialValues: {
      nombre: "",
      apellido: "",
      pais: "",
      imagen: "",
      nickname: "",
      check: false,
      aceptnotif: false,
      aceptinfo: false,
    },
    validationSchema: Yup.object().shape({
      nombre: Yup.string()
        .max(50, CaracteresMaximos(50))
        .nullable(true)
        .required(CampoRequerido("Nombre")),
      apellido: Yup.string()
        .max(50, CaracteresMaximos(50))
        .nullable(true)
        .required(CampoRequerido("Apellido")),
      pais: Yup.string()
        .min(3, CaracteresMinimos(3))
        .max(50, CaracteresMaximos(50))
        .nullable(true)
        .required(CampoRequerido("Pais")),
      nickname: Yup.string()
        .max(50, CaracteresMaximos(50))
        .nullable(true)
        .required(CampoRequerido("Nickname")),

      imagen: Yup.string()
        .min(3, CaracteresMinimos(3))
        .nullable(true)
        .required(CampoRequerido("Imagen")),
      check: Yup.string()
        .nullable(true)
        .matches(/true/, "Debe aceptar los términos y condiciones")
        .required(CampoRequerido("Terminos y Condiciones")),
      aceptinfo: Yup.boolean(),
      aceptnofif: Yup.boolean(),
      claveactual: Yup.string(),
      nuevaclave: Yup.string(),
      confirmarclave: Yup.string(),
    }),

    validateOnMount: true,
    onSubmit: (values: any) => {},
  });

  const generateNewPassword = () =>{
    sendPasswordReset(currentUser?.email).then((resp:any) => {
      Swal.fire({
        title: `${t("Login.Modals.resetPasswordTitle")}` + currentUser?.email,
        text: `${t("Login.Modals.resetPasswordDescription")}`,
        icon: "success",
      });
    }).catch((err)=>{
      Swal.fire({
        title: "Error",
        text: err.message,
        icon: "error",
      });
    })
  }
  return (
    <>
      {loading ? (
        <Placeholder.Grid rows={5} />
      ) : (
        <div className="p-10 mx-auto rounded shadow-sm form w-lg-500px bg-body p-lg-15">
          <div className="card-body border-top p-9">
            <div className="mb-6 row">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                {t("Profil.photoProfile")}
              </label>
              <div className="col-lg-8">
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/gif"
                  name="file"
                  ref={inputRef}
                  style={{ display: "none" }}
                  onChange={handlePreview}
                />
                <div
                  className={`w-40 h-40 rounded-full bg-slate-200 right-2 ring-offset-cyan-500 hover:bg-slate-400 hover:cursor-pointer flex justify-center items-center ${
                    hoverImage ? "hover-overlay" : ""
                  }`}
                  onMouseEnter={() => {
                    setHoverImage(true);
                  }}
                  onMouseLeave={() => {
                    setHoverImage(false);
                  }}
                  onClick={handleContainerClick}
                >
                  {previewImage !== null ? (
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <img
                      src={URL.createObjectURL(previewImage)}
                      alt="preview image"
                      className="w-40 h-40 rounded-full"
                    />
                  ) : (
                    <>
                      {hoverImage && (
                        <div className="absolute">
                          <RiUploadCloud2Fill size={50} color={"#ff0000"} />
                        </div>
                      )}

                      <img
                        src={formik.values.imagen}
                        alt="actual profile image"
                        className="w-40 h-40 rounded-full hover:opacity-60"
                      />
                    </>
                  )}
                </div>
                <ModalAntd
                  open={previewOpen}
                  title=""
                  footer={[
                    <Button key="submit" onClick={uploadImageBucket}>
                      {t("Profil.Buttons.upload")}
                    </Button>,
                    <Button key="back" onClick={handleRejectImage}>
                      {t("Profil.Buttons.cancel")}
                    </Button>,
                  ]}
                >
                  {previewImage !== null && (
                    <div className="flex items-center justify-center w-full mt-10 rounded-md">
                      <img
                        src={URL.createObjectURL(previewImage)}
                        alt="Preview"
                      />
                    </div>
                  )}
                </ModalAntd>
                <div className="form-text">{t("Profil.allowImage")}</div>
              </div>
            </div>
            <div className="mb-6 row">
              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                {t("ContactUs.Form.name")}
              </label>
              <div className="col-lg-8 fv-row fv-plugins-icon-container">
                <input
                  {...formik.getFieldProps("nombre")}
                  onChange={(value) => {
                    formik.setFieldValue("nombre", value.target.value);
                  }}
                  type="text"
                  name="nombre"
                  className="form-control form-control-lg form-control-solid"
                  placeholder={t("ContactUs.Form.name")}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
            </div>

            <div className="mb-6 row">
              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                {t("ContactUs.Form.lastName")}
              </label>
              <div className="col-lg-8 fv-row fv-plugins-icon-container">
                <input
                  {...formik.getFieldProps("apellido")}
                  onChange={(value) => {
                    formik.setFieldValue("apellido", value.target.value);
                  }}
                  type="text"
                  name="apellido"
                  className="form-control form-control-lg form-control-solid"
                  placeholder={t("ContactUs.Form.name")}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
            </div>

            <div className="mb-6 row">
              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                {t("Profil.nick")}
              </label>
              <div className="col-lg-8 fv-row fv-plugins-icon-container">
                <input
                  {...formik.getFieldProps("nickname")}
                  onChange={(value) => {
                    formik.setFieldValue("nickname", value.target.value);
                  }}
                  type="text"
                  name="nickname"
                  className="form-control form-control-lg form-control-solid"
                  placeholder={t("Profil.nick")}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
            </div>

            <div className="mb-6 row">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                <span className="required"> {t("ContactUs.Form.country")}</span>
                <i
                  className="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  aria-label="Country of origination"
                  data-kt-initialized={1}
                />
              </label>
              <div className="col-lg-8 fv-row fv-plugins-icon-container">
                <SelectPicker
                  {...formik.getFieldProps("pais")}
                  name="pais"
                  onChange={(value: any) => {
                    formik.setFieldValue("pais", value);
                  }}
                  style={{
                    width: "100%",
                  }}
                  data={Paises.sort((a, b) => {
                    if (a.label < b.label) {
                      return -1;
                    }
                    if (a.label > b.label) {
                      return 1;
                    }
                    return 0;
                  })}
                />
              </div>
            </div>

            {!hideTerm ? (
              <div className="mb-8 fv-row fv-plugins-icon-container">
                <label className="form-check form-check-inline">
                  <input
                    {...formik.getFieldProps("check")}
                    style={{
                      transform: "scale(2)",
                    }}
                    className="form-check-input"
                    type="checkbox"
                    name="toc"
                    onChange={() => {
                      formik.setFieldValue("check", !formik.values.check);
                      setTerminos(!terminos);
                    }}
                    checked={terminos}
                  />
                  <span className="text-gray-700 form-check-label fw-semibold fs-base ms-1">
                    {t("Cookies.Cookies_button.accept_button")}
                    <label
                      onClick={() => {
                        setOpen(!open);
                      }}
                      className="ms-1 link-primary"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {t("Cookies.Cookies_button.degree_button")}
                    </label>{" "}
                  </span>
                </label>
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
            ) : (
              <></>
            )}

            <div className="mb-8 fv-row fv-plugins-icon-container">
              <label className="form-check form-check-inline">
                <input
                  {...formik.getFieldProps("aceptnotif")}
                  style={{
                    transform: "scale(2)",
                  }}
                  className="form-check-input"
                  type="checkbox"
                  name="toc"
                  onChange={() => {
                    formik.setFieldValue(
                      "aceptnotif",
                      !formik.values.aceptnotif
                    );
                    setAceptnotif(!aceptnotif);
                  }}
                  checked={aceptnotif}
                />
                <span className="text-gray-700 form-check-label fw-semibold fs-base ms-1">
                  {t("Profil.acceptNotify")}
                </span>
              </label>
              <div className="fv-plugins-message-container invalid-feedback" />
            </div>

            <div className="mb-8 fv-row fv-plugins-icon-container">
              <label className="form-check form-check-inline">
                <input
                  {...formik.getFieldProps("aceptinfo")}
                  style={{
                    transform: "scale(2)",
                  }}
                  className="form-check-input"
                  type="checkbox"
                  name="toc"
                  onChange={() => {
                    formik.setFieldValue("aceptinfo", !formik.values.aceptinfo);
                    setAceptinfo(!aceptinfo);
                  }}
                  checked={aceptinfo}
                />
                <span className="text-gray-700 form-check-label fw-semibold fs-base ms-1">
                  {t("Profil.attent")}
                </span>
              </label>
              <div className="fv-plugins-message-container invalid-feedback" />
            </div>

            <hr />

            <div className="mb-6 row">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                {t("Profil.subscriptionStatus")}
              </label>
              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                {currentUser?.id_membership == 1
                  ? "Free"
                  : currentUser?.id_membership == 2
                  ? "Pro"
                  : currentUser?.id_membership == 3
                  ? "Club Admin"
                  : currentUser?.id_membership == 4
                  ? "Coach"
                  : "Sport Pro Admin"}
              </label>

              {substatus?.estado && (
                <label
                  className="col-lg-4 col-form-label fw-semibold fs-6"
                  onClick={() => {
                    //statusSub();
                  }}
                >
                  ({substatus?.estado})
                </label>
              )}
            </div>

            {currentUser?.id_membership !== 1 && substatus !== undefined ? (
              <div className="mb-6 row">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  {t("Profil.subScriptionDate")}
                </label>
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  {substatus?.fecha !== ""
                    ? formatearFecha(substatus.fecha)
                    : `${t("Profil.noDate")}`}
                </label>
              </div>
            ) : (
              <></>
            )}

            {/* {
                            currentUser?.id_membership !== 1 && substatus !== undefined ?
                                <div className="mb-6 row">
                                    <label className="col-lg-4 col-form-label fw-semibold fs-6">Meses Subscrito</label>
                                    <label className="col-lg-4 col-form-label fw-semibold fs-6">{
                                        substatus?.fecha !== "" ?
                                            mesesSub(substatus.fecha) :
                                            "Sin fecha"
                                    }</label>
                                </div> : <></>
                        } */}

            {currentUser?.id_membership !== 1 && substatus !== undefined ? (
              <div className="mb-6 row">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  {substatus?.estado === "cancelada"
                    ? `${t("Profil.withSubs")}`
                    : `${t("Profil.NextCharge")}`}
                </label>
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  {substatus?.fecha !== ""
                    ? recargo(substatus.fecha)
                    : `${t("Profil.noDate")}`}
                </label>
              </div>
            ) : (
              <></>
            )}

            {currentUser?.id_membership !== 3 &&
            currentUser?.id_membership !== 4 &&
            currentUser?.id_membership !== 0 &&
            currentUser?.id_membership !== 1 &&
            substatus?.estado !== "cancelada" ? (
              <div>
                <div className="mb-6 row">
                  <button
                    onClick={() => {
                      cancelSubscription();
                    }}
                    className="btn btn-primary"
                  >
                    {t("Profil.Buttons.cancelSubs")}
                  </button>
                </div>
              </div>
            ) : currentUser?.id_membership !== 3 &&
              currentUser?.id_membership !== 0 ? (
              <div className="mb-6 row">
                <div>
                  <div style={{ textAlign: "center" }}>
                    <label className="col-lg-12 col-form-label fw-semibold fs-6">
                      {t("Profil.youCan")}
                    </label>
                  </div>
                  <br />
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        navigate("/planessocios");
                      }}
                    >
                      {t("SuscriptionClub.Subscribe")}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <br />

            {provider === "google" ? (
              <div className="mb-6 row">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  <span className="">{t("Profil.InitWith")} </span>
                  <i
                    className="fas fa-exclamation-circle ms-1 fs-7"
                    data-bs-toggle="tooltip"
                    aria-label="Country of origination"
                    data-kt-initialized={1}
                  />
                </label>
                {}
                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                  <img
                    alt="Logo"
                    src="/google-icon.svg"
                    className="h-20px me-3"
                  />{" "}
                  Google
                </div>
              </div>
            ) : provider === "" || provider === "facebook" ? (
              <div className="mb-6 row">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  <span className="">{t("Profil.InitWith")} </span>
                  <i
                    className="fas fa-exclamation-circle ms-1 fs-7"
                    data-bs-toggle="tooltip"
                    aria-label="Country of origination"
                    data-kt-initialized={1}
                  />
                </label>
                {}

                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                  <img
                    alt="Logo"
                    src="/facebook-icon.png"
                    className="h-20px me-3"
                  />{" "}
                  Facebook
                </div>
              </div>
            ) : (
              <div className="mb-6 row">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  <span className="">{t("Profil.InitWith")} </span>
                  <i
                    className="fas fa-exclamation-circle ms-1 fs-7"
                    data-bs-toggle="tooltip"
                    aria-label="Country of origination"
                    data-kt-initialized={1}
                  />
                </label>
                {}

                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                  <img alt="Logo" src="/webIcon.png" className="h-10" />{" "}
                  {t("Profil.fromWeb")}
                </div>
              </div>
            )}
          </div>

          {provider === "local" ? (
            <div className="py-6 d-flex justify-content-end px-9">
              <button
                onClick={() => {
                  generateNewPassword();
                }}
                style={{
                  width: "100%",
                }}
                className="text-base font-medium text-center text-white bg-red-500 btn hover:bg-red-700"
              >
                <FontAwesomeIcon
                  icon={faKey}
                  style={{
                    marginRight: "20px",
                  }}
                />{" "}
                {t("Profil.Buttons.changePassword")}
              </button>
            </div>
          ) : (
            <></>
          )}

          <div className="py-6 d-flex justify-content-end px-9">
            <button
              onClick={() => {
                // const token = localStorage.getItem(tokenID);
                updateUser();
                // Post(URL_API + "/login/updateDataClient", {
                //   token: token,
                //   data: formik.values,
                // }).then((res) => {
                //   if (res.data.message === "ok") {
                //     GetUser();
                //     Swal.fire({
                //       title: `${t("Profil.Modals.dataUpdate")}`,
                //       text: `${t("Profil.Modals.dataUpdate_description")}`,
                //       icon: "success",
                //     });
                //   } else {
                //     Swal.fire({
                //       title: `${t("Profil.dataUpdate_error")}`,
                //       text: res.data.message,
                //       icon: "error",
                //     });
                //   }
                // });
              }}
              disabled={JSON.stringify(formik.errors) !== "{}"}
              style={{
                width: "100%",
              }}
              type="submit"
              className="text-base font-semibold text-center text-white bg-black rounded-lg h-14 hover:bg-white hover:text-black hover:ring-2 hover:ring-black"
              id="kt_account_profile_details_submit"
            >
              {t("Profil.Buttons.saveInfo")}
            </button>
          </div>
          <input type="hidden" />
          <div />
          {/* </form> */}
        </div>
      )}
      {/* <form id="kt_account_profile_details_form" className="p-10 mx-auto rounded shadow-sm form w-lg-500px bg-body p-lg-15" > */}
      <Modal show={popup} backdrop="static" style={{ top: "30vh" }}>
        <Modal.Body>
          <div>
            {/* <PayPalPopUp idplan="P-28D314351H008881EMNG7JTA" tipoplan="subscription" idcliente="desa" ></PayPalPopUp> */}
            <PayPalPopUp
              idplan="P-7TS6228264587662WMNZNM3Q"
              tipoplan="subscription"
              idcliente=""
            ></PayPalPopUp>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            justifyContent: "center",
          }}
        >
          <div>
            <button
              style={{}}
              onClick={() => {
                setPopup(false);
              }}
              className="btn btn-danger"
            >
              `${t("Profil.Buttons.cancel")}`
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
