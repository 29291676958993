import axios from 'axios';
import { API_URL ,API_URL_LOCAL_DEV,API_URL_DEV} from '../../env';
import { Get, Post } from '../consumir';


export const SaveSuscriptionPaypalInformation = async (data:Object,tiposub:string) =>{

    try {
       const  response  = await Post(`${API_URL_DEV}/subscriptions/SavePaypalResponse/`, data);

        return response;
        
    } catch (error) {

        console.error("ERROR NETWORK CONNECTION  SEND INFO PAYPAL", error);
    }
}

export const cancelSubscriptionPaypal = async(values:Object) =>{
    console.log('Valores a llegar',values);
    try {
        const response = await Post(`${API_URL_DEV}/subscriptions/cancel`,values);

        return response;
    } catch (error) {
        console.error("ERROR NETWORK CONNECTION TO CANCEL SUSCRIPTION",error);
    }
}

export const updateMemberShip = async(userId:number,membership:number) => {

    try {
        const response = await Get(`${API_URL_DEV}/subscriptions/updatePlan/${userId}/${membership}`);

        return response

    } catch (error) {
        console.error('ERROR NETWORK CONNECTION UPDATE SUSCRIPTION',error);
        
    }
}

export const getInfoUserPaypal = async(userId:number) => {
    try {
         const response = await Get(`${API_URL_DEV}/subscriptions/getPaypalResponse/${userId}`);

         return response;
    } catch (error) {

        console.error('ERROR NETWORK CONNECTION TO GET INFO USER',error);
        
    }
}

export const statusSubscriptionUser = async(userId:number) => {
    try {
        const response = await Get(`${API_URL_DEV}/subscriptions/subStatus/${userId}`);

        return response;
    } catch (error) {
        console.error('ERROR NETWORK CONNECTION TO GET STATUS MEMBERSHIP USER',error);
    }
}

