import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { PDFObject } from "react-pdfobject";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, SelectPicker } from "rsuite";
import { API_URL } from "../../env";
import AuthContext from "../../utils/Auth";
import { Post } from "../../utils/consumir";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../../utils/firebase";
import { Paises } from "../../utils/utilidades";
import "./register.css";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf";
import "pdfjs-dist/build/pdf.worker.entry";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { pdfjs } from 'react-pdf';
import Lottie from 'lottie-react';
import loaderPdf from '../../animations/loadingPdf.json';
import { Modal } from "antd";
import ToastSwal from "../../components/toastSwal";
import Swal from "sweetalert2";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const device = navigator.userAgent;
let regexp = /android|iphone|kindle|ipad|huawei|zte|honor|galaxy/i
const isMobileDevice = regexp.test(device);

export function RegisterPage() {
  const URL_API = API_URL || "";
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [terminos, setTerminos] = useState(false);

  const [info, setInfo] = useState<any>({});
  const [user, loading, error] = useAuthState(auth);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();
  const [t, i18n] = useTranslation('global');
  const { setCurrentUser, currentUser, GetUser } = useContext(AuthContext);

  const register = () => {
    if (!info.name) {
      alert(t("Register.ValidateForm.name"));
      return;
    }
    if (!info.email) {
      alert(t("Register.ValidateForm.email"));
      return;
    }
    if (!info.password) {
      alert(t("Register.ValidateForm.password"));
      return;
    }
    if (info.password !== info.repassword) {
      alert(t("Register.ValidateForm.samePassword"));
      return;
    }

    registerWithEmailAndPassword(
      info.name,
      info.email,
      info.password,
      info.pais
    ).then(async (res) => {
      let resultado = await Post(URL_API + "/login/getDataClient", {
        token: res?.token,
      });
      const {data,status} = resultado;
      if(status === 201 || status === 200){
        setCurrentUser(data);
        GetUser();
        let info:any = state;
        if (info?.video) {
          navigate("/startlive?video=" + info?.video);
        }
        Swal.fire({
          icon:'success',
          title:`${data.firstname}  ${t("Register.registerOK")}`,
          text:`${t("Register.redirectOn")}`,
        })
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }else{
        Swal.fire({
          icon:'error',
          title:`${t("Register.registerError")}`,
          text:`${t("Register.redirectOn")}`,
        })
        console.error('hubo un error al crear el usuario',data);
      }
    });
  };

  useEffect(() => {
    if (loading) return;
    if (currentUser) {
      navigate("/");
    }
  }, [user, loading]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setTerminos(true);
  }

  return (
    <div className="p-10 mx-auto rounded shadow-sm w-lg-500px bg-body p-lg-15">
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        id="kt_sign_in_form"
        action="#"
      >
        <div className="mb-10 text-center">
          <h1 className="mb-3 text-dark">{t("Register.Title")}</h1>
        </div>
        <div className="mb-10 fv-row fv-plugins-icon-container">
          <label className="form-label fs-6 fw-bolder text-dark">
            {t("Register.name")}
          </label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="text"
            name="name"
            autoComplete="off"
            onChange={(e) =>
              setInfo({
                ...info,
                name: e.target.value,
              })
            }
          />
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        <div className="mb-10 fv-row fv-plugins-icon-container">
          <label className="form-label fs-6 fw-bolder text-dark">
            {t("Register.email")}
          </label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="email"
            name="email"
            autoComplete="off"
            onChange={(e) =>
              setInfo({
                ...info,
                email: e.target.value,
              })
            }
          />
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        <div className="mb-10 fv-row fv-plugins-icon-container">
          <div className="mb-2 d-flex flex-stack">
            <label className="mb-0 form-label fw-bolder text-dark fs-6">
              {t("Register.password")}
            </label>
          </div>
          <input
            className="form-control form-control-lg form-control-solid"
            type="password"
            name="password"
            autoComplete="off"
            onChange={(e) =>
              setInfo({
                ...info,
                password: e.target.value,
              })
            }
          />
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        <div className="mb-10 fv-row fv-plugins-icon-container">
          <div className="mb-2 d-flex flex-stack">
            <label className="mb-0 form-label fw-bolder text-dark fs-6">
              {t("Register.re-password")}
            </label>
          </div>
          <input
            className="form-control form-control-lg form-control-solid"
            type="password"
            name="password"
            autoComplete="off"
            onChange={(e) =>
              setInfo({
                ...info,
                repassword: e.target.value,
              })
            }
          />
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        <div className="mb-10 fv-row fv-plugins-icon-container">
          <div className="mb-2 d-flex flex-stack">
            <label className="mb-0 form-label fw-bolder text-dark fs-6">
              {t("Register.country")}
            </label>
          </div>
          <SelectPicker
            name="pais"
            onChange={(value: any) => {
              // formik.setFieldValue('pais', value);
              setInfo({
                ...info,
                pais: value,
              });
            }}
            style={{
              width: "100%",
            }}
            data={Paises.sort((a, b) => {
              if (a.label < b.label) {
                return -1;
              }
              if (a.label > b.label) {
                return 1;
              }
              return 0;
            })}
          />
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        <div className="mb-8 fv-row fv-plugins-icon-container">
          <label className="form-check form-check-inline">
            <input
              style={{
                transform: "scale(2)",
              }}
              className="form-check-input"
              type="checkbox"
              name="toc"
              onChange={() => {
                setTerminos(!terminos);
              }}
              checked={terminos}
            />
            <span className="text-gray-700 form-check-label fw-semibold fs-base ms-1">
              Aceptar
              <label
               onClick={() => {
                  // console.log("Entre aqui es desktop");
                  setOpen(!open);

      
              }}
                className="ms-1 link-primary"
                style={{
                  cursor: "pointer",
                }}
              >
                {t("Login.PolicyAndConditions2")}
              </label>{" "}
            </span>
          </label>
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>

        <div className="text-center">
          <button
            type="button"
            id="kt_sign_in_submit"
            className="mb-5 btn btn-lg btn-primary w-100"
            disabled={
              !terminos ||
              !info.name ||
              !info.email ||
              !info.password ||
              !info.repassword ||
              info.password !== info.repassword
            }
            onClick={register}
          >
            <span className="indicator-label">{t("Register.register")}</span>
            <span className="indicator-progress">
              {t("Login.Loading")}
              <span className="align-middle spinner-border spinner-border-sm ms-2" />
            </span>
          </button>
        </div>
        <div />
      </form>
      <Modal
        open={open}
        title={t("Login.PolicyAndConditions2")}
        onCancel={() =>{
          setOpen(false);
        }}
        footer={[
            <Button
            color={terminos ? 'green':'red'}
              onClick={() => {
                if(terminos){
                  setTerminos(true);
                  setOpen(!open);

                }else{
                  setTerminos(true);
                  setOpen(!open);
                }
              }}
              appearance="primary"
            >
              {" "}
              {!terminos ? t("Cookies.Cookies_button.accept_button") : t("Cookies.Cookies_button.degree_button")}
            </Button>
        ]}
      >
       <Document
            file={
              i18n.language === "es"
                ? "https://storage.googleapis.com/sportprotv-images/Te%CC%81rminos%20y%20condiciones%20sportproTV%20V4%20Espan%CC%83ol.pdf"
                : i18n.language === "en"
                ? "https://storage.googleapis.com/sportprotv-images/Te%CC%81rminos%20y%20condiciones%20sportproTV%20V4%20Ingle%CC%81s.pdf"
                : "https://storage.googleapis.com/sportprotv-images/Te%CC%81rminos%20y%20condiciones%20sportproTV%20V4%20Portugues.pdf"
            }
              onLoadSuccess={onDocumentLoadSuccess}
              renderMode="canvas"
              onError={() =>{
                return(
                  <div>
                    <p>Error loading pdf...</p>
                  </div>
                )
              }}
              loading={() =>{
                return (
                  <div className="flex items-center justify-center w-full pt-3 ">
                  <Lottie
                    animationData={loaderPdf}
                    style={{
                      width: 450,
                      height: 450,
                    }}
                  />
                </div>
                )
              }}
            >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} width={isMobileDevice? 300:480}/>
            ))}
            </Document>
      </Modal>
    </div>
  );
}
