 export const API_URL_LOCAL_DEV = "http://localhost:3100"

// export const API_URL = "https://e038-201-214-213-9.ngrok.io"
export const API_URL = "https://backendsportprotv-p456tcdk5q-ue.a.run.app"

export const API_URL_DEV="https://backendsportprotvdev-p456tcdk5q-ue.a.run.app"
export const GOOGLE_ADS_CLIENTID="ca-pub-4195577514052158"
export const GOOGLE_ADS_SLOT="7372064261"
export const APP_TOKEN_SPORTPRO="sportpro-token-v2"
export const APP_GOOGLE_TAG="GTM-M7799BZ"
export const REACT_APP_ENV_STATUS_DEV = false

